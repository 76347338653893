<template>
  <b-form-group label="Цвет">
    <div class="card-body mt--3">
      <div class="row icon-examples">
        <div
            class="mr-3"
            v-for="(color, index) in colors"
            :key="`${color}${index}`"
        >
          <button
              @click="setColor(color)"
              :class="btnActiveClass(color)"
              type="button"
              :title="color"
              class="btn-icon-clipboard"
          />
        </div>

        <div
            class="mr-3"
            v-for="(color, index) in colors"
            :key="index"
        >
          <button
              v-if="!isText"
              @click="setColor(`gradient-${color}`)"
              :class="btnActiveClass(`gradient-${color}`)"
              type="button"
              :title="`gradient-${color}`"
              class="btn-icon-clipboard"
          />
        </div>

      </div>

    </div>
  </b-form-group>
</template>

<script>
export default {
  name: "BColorPicker",
  data() {
    return {
      selectedColor: null,
      colors: [
        'indigo',
        'purple',
        'blue',
        'orange',
        'green',
        'red',
        'dark',
        'default',
        'info',
        'cyan',
        'pink',
        'yellow',
      ]
    }
  },
  props: {
    isText: {
      type: Boolean,
      default() {
        return false;
      }
    },
    value: {
      type: String,
      description: 'Выбранный цвет',
    },
  },
  mounted() {
    if (this.value)
      this.selectedColor = this.value;
  },
  methods: {
    /**
     * Устанавливает цвет
     * @param color
     */
    setColor(color) {
      this.selectedColor = color;
      this.$emit('on-update-color', color);
    },
    btnActiveClass(color) {
      const isActive = this.selectedColor === color;
      const active = isActive ? 'active' : '';
      return `bg-${color} ${active}`;
    },
  }
}
</script>

<style scoped>
.active {
  transform: scale(1.2);
}
</style>