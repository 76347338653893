<template>
  <div>
    <base-header type="gradient-indigo" class="pb-6 pb-6 pt-5 pt-md-5" />
    <span class="mask bg-dark" />

    <template v-if="!isError">
      <!--  для пк  -->
      <div class="container-fluid w-25 text-center d-none d-md-block">
        <certificate-loader :loading="isLoading" :url="url" />
      </div>

      <!--  для телефонов  -->
      <div class="container-fluid text-center d-sm-block d-md-none">
        <certificate-loader :loading="isLoading" :url="url" />
      </div>
    </template>

    <template v-else>
      <div class="container-fluid w-25 text-center d-none d-md-block">
        <!--  текст ошибки  -->
        <div class="glass text-white p-3 text-lg">
          <b-iconstack font-scale="5" class="mb-3">
            <b-icon stacked icon="exclamation-triangle" scale="0.75" shift-v="-0.25" />
          </b-iconstack>
          <br>
          <div class="mb-3">
            Ошибка!
          </div>
          <small class="opacity-7">
            Если заявка, для которой вы хотите распечатать сертификат оплачена
            и завершена, то обратитесь к оператору.
          </small>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import EntryResource from "../../../resources/entry";
import CertificateLoader from "../../../components/Loaders/CertificateLoader.vue";

export default {
  name: "PrintDocument",
  components: {
    CertificateLoader,
  },
  data() {
    return {
      types: {
        certificate: 'certificate',
        certificateDocx: 'certificate-docx',
        program: 'program',
        registrationList: 'registration-list',
      },
      isLoading: false,
      isError: false,
      url: null,
    }
  },
  async mounted() {
    this.isLoading = true;
    const id = this.$route.params.id;
    const type = this.$route.params.type;
    try {
      const file = await EntryResource.print(id, type);
      window.location.href = file.url;
      this.url = file.url;
      this.isError = false;
    } catch (e) {
      this.isError = true;
    }
    this.isLoading = false;
  },
}
</script>

<style>
.glass {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

</style>