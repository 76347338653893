<template>
  <div class="main-content bg-gradient-indigo">
    <!-- Navbar -->
    <base-nav
      class="navbar-top navbar-horizontal navbar-dark"
      containerClasses="px-4 container"
      expand
    >
      <router-link slot="brand" class="text-xl text-white navbar-brand" to="/">
        АНЭКС
<!--        <img src="/img/brand/white.png" />-->
      </router-link>

      <template v-slot="{ closeMenu }">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
<!--                <img src="/img/brand/green.png" />-->
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <button
                type="button"
                @click="closeMenu"
                class="navbar-toggler"
                aria-label="Toggle sidenav"
              >
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/login">
              <span class="nav-link-inner--text">Вход</span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link nav-link-icon" to="/register">
              <span class="nav-link-inner--text">Регистрация</span>
            </router-link>
          </li>
        </ul>
        <hr class="d-lg-none" />
<!--        <ul class="navbar-nav align-items-lg-center ml-lg-auto">-->
<!--          <li class="nav-item d-none d-lg-block ml-lg-4">-->
<!--            <a-->
<!--              href="#"-->
<!--              class="btn btn-neutral btn-icon"-->
<!--            >-->
<!--              <span class="nav-link-inner&#45;&#45;text">АНЭКС</span>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
      </template>
    </base-nav>
    <!-- Header -->
    <div class="header bg-gradient-indigo pt-8 pb-8">
<!--      <div class="separator separator-bottom separator-skew zindex-100">-->
<!--        <svg-->
<!--          x="0"-->
<!--          y="0"-->
<!--          viewBox="0 0 2560 100"-->
<!--          preserveAspectRatio="none"-->
<!--          xmlns="http://www.w3.org/2000/svg"-->
<!--        >-->
<!--          <polygon-->
<!--            class="fill-default"-->
<!--            points="2560 0 2560 100 0 100"-->
<!--          ></polygon>-->
<!--        </svg>-->
<!--      </div>-->
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view />
      </slide-y-up-transition>
    </div>
<!--    <footer class="py-5" id="footer-main">-->
<!--      <div class="container text-center">-->
<!--        <div class="copyright text-center text-muted">-->
<!--          © {{ year }} АНЭКС-->
<!--        </div>-->
<!--      </div>-->
<!--    </footer>-->
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  computed: {
    isLogin() {
      return this.$route.name === "login";
    },
    isRegister() {
      return this.$route.name === "register";
    },
    isPassword() {
      return this.$route.name === "reset-password";
    },
  },
};
</script>
<style>
</style>
