<template>
  <div>
    <base-header type="gradient-indigo" class="pt-5 pt-md-5"/>
    <span class="mask bg-primary" />

    <div class="container-fluid mt-5 w-75">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header pt-0 border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">Отзывы</h3>
            </div>
          </div>
        </div>

        <review-list
            :loading="isLoading"
            :items="items"
            :items-pagination="itemsPagination"
            @on-update="fetchData"
        />

      </card>
    </div>
  </div>
</template>
<script>
import ReviewResource from "../../../../resources/review";
import ReviewList from "./ReviewList.vue";

export default {
  name: "Payment",
  components: {
    ReviewList
  },
  data() {
    return {
      items: [],
      itemsPagination: {},
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page) {
      this.isLoading = true;
      if (page === undefined) page = 1;
      this.items = await ReviewResource.fetch({}, page);
      this.itemsPagination = ReviewResource.dataPagination;
      this.isLoading = false;
    },
  },
};
</script>
<style>
</style>