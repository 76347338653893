<template>
  <div>
    <h6 class="heading-small text-muted mb-4">
      Пароль
    </h6>
    <div class="pl-lg-4">
      <div class="row">

        <div class="col-lg-12">
          <base-input
              alternative=""
              label="Пароль"
              placeholder="Введите новый пароль"
              v-model="item.password"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PasswordCard",
  props: {
    item: {
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>