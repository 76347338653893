<template>
  <div>
    <h6 class="heading-small text-muted mb-4">
      Работа
    </h6>


    <div class="col-12" v-if="item.role !== roleGuest">
      <div class="form-group">
        <a-label label="Профили работы" required />
        <b-form-checkbox-group v-model="checkedProfiles">
          <b-form-checkbox
              class="col-3 m-2 ml-4"
              v-for="profile in profiles"
              :value="profile.id"
              :key="profile.id"
          >
            {{ profile.fdv }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </div>

    <b-row class="px-3">
      <b-form-group class="col-6">
        <a-label label="Организация" required />
        <b-form-input placeholder="Школа №213" v-model="item.organization" />
        <validation-error :errors="apiValidationErrors.email" />
      </b-form-group>

      <b-form-group class="col-6">
        <a-label label="Должность" required />
        <b-form-input placeholder="Методист" v-model="item.job_title" />
        <validation-error :errors="apiValidationErrors.job_title" />
      </b-form-group>
    </b-row>

    <b-form-group class="col-12">
      <a-label label="Узнали о нас" />
      <b-form-input placeholder="Из интернета" v-model="item.learned_from_us" />
    </b-form-group>

  </div>
</template>

<script>
export default {
  name: "JobCard",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      checkedProfiles: [],
      roleGuest: 3,
    }
  },
  watch: {
    item: {
      handler() {
        if (this.item.profiles !== undefined) {
          this.checkedProfiles = this.item.profiles.map(elm => elm.id);
        }
      },
    },
    checkedProfiles: {
      handler() {
        this.$emit('select-profiles', this.checkedProfiles);
      },
    },
  },
  computed: {
    profiles() {
      return this.$store.getters.getDict('dict_profiles');
    }
  }
}
</script>

<style scoped>

</style>