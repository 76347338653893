<template>
  <div>
    <base-header type="gradient-indigo" class="pt-5 pt-md-5"/>
    <span class="mask" :class="`bg-${types[selectedType].color}`" />
    <div class="container-fluid mt-5">
      <entry />
    </div>
  </div>
</template>
<script>
import Entry from "./Entry/Entry";

export default {
  name: "EntryMain",
  components: {
    Entry,
  },
  data() {
    return {
      indexEntry: 0,
      indexPayment: 2,

      selectedType: 0,
      types: [
        {title: 'Заявки', icon: 'ni ni-send', color: 'default'},
        {title: 'Платежи', icon: 'ni ni-money-coins', color: 'cyan'},
      ]
    }
  },
  methods: {
    selectType(typeIndex) {
      this.selectedType = typeIndex;
    },
    selectedClass(index) {
      return this.isSelected(index) ?
          '' :
          'bg-transparent text-white';
    },
    isSelected(index) {
      return index === this.selectedType;
    },
    notification(varName) {
      return (varName !== undefined) ?
          this.$store.getters.getStatisticsByName(varName) :
          '';
    },
  },
};
</script>
<style>
</style>
