<template>
  <b-modal
      size="xl"
      id="publicationEditModal"
      centered
      ref="publicationEditModal"
      modal-class="xxl"
      cancel-title="Закрыть"
      ok-title="Сохранить"
      hide-footer
      @close="$emit('on-update')"
  >

<!--    <b-alert variant="warning" show v-if="entry.publication.status === statuses.pending">-->
<!--      Публикация успешно отредактирована. Дождитесь новой проверки оператором.-->
<!--    </b-alert>-->

    <b-row class="ml-2 mr-2" v-if="entry.publication">
      <b-col cols="4" class="border-right pr-5 mr-4 position-sticky" v-if="hasComment">
        <template>
          <b class="mr-2 mt-2">Оператор:</b> <br>
          {{ entry.publication.manager_comment }}
        </template>
      </b-col>

      <b-col class="publication">
        <publication-forms
            @on-update="$bvModal.hide('publicationEditModal'); $emit('on-update')"
            :item="entry.publication"
            :service="entry.service"
            v-if="entry.publication.status === statuses.revision || entry.publication.status === statuses.pending"
        />
        <template v-else>
          <h2>{{ entry.publication.title }}</h2>
          <h4><b-badge :variant="entry.publicationData.statusColor">
            {{ entry.publicationData.statusLabel }}
          </b-badge></h4>
          <div v-html="entry.publication.text" />
        </template>
      </b-col>

    </b-row>

  </b-modal>
</template>

<script>
import PublicationForms from "../Publication/PublicationForms";

export default {
  name: "PublicationEntryModal",
  components: {
    PublicationForms
  },
  data() {
    return {
      statuses: {
        pending: 1,
        approved: 2,
        revision: 3,
      },
    };
  },
  props: {
    entry: {
      type: Object,
      default(item) {
        return {
          status: this.statuses.pending,
        }
      },
    },
  },
  watch: {
    entry: {
      handler() {
        if (!this.entry.publication)
          this.entry.publication = {
            entry_id: this.entry.id,
            status: this.statuses.pending,
          }
      }
    }
  },
  computed: {
    hasComment() {
      return this.entry.publication.manager_comment &&
          this.entry.publication.manager_comment.length > 0;
    }
  },
}
</script>

<style scoped>

</style>