import Resource from "../resource";
import axios from "axios";

export default class ReviewResource extends Resource {
    /**
     * Публикация отзыва
     * @returns {Promise<void>}
     */
    static async accept(id) {
        const url = `${this.backendUrl}/${this.url}/${id}/accept`;
        const { data } = await axios.post(url, {});
        return data;
    }
}

ReviewResource.url = 'review';