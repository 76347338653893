export default {
  computed: {
      /**
       * Текущий пользователь из $store.getters.currentUser
       * @returns
       */
      currentUser() {
          return this.$store.getters.user;
      },
  }
};
