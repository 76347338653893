<template>
  <div>
    <base-header
        class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    >
      <div class="col-12 text-center">
        <h1 class="text-white">Пакеты услуг</h1>
        <p class="text-white mt-0 mb-5">
          Наведите курсор на пакет, чтобы просмотреть названия услуг.
        </p>
      </div>
      <div class="container-fluid mt-5 text-center service-packages">
        <service-packages />
      </div>
    </base-header>
  </div>
</template>

<script>
import ServicePackages from "../../shared/ServicePackages/ServicePackages";

export default {
  name: "ServicePackageList",
  components: {
    ServicePackages,
  },
}
</script>

<style scoped>

</style>