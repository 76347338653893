<template>
  <div class="mt-2">
    <b-form-group>
      <a-label :label="'Рубрика'" required />
      <l-select :disabled="!canBeEdited" v-model="item.dict_category_id" dict="dict_publication_categories" />
      <validation-error :errors="apiValidationErrors.dict_category_id" />
    </b-form-group>

    <b-form-group v-if="service.dict_subtype_id !== publicationInternetId">
      <a-label :label="'Выпуск'" required />
      <l-select :disabled="!canBeEdited" v-model="item.release_id" :options="releases" />
    </b-form-group>

    <b-form-group v-if="service.dict_subtype_id === publicationInternetId">
      <a-label :label="'Тип'" required />
      <l-select :disabled="!canBeEdited" v-model="item.type" :options="types" />
    </b-form-group>

    <b-form-group>
      <a-label :label="'Заголовок'" required />
      <b-form-input :disabled="!canBeEdited" v-model="item.title" placeholder="Заголовок" />
      <validation-error :errors="apiValidationErrors.title" />
    </b-form-group>

    <template>
      <b-form-group>
        <a-label :label="'Текст'" required />
        <template v-if="service.subtype.publication_has_file">
          <b-form-file
              v-model="item.file"
              placeholder="Выберите файл или перетащите его курсором..."
              drop-placeholder="Перетащите файл сюда..."
          />
          <a-file :file="item.file" hide-delete-btn />
        </template>
        <a-label
            v-if="service.subtype.publication_has_file && isManager"
            :label="'Текст для проверки антиплагиатом'"
            class="mt-3"
        />
        <template v-if="!service.subtype.publication_has_file || isManager">
          <ckeditor
              :disabled="!canBeEdited"
              :editor="ckEditor"
              v-model="item.text"
              @ready="onReady"
              :config="editorConfig"
          />
        </template>
        <validation-error :errors="apiValidationErrors.text" />
        <div class="mt-2 mb--2" v-if="item.text && item.text.length">
          Количество символов: {{ item.text.length }} <br>
          Длина текста должна не превышать {{ maxTextLength }} символов! <br>
          <div class="text-danger" v-if="item.text.length > maxTextLength">
            Слишком много символов!
          </div>
        </div>
      </b-form-group>
    </template>

    <b-form-group>
      <a-label :label="'Приложения'" />
      <b-form-file
          multiple
          v-if="!item.id"
          v-model="item.additionalFiles"
          placeholder="Выберите файлы или перетащите их курсором..."
          drop-placeholder="Перетащите файл сюда..."
      />
      <a-file
          v-for="file in item.additionalFiles"
          :file="file"
          :key="file.id"
          @on-remove="removeFile"
      />
    </b-form-group>

    <div class="text-right" v-if="canBeEdited">
      <b-button v-if="item.id" variant="primary" @click="checkAndSave" pill>
        Сохранить
      </b-button>
      <b-button v-else variant="success" @click="save" pill>
        Отправить
      </b-button>
    </div>

  </div>
</template>

<script>
import publicationMixin from "../../../mixins/publication-mixin";
import PublicationReleaseResource from "../../../resources/publication-release";

export default {
  name: "PublicationForms",
  mixins: [
      publicationMixin
  ],
  data() {
    return {
      maxTextLength: 65535,
      statuses: {
        pending: 1,
        approved: 2,
        revision: 3,
      },
      types: [
        { value: 1, label: 'Статья' },
        { value: 2, label: 'Методическая разработка' },
      ],
      publicationInternetId: 5,
      releases: [],
    };
  },
  props: {
    item: {
      type: Object,
    },
    service: {
      type: Object,
    },
  },
  computed: {
    canBeEdited() {
      const isRevision = this.item.status === this.statuses.revision;
      const isPending = this.item.status === this.statuses.pending;
      return (!this.item.status || isRevision || isPending || !this.item.id) || this.isManager;
    },
  },
  async mounted() {
    await this.fetchReleases();
  },
  methods: {
    async fetchReleases() {
      this.releases = await PublicationReleaseResource.fetch({
        dict_service_subtype_id: this.service.dict_subtype_id,
        is_published: false,
      });
    },
    async checkAndSave() {
      this.checkStatus();
      await this.save();
    },
    /**
     * После корректировки пользователем публикации со
     * статусом "требует корректировки" устанавливает
     * публикации статус "ожидает проверки"
     */
    checkStatus() {
      if (!this.isManager) {
        switch (this.item.status) {
          case this.statuses.revision:
            this.item.status = this.statuses.pending;
            break;
        }
      }
    },
    /**
     * Удаление файла до его отправки на сервер
     */
    removeFile(file) {
      const index = this.item.additionalFiles.indexOf(file);
      this.item.additionalFiles.splice(index, 1);
    }
  },
}
</script>

<style>
#publicationViewModal .ck-content {
  height: 500px;
}
</style>