<template>
  <div>
    <div class="glass text-white p-3 text-lg" v-if="loading">
      <b-iconstack font-scale="5" animation="throb" class="mb-3">
        <b-icon stacked icon="file-earmark-text-fill" scale="0.75" shift-v="-0.25" />
      </b-iconstack>
      <br>
      Формирование документа...
    </div>

    <template v-else>
      <div class="glass text-white p-3 text-lg">
        <b-iconstack font-scale="5" class="mb-3">
          <b-icon stacked icon="check" scale="0.75" shift-v="-0.25" />
        </b-iconstack>
        <br>
        Документ сформирован!
        <div class="text-white p-3 text-sm">
          Если скачивания не произошло, нажмите <a :href="url"> сюда </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CertificateLoader",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: '#',
    },
  }
}
</script>

<style scoped>
.dotted {
  border-color: white;
}
</style>