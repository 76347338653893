<template>
  <div class="dropdown-item py-0 overflow-hidden rounded">
  <div class="px-3 py-3">
      У вас
      <strong class="text-primary">
        {{ notifications.length }}
      </strong>
      новых уведомлений

      <a href="#" size="sm" class="mr-3 float-right" @click="readAll">
        Очистить
      </a>
    </div>

    <!--      <div class="px-3 py-3">-->
    <div v-if="notifications.length > 0">
      <template v-for="(notification, index) in notifications" >
        <hr class="m-0 p-0 pt-2" v-if="index < 5" :key="`hr-${notification.id}`" />
        <b-row v-if="index < 5" :key="`row-${notification.id}`" >
          <b-col cols="1">
            <i
                class="text-xs text-white p-2 rounded-circle mt-1"
                :class="`${notification.icon} bg-${notification.color}`"
            />
          </b-col>
          <b-col>
            <div
                class="nav-dropdown-notification text-wrap pointer pl-2"
                v-html="notification.text"
                v-if="index < 6"
            />
          </b-col>
        </b-row>
      </template>
    </div>

    <a
        @click="toNotifications"
        class="dropdown-item text-center text-primary font-weight-bold py-3 pointer"
    >
      Посмотреть все
    </a>
  </div>
</template>

<script>
import NotificationResource from "../resources/notification";

export default {
  name: "NotificationsDropdown",
  data() {
    return {
      notifications: [],
    }
  },
  async mounted() {
    // TODO Уведомления
    // await this.fetchData();
  },
  methods: {
    async fetchData() {
      if (!this.$store.getters.user.id)
        await this.$store.dispatch('me');
      const notifications = await NotificationResource.fetch({
        user_id: this.$store.getters.user.id
      })
      this.notifications = notifications.filter(elm => !elm.checked);
    },
    /**
     * Редирект на страничку всех уведомлений
     */
    toNotifications() {
      this.$router.push('/user/notifications');
    },
    async readAll() {
      await NotificationResource.readAll();
      this.notifications = [];
    }
  },
}
</script>

<style scoped>

</style>
