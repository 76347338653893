<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <div class="form-group mb-0">
        <base-input
            v-if="!isManagerPanel"
            placeholder="Поиск"
            class="input-group-alternative pl-3"
            alternative=""
            addon-right-icon="fas fa-search"
            v-model.lazy="keywords"
            :debounce="300"
        />
      </div>
    </form>

    <notifications />

    <actions-user />

    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        menu-classes="dropdown-menu dropdown-menu-right py-0 overflow-hidden"
      >
        <a href="#" slot="title" class="nav-link pr-0" @click.prevent>
          <div class="media align-items-center">
            <b-avatar :text="$store.getters.user.initials" :src="avatarUrl" />

<!--            <span-->
<!--              class="avatar avatar-sm rounded-circle avatar-image"-->
<!--              :style="{-->
<!--                'background-image': `url('${avatarUrl}')`,-->
<!--              }"-->
<!--            >-->

<!--            </span>-->
<!--            <div class="media-body ml-2 d-none d-lg-block">-->
<!--              <span class="mb-0 text-sm font-weight-bold">-->
<!--                Фамилия Имя Отчество-->
<!--              </span>-->
<!--            </div>-->
          </div>
        </a>

        <template>

          <div class="dropdown-header text-dark text-center text-sm text-wrap">
            <b>{{ user.fio }}</b> <br>
            <small>{{ user.role_label }}</small>
          </div>

          <div class="dropdown-divider"></div>

          <template v-if="!isManagerPanel && isManager">
            <router-link to="/manager/dashboard" class="dropdown-item">
              <i class="ni ni-app"></i>
              <span>Оператор</span>
            </router-link>
            <div class="dropdown-divider"></div>
          </template>

          <template v-if="isManagerPanel && isManager">
            <router-link to="/user/dashboard" class="dropdown-item">
              <i class="ni ni-app"></i>
              <span>Пользователь</span>
            </router-link>
            <div class="dropdown-divider"></div>
          </template>

          <router-link to="/user/notifications" class="dropdown-item">
            <i class="ni ni-bell-55"></i>
            <span class="ml--1">
              Уведомления
              <a-badge
                  v-if="newNotifications.length > 0"
                  variant="gradient-danger"
                  pill
              >
                {{ newNotifications.length }}
              </a-badge>
            </span>
          </router-link>

          <router-link to="/user/settings" class="dropdown-item">
            <i class="ni ni-settings"></i>
            <span>Настройки</span>
          </router-link>

<!--          <router-link to="/user/profile" class="dropdown-item">-->
<!--            <i class="ni ni-single-02"></i>-->
<!--            <span>Профиль</span>-->
<!--          </router-link>-->

          <a @click.prevent="addReview" class="dropdown-item text-grey pointer">
            <i class="ni ni-like-2"></i>
            <span>Оставить отзыв</span>
          </a>

          <a @click.prevent="logout" class="dropdown-item text-danger pointer">
            <i class="ni ni-user-run"></i>
            <span>Выход</span>
          </a>

        </template>
      </base-dropdown>
    </ul>

    <search-modal :keywords="keywords" @clear-keywords="clearKeywords" />
    <review-modal />

  </base-nav>
</template>
<script>
import ActionsUser from "./NavbarHead/ActionsUser";
import SearchModal from "../components/Modals/SearchModal";
import Notifications from "./NavbarHead/Notifications";
import ReviewModal from "../components/Modals/ReviewModal.vue";

export default {
  components: {
    ReviewModal,
    ActionsUser,
    SearchModal,
    Notifications
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      avatar: null,
      keywords: '',
    };
  },
  watch: {
    keywords: {
      async handler(value) {
        if (value.length > 0) {
          await this.search();
        }
      }
    }
  },
  // TODO Уведомления
  async created() {
    // await this.checkNotifications(false);
    // const interval = setInterval(this.checkNotifications, 10000, false);
    // this.$on('hook:beforeDestroy', () => clearInterval(interval));
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    avatarUrl() {
      const avatarUrl = this.user.avatar_url;
      return this.user.avatar_id ? avatarUrl : null;
    },
    isManagerPanel() {
      return this.$route.path.includes('manager');
    },
    isManager() {
      const roleManager = 1;
      return this.$store.getters.user.role === roleManager;
    }
  },
  methods: {
    search() {
      this.$bvModal.show('searchModal');
    },
    addReview() {
      this.$bvModal.show('reviewModal');
    },
    clearKeywords() {
      this.keywords = '';
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },
};
</script>

<style>
#sidenav-main .navbar-brand {
  color: var(--indigo);
}

.dropdown-actions, .shortcut-item {
  max-width: 100% !important;
  text-decoration: none !important;
  color: white !important;
  text-align: center !important;
}

.shortcut-item {
  padding: 15px;
}

.dropdown-actions {
  min-width: 300px !important;
  -webkit-box-shadow: 0px 1px 8px 6px rgba(34, 60, 80, 0.2) !important;
  -moz-box-shadow: 0px 1px 8px 6px rgba(34, 60, 80, 0.2) !important;
  box-shadow: 0px 1px 8px 6px rgba(34, 60, 80, 0.2) !important;
}
</style>
