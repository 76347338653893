<template>
  <base-multiselect
      :options="filteredOptions"
      :multiple="multiple"
      :custom-label="customLabel"
      :disabled="disabled"
      :value="objectValue"
      :searchable="searchable"
      :track-by="trackBy"
      :label="label"
      select-label=""
      deselect-label=""
      selected-label=""
      selected-label-text=""
      :placeholder="placeholderText"
      :limit="5"
      :limit-text="limitText"
      :allow-empty="allowEmpty"
      :size="size"
      :state="state"
      @input="updateValue"
      @search-change="handleSearchChange"
  >
    <template slot="tag" slot-scope="props">
      <span class="multiselect__tag">
        <span>{{ props.option[tagLabel || label] }}</span>
        <i aria-hidden="true" tabindex="1" class="multiselect__tag-icon"
           @keydown.enter.prevent="props.remove(props.option)" @mousedown.prevent="props.remove(props.option)"
        ></i>
      </span>
    </template>
    <span slot="noResult">Ничего не найдено</span>
  </base-multiselect>
</template>

<script>
import BaseMultiselect from "./BaseMultiselect";

export default {
  name: 'LSelect',
  components: { BaseMultiselect },
  props: {
    value: null,
    options: null,
    dict: null,
    size: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'label',
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    addDefaultOption: {
      type: Boolean,
      default: false,
    },
    defaultOptionLabel: {
      type: String,
      default: 'Все',
    },
    customLabel: {
      type: Function,
    },
    tagLabel: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    placeholderText: {
      type: String,
      default: 'Выберите значение',
    },
    state: {
      type: Boolean,
      default: null,
    },
  },

  data() {
    return {
      // placeholderText: this.$t('components.l_select.placeholder')
      // placeholderText: 'Выберите значение'
    };
  },

  computed: {
    defaultOption() {
      const option = {
        value: null,
      };
      const labelKey = this.label ? this.label : 'label';
      option[labelKey] = this.defaultOptionLabel;
      option['fdv'] = this.defaultOptionLabel;
      return option;
    },
    /**
     * Ищем объект по переданному id
     * @returns {*}
     */
    objectValue() {
      if (Array.isArray(this.value) && !this.multiple) {
        throw new Error('Для l-select с одиночным выбором передаваемое значение не может быть массивом! Значение по умолчанию - null или id.');
      }

      // если мультиселект
      if (Array.isArray(this.value)) {
        return this.filteredOptions.filter((filteredItem) => this.value.indexOf(filteredItem.value) > -1);
      } // если одинарный
      for (const i in this.filteredOptions) {
        if (this.value === this.filteredOptions[i].value) {
          return this.filteredOptions[i];
        }
      }

      return null;
    },

    filteredOptions() {
      let _options = [];
      /**
       * Если задан словарь, то используем его в качестве options + добавляем к нему пустой элемент в начало
       */
      if (this.dict) {
        _options = this.$store.getters.getDict(this.dict).slice();

        if (!this.multiple && this.addDefaultOption) {
          _options.unshift(this.defaultOption);
        }

        _options = _options.map((item) => ({
          ...item,
          value: item.id,
          label: item.fdv,
        }));

      }
      /**
       * Если словарь не задан, то используем объект options
       */
      else {
        _options = this.options.slice();

        if (!this.multiple && this.addDefaultOption) {
          _options.unshift(this.defaultOption);
        }
      }

      return _options;
    },

    limitText() {
      return (count) => `+ ${count}`;
    },
  },

  methods: {
    updateValue(v) {
      /**
       * По умолчанию либа в качестве значения использует не примитивный id, а весь объект
       * Поэтому наружу передаем только id из этого обюъекта
       */

      if (Array.isArray(v)) {
        this.$emit('input', v.map((item) => item.value));
        if (v.length === 0) {
          // this.placeholderText = this.$t('components.l_select.placeholder')
          this.placeholderText = 'Выберите значение';
        } else {
          this.placeholderText = '';
        }
      } else if (v !== null) {
        this.$emit('input', v.value);
      } else if (this.multiple) this.$emit('input', []);
      else this.$emit('input', null);
    },

    handleSearchChange(search) {
      this.$emit('search-change', search);
    }
  },
};
</script>

<style>
.multiselect-dropdown {
  max-width: 100%;
  overflow-y: scroll;
  margin-bottom: -30rem;
}

.multiselect-option {
  white-space: normal;
}
</style>