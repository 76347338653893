import Resource from "../resource";
import axios from "axios";

export default class UserResource extends Resource {
    static storeProfiles(params, userId) {
        const url = `${this.backendUrl}/${this.url}/store-profiles/${userId}`;
        const { data } = axios.post(url, params);
        return data;
    }

    static async uploadAvatar(params, userId) {
        const url = `${this.backendUrl}/${this.url}/${userId}/upload-avatar`;
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        const { data } = await axios.post(url, params, config);
        return data;
    }

    static changePassword(params, userId) {
        const url = `${this.backendUrl}/${this.url}/${userId}/change-password`;
        const { data } = axios.post(url, params);
        return data;
    }

    static async login(userId) {
        const url = `${this.backendUrl}/${this.url}/${userId}/login`;
        const { data } = await axios.post(url, {});
        return data;
    }

    static async resetPassword(params) {
        const url = `${this.backendUrl}/${this.url}/reset-password`;
        const { data } = await axios.post(url, params);
        return data;
    }
}

UserResource.url = 'user';
