<template>
  <module
      title="Платежи"
      subtitle="Оплатить неоплаченные платежи"
      bg-header="gradient-primary"
      color-header="white"
  >
    <ul class="list-group list-group-flush list my--3">

      <template v-if="!isLoading">

        <li class="list-group-item px-0" v-for="entry in entriesUnpaid" :key="entry.id">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="icon icon-shape bg-gradient-danger text-white rounded-circle text-lg p-3">
                ₽
                <!--                <icon :class="`${entry.service.type.icon} text-${entry.service.type.color}`" />-->
              </div>
            </div>
            <div class="col ml--2">
              <a-badge variant="gradient-danger" text-class="h4">{{ entry.price }} ₽ </a-badge>
              <!--              <icon :class="`${entry.service.type.icon} text-${entry.service.type.color} pl-4 pr-1`" />-->
              <br>
              <small> {{ entry.service.title.substring(0, 20) }}... </small>
            </div>
            <div class="col-auto">
              <b-btn size="sm" variant="primary" @click="pay(entry)"> Оплатить </b-btn>
            </div>
          </div>
        </li>

        <template v-if="entriesUnpaid.length < 4">
          <template v-for="entry in entriesPaid">
            <li
                class="list-group-item px-0"
                :key="`paid-${entry.id}`"
            >
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="icon icon-shape bg-gradient-cyan text-white rounded-circle text-lg p-3">
                    ₽
                  </div>
                </div>
                <div class="col ml--2">
                  <a-badge variant="gradient-cyan" text-class="h4">{{ entry.price }} ₽</a-badge><br>
                  <small> {{ entry.service.title.substring(0, 25) }}... </small>
                </div>
                <div class="col-auto mr-2 text-muted">
                  <small>оплачено</small>
                </div>
              </div>
            </li>
          </template>
        </template>

      </template>
      <template v-else>
        <li class="list-group-item px-0" v-for="(item, index) in [1, 2, 3, 4]" :key="index">
          <b-skeleton />
        </li>
      </template>

    </ul>
  </module>
</template>

<script>
import paymentMixin from "../../../../mixins/payment-mixin";
import Module from "./Module";
import EntryResource from "../../../../resources/entry";

export default {
  name: "Payment",
  components: {
    Module
  },
  mixins: [
    paymentMixin
  ],
  data() {
    return {
      entries: [],
      entriesUnpaid: [],
      entriesPaid: [],
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.entries = await EntryResource.fetch({
        created_by: this.$store.getters.user.id,
      }, 1, 5);
      if (this.entries.length) {
        this.entriesUnpaid = this.entries.filter(elm => !elm.is_paid);
        this.entriesPaid = this.entries.filter(elm => elm.is_paid);
      }
      this.isLoading = false;
    },
  },
}
</script>

<style scoped>
.icon {
  width: 0;
  height: 0;
}
</style>