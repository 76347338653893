<template>
  <div class="row justify-content-center mt-md-7">
    <div class="col-md-8 col-sm-12">
      <div class="card bg-transparent border-0">
        <div class="card-body px-lg-5">

          <b-row>
            <b-col class="text-right pr-4 d-none d-md-block text-white">
              <h1 class="text-white">Вход</h1>
              <p class="text-lead">
                Если вы были ранее зарегистрированы на одном из наших ресурсов:
                АНОО "АНЭКС" , Журнал "Экстернат.РФ", Журнал "Педагогика онлайн",
                Личный кабинет АНЭКС, то повторная регистрация не требуется.
              </p>
            </b-col>
            <b-col class="ml-4 bg-white p-4 rounded-lg">
              <div class="text-xl text-center font-weight-bolder text-indigo pb-3">
                <!--        <img :src="logo" class="navbar-brand-img" alt="..." />-->
                АНЭКС
              </div>
              <form role="form" @submit.prevent="handleSubmit()">
                <base-input
                    alternative
                    class="input-group-alternative mb-3"
                    placeholder="Email"
                    addon-left-icon="ni ni-email-83"
                    v-model="user.email"
                    name="Email"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.email" />

                <base-input
                    alternative
                    class="input-group-alternative"
                    placeholder="Пароль"
                    type="password"
                    addon-left-icon="ni ni-lock-circle-open"
                    v-model="user.password"
                    name="Password"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.password" />

                <b-row>
                <!--      TODO      -->
                <b-col cols="8 pt-2">
                  <base-checkbox class="ml-1">
                    <span class="ml--3">Запомнить меня</span>
                  </base-checkbox>
                </b-col>
                <b-col>
                  <div class="text-center">
                    <base-button
                        native-type="submit"
                        class="btn-login bg-purple border-0 rounded-pill"
                    >
                      Вход
                    </base-button>
                  </div>
                </b-col>
                </b-row>
              </form>
            </b-col>
          </b-row>

        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/password/reset" class="text-light">
            <small>
              Забыли пароль?
            </small>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light">
            <small>
              Регистрация
            </small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
export default {
  name: "login-pill-alternative",
  components: {
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.getters.isAuthenticated();
    },
  },
  methods: {
    async handleSubmit() {
      const user = this.user;

      try {
        await this.$store.dispatch("login", { user });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Неверные данные!",
        });
        // this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>

<style>
.input-group-alternative .form-control {
  padding-left: 0.5rem !important;
}
</style>
