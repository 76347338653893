import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
// import { GeneralHtmlSupport } from '@ckeditor/ckeditor5-html-support';
import { Bold, Italic, Underline } from '@ckeditor/ckeditor5-basic-styles';
import Link from '@ckeditor/ckeditor5-link/src/link';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Heading from '@ckeditor/ckeditor5-heading/src/heading';
import RemoveFormat from '@ckeditor/ckeditor5-remove-format/src/removeformat';
import {List} from "@ckeditor/ckeditor5-list";
import {Table} from "@ckeditor/ckeditor5-table";
import {MediaEmbed} from "@ckeditor/ckeditor5-media-embed";
import {BlockQuote} from "@ckeditor/ckeditor5-block-quote";
import {Alignment} from "@ckeditor/ckeditor5-alignment";
// import {SourceEditing} from "@ckeditor/ckeditor5-source-editing";
import {
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload
} from '@ckeditor/ckeditor5-image';
// import Font from '@ckeditor/ckeditor5-font/src/font';

export default {
  data(){
    return {
      ckEditor: ClassicEditor,
      editorData: 'Контент',
      editorConfig: {
        ckfinder: {
          uploadUrl: 'http://www.mypage.com/api/uploadckeditor'
        },
        plugins: [
          // Font,
          Essentials,
          Paragraph,
          Bold,
          Italic,
          Underline,
          Link,
          BlockQuote,
          Heading,
          RemoveFormat,
          List,
          Table,
          MediaEmbed,
          Alignment,
          // SourceEditing,
          // GeneralHtmlSupport, # TODO Почему не работает?

          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
        ],
        // htmlSupport: {
        //   allow: [
        //     {
        //       name: /.*/,
        //       attributes: true,
        //       classes: true,
        //       styles: true
        //     }
        //   ]
        // },
        toolbar: {
          items: [
            'heading',
            // 'fontColor',
            // 'fontBackgroundColor',
            'alignment',
            '|',
            'bold',
            'italic',
            'underline',
            '|',
            // 'alignment',
            // 'indent',
            // 'outdent',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'link',
            'blockQuote',
            '|',
            'insertTable',
            'mediaEmbed',
            // 'imageUpload',
            '|',
            'removeFormat',
            // '|',
            // 'sourceEditing',

            // 'fontSize',
            // 'fontFamily',
            // 'strikethrough',
            // '|',
            // 'undo',
            // 'redo',
            // 'CKFinder'
            // 'imageUpload', # TODO Загрузка картинок

          ],
          shouldNotGroupWhenFull: true,
        },
        image: {
          toolbar: [
            'imageTextAlternative',
            'toggleImageCaption',
            'imageStyle:inline',
            'imageStyle:block',
            'imageStyle:side'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells'
          ]
        },
        licenseKey: ''
      },
    };
  },
  methods: {
    onReady( editor )  {
      editor.config._config.allowedContent = true;
      editor.config.allowedContent = true;
      editor.allowedContent = true;
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
    },
  }
};
