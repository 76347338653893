<template>
  <span
      class='text-white ml-2 pl-1 pr-1'
      :class="`bg-${variant} ${pill ? 'rounded-circle' : ''} ${textClass ? textClass : 'text-xs'}`"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: "ABadge",
  props: {
    textClass: {},
    variant: {
      type: String
    },
    pill: {
      type: Boolean
    },
  }
}
</script>

<style scoped>

</style>