<template>
  <b-btn
      align="center"
      tag="article"
      class="mb-5 mt-2 col-12 col-xl-4 bg-transparent border-0 service-package-card mx-0 px-3 shadow-none"
  >
    <b-card-header
        header-bg-variant="purple"
        header-text-variant="white"
    >
      <b-skeleton class="center" width="50%" />
    </b-card-header>

    <b-card-body class="bg-white">
      <b-skeleton width="90%" class="mt-3"/>
      <b-skeleton class="mt-3"/>
      <b-skeleton width="70%" class="mt-3"/>
      <b-list-group flush>
        <b-list-group-item/>
        <b-list-group-item>
          <b-skeleton class="center" width="50%"/>
        </b-list-group-item>
        <b-list-group-item>
          <b-skeleton class="center" width="80%"/>
        </b-list-group-item>
        <b-list-group-item>
          <b-skeleton class="center" width="70%"/>
        </b-list-group-item>
      </b-list-group>
    </b-card-body>
  </b-btn>
</template>

<script>
export default {
  name: "ServicePackageSkeleton"
}
</script>

<style scoped>

</style>