<template>
  <div>
    <label class="form-control-label" :class="`text-${color} ${labelClass}`">
      {{ label }}
      <span class="text-danger" v-if="required">*</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "ALabel",
  props: {
    label: {
      type: String,
    },
    color: {
      type: String,
    },
    labelClass: {
      type: String,
      default() {
        return 'mb-3';
      },
    },
    required: {
      type: Boolean,
      default: () => { return false }
    }
  }
}
</script>

<style scoped>

</style>