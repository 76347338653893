import Vue from 'vue'
Vue.use(Router)
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import store from "./store";

import Router from 'vue-router'
import UserLayout from '@/layout/UserLayout'
import AuthLayout from '@/layout/AuthLayout'

import Dashboard from "./app/user/Dashboard/Dashboard";
import Icons from "./app/manager/SpecialFeatures/Icons";
import UserProfile from "./app/user/Profile/UserProfile";
import Login from "./app/auth/Login/Login";
import LoginPill from "./app/auth/Login/LoginPill";
import LoginPillAlternative from "./app/auth/Login/LoginPillAlternative";
import Register from "./app/auth/Register";
import Reset from "./app/auth/Password/Reset";
import Email from "./app/auth/Password/Email";
import UsersList from "./app/manager/User/UsersList";
import ManagerDashboard from "./app/manager/Dashboard/ManagerDashboard";
import ManagerLayout from "./layout/ManagerLayout";
import ProfileSettings from "./app/user/Profile/ProfileSettings";
import Service from "./app/shared/Service/Service";
import ApiRequests from "./app/manager/SpecialFeatures/ApiRequests";
import EntryMain from "./app/manager/Entry/EntryMain";
import ServiceTypeList from "./app/manager/Dict/ServiceType/ServiceTypeList";
import ProfileList from "./app/manager/Dict/ProfileList";
import RegionList from "./app/manager/Dict/RegionList";
import ServicePackageManagement from "./app/manager/ServicePackage/ServicePackageManagement";
import ServicePackageList from "./app/user/ServicePackage/ServicePackageList";
import LeadingList from "./app/manager/Dict/LeadingList";
import UserEntryList from "./app/user/Entry/UserEntryList";
import Macroses from "./app/manager/Template/Macros";
import Template from "./app/manager/Template/Template";
import Event from "./app/manager/Template/Event";
import Mailing from "./app/manager/SpecialFeatures/Mailing";
import Notification from "./app/user/Notification/Notification";
import PaymentMain from "./app/manager/SpecialFeatures/PaymentMain";
import PublicationCategoryList from "./app/manager/Dict/PublicationCategoryList";
import UserPublicationList from "./app/user/Publication/UserPublicationList";
import PublicationManagement from "./app/manager/Publication/PublicationManagement";
import PrintDocument from "./app/shared/Entry/PrintDocument.vue";
import ReviewMain from "./app/manager/SpecialFeatures/Review/ReviewMain.vue";
import YKassa from "./app/manager/YKassa/YKassa.vue";
import Pay from "./app/auth/Pay.vue";
import PublicationReleaseList from "./app/manager/Dict/PublicationReleaseList.vue";

const manager = 1;

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: Login,
          // component: LoginPill,
          // component: LoginPillAlternative,
          meta: { isAuthPage: true },
        },
        {
          path: '/register',
          name: 'register',
          component: Register,
          meta: { isAuthPage: true },
      },
      {
        path: "/password/reset",
        name: "reset-password",
        component:  Reset,
        meta: { isAuthPage: true },
      },
      {
        path: "/password/email",
        name: "remind-password",
        component: Email,
        meta: { isAuthPage: true },
      },
      {
        path: "/pay",
        name: "pay",
        component: Pay,
        meta: { isAuthPage: true },
      },
      ]
    },
    {
      path: "/user",
      component: UserLayout,
      name: "users",
      children: [
        {
          path: 'dashboard',
          name: 'Главная',
          component: Dashboard,
          meta: { middleware: auth },
        },
        {
          path: "profile",
          name: "Профиль",
          component: UserProfile,
          meta: { middleware: auth }
        },
        {
          path: "entry/:from?",
          name: "Мои заявки",
          component: UserEntryList,
          meta: { middleware: auth }
        },
        {
          path: "publication",
          name: "Мои публикации",
          component: UserPublicationList,
          meta: { middleware: auth }
        },
        {
          path: "service/:id?",
          name: "Запись на услуги",
          component: Service,
          meta: { middleware: auth }
        },
        {
          path: "service-package",
          name: "Пакеты",
          component: ServicePackageList,
          meta: { middleware: auth }
        },
        {
          path: 'settings',
          name: 'Настройки профиля',
          component: ProfileSettings
        },
        {
          path: 'notifications',
          name: 'Уведомления',
          component: Notification
        },
      ]
    },

    {
      path: "/manager",
      component: ManagerLayout,
      name: "Оператор",
      children: [
        {
          path: 'dashboard',
          name: 'Панель оператора',
          component: ManagerDashboard,
          meta: { middleware: auth, role: manager }
        },
        {
          path: "users",
          name: "Пользователи",
          component: UsersList,
          meta: { middleware: auth, role: manager }
        },
        {
          path: "service/:id?",
          name: "Услуги",
          component: Service,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'service-package',
          name: 'Пакеты услуг',
          component: ServicePackageManagement,
          meta: { middleware: auth, role: manager }
        },
        {
          path: "entry",
          name: "Заявки",
          component: EntryMain,
          meta: { middleware: auth, role: manager }
        },
        {
          path: "publication",
          name: "Публикации",
          component: PublicationManagement,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'icons',
          name: 'Иконки',
          component: Icons,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'mailing',
          name: 'Рассылка',
          component: Mailing,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'leadings',
          name: 'Ведущие',
          component: LeadingList,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'service-types',
          name: 'Типы услуг',
          component: ServiceTypeList,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'profiles',
          name: 'Профили',
          component: ProfileList,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'publication-categories',
          name: 'Рубрики',
          component: PublicationCategoryList,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'publication-releases',
          name: 'Выпуски',
          component: PublicationReleaseList,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'regions',
          name: 'Регионы',
          component: RegionList,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'api-requests',
          name: 'Запросы API',
          component: ApiRequests,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'macroses',
          name: 'Макросы',
          component: Macroses,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'templates',
          name: 'Шаблоны',
          component: Template,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'events',
          name: 'События',
          component: Event,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'payments',
          name: 'Платежи',
          component: PaymentMain,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'yookassa',
          name: 'Платежи ЮКассы',
          component: YKassa,
          meta: { middleware: auth, role: manager }
        },
        {
          path: 'reviews',
          name: 'Отзывы',
          component: ReviewMain,
          meta: { middleware: auth, role: manager }
        }
      ]
    },
    //   TODO Печатать документы заявки адекватным способом
    {
      path: "/entry/:id?/print/:type?",
      name: "Печать",
      component: PrintDocument,
      meta: { middleware: auth }
    },
  ]
});

router.beforeEach((to, from, next) => {

  const token = localStorage.getItem('x-xsrf-token');

  let isCertainUrl = (to.name !== 'login');
  if (!to.meta.isAuthPage) localStorage.setItem('to', to.fullPath);

  if (to.meta.middleware && !token) {
    router.push({path: '/login'});
  }

  if (to.name === 'login' && !!token) {
    router.push('/user/dashboard');
  }

  if (to.meta.role === manager) {
    if (
      (store.getters.user.role && store.getters.user.role !== manager) ||
      !store.getters.user.role &&
      !isCertainUrl
    ) {
      router.push('/user/dashboard');
    }
  }

  return next();
});

export default router;
