export default {
  data() {
    return {
        //
    };
  },
  computed: {
      /**
       * Проверка на роль у текущего пользователя и адрес страницы
       * @returns {boolean}
       */
      isManager() {
          const user = this.$store.getters.user;
          const roleManager = 1;
          const isUserManager = user.role === roleManager;
          const isManagerPanel = this.$route.path.includes('manager');
          return isUserManager && isManagerPanel;
      }
  }
};
