<template>
  <b-modal
      size="lg"
      title="Редактирование рубрики"
      title-class="text-lg p-2 text-center"
      body-class="mt--4 mb--5"
      id="itemFormsModal"
      centered
      cancel-title="Закрыть"
      ok-title="Сохранить"
      @ok="saveItem"
  >
    <a-label label="Категория в Joomla (Экстернат.РФ)" />
    <l-select
        class="text-wrap mb-3"
        :disabled="joomlaCategories.length < 1"
        :options="joomlaCategories"
        v-model="item.joomla_category_id"
    />

    <b-form-group label="Название">
      <b-form-input v-model="item.fdv" placeholder="Название" />
    </b-form-group>

    <b-form-group label="Алиас">
      <b-form-input v-model="item.alias" placeholder="Алиас" />
    </b-form-group>

    <b-form-group label="Описание">
      <b-form-textarea rows="4" v-model="item.description" placeholder="Описание" />
    </b-form-group>

  </b-modal>
</template>

<script>
import JoomlaResource from "../../../../resources/joomla";
import DictResource from "../../../../resources/dict";

export default {
  name: "ItemFormsModal",
  data() {
    return {
      joomlaCategories: [],
    }
  },
  props: {
    item: {
      type: Object,
    },
  },
  async mounted() {
    const joomlaCategories = await JoomlaResource.fetchPublicationCategories();
    this.joomlaCategories = joomlaCategories.map(item => ({
      ...item,
      label: `${item.id}. ${item.title} (${item.alias})`,
      value: parseInt(item.id),
    }));
  },
  methods: {
    async saveItem() {
      DictResource.setUrl('dict_publication_categories');
      await this.validateAndSave(DictResource);
    },
  },
}
</script>

<style scoped>

</style>