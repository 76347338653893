export default {
  data() {
    return {
        //
    };
  },
  methods: {
      /**
       * Добавляет элемент в противоположный
       * массив и удаляет из старого
       * @param elm
       * @param array Массив полей для текущего списка
       */
      replaceArrayElm(elm, array) {
          const elmIndex = array.indexOf(elm);
          const spliceLength = 1;
          array.splice(elmIndex, spliceLength);
      },
  }
};
