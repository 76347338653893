<template>
  <div class="container-fluid mt-5 w-75">
    <template v-if="!isLoading">
      <div v-html="service.description" />
      <publication-forms
          :item="item"
          :service="service"
          @reset="$emit('reset-subtype')"
      />
    </template>
    <!--  Загрузка страницы  -->
    <template v-else>
      <b-skeleton width="75%" />
      <b-skeleton width="85%" />
      <b-skeleton width="80%" />
      <b-skeleton width="70%" />
      <b-skeleton width="75%" />
      <b-skeleton width="85%" />
      <b-skeleton width="80%" />
      <b-skeleton width="70%" />
      <b-skeleton-img class="mt-4" Jnlt/>
    </template>
  </div>
</template>

<script>
import ServiceResource from "../../../resources/service";
import PublicationForms from "../../shared/Publication/PublicationForms";
const TYPE_ARTICLE = 1;

export default {
  name: "PublicationEntry",
  components: {
    PublicationForms,
  },
  data() {
    return {
      service: {
        subtype: {},
      },
      item: {
        service_id: null,
        title: '',
        text: '',
        type: TYPE_ARTICLE,
        additionalFiles: [],
      },
    }
  },
  props: {
    selectedSubType: {
      type: Object,
    },
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    selectedSubType: {
      async handler() {
        await this.fetchData();
      }
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const services = await ServiceResource.fetch({
        dict_subtype_id: this.selectedSubType.id,
      });
      this.service = services[0];
      this.$emit('set-publication', this.service);
      this.item.service_id = this.service.id;
      this.isLoading = false;
    },
  },
}
</script>

<style scoped>

</style>