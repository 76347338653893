<template>
  <div class="wrapper bg-gradient-indigo" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      class="nav-user"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Главная',
            icon: 'ni ni-chart-bar-32 text-primary',
            path: '/user/dashboard',
          }"
        />

        <sidebar-item
            :link="{
            html: `Мои заявки ${spanDebt}`,
            name: `Мои заявки`,
            icon: 'ni ni-send text-green',
            path: '/user/entry',
          }"
        />

        <sidebar-item
            :link="{
            name: 'Услуги',
            icon: 'ni ni-archive-2 text-pink',
            path: '/user/service',
          }"
        />

        <sidebar-item
            :link="{
            name: 'Пакеты услуг',
            icon: 'ni ni-app text-orange',
            path: '/user/service-package',
          }"
        />

        <sidebar-item
            :link="{
            html: `Мои публикации`,
            name: `Мои публикации`,
            icon: 'ni ni-single-copy-04 text-info',
            path: '/user/publication',
          }"
        />

        <sidebar-item
            :link="{
            name: 'Профиль',
            icon: 'ni ni-single-02 text-yellow',
            path: '/user/profile',
          }"
        />

      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">

      <navbar-head />

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter" />
      </div>
    </div>
  </div>
</template>
<script>
import NavbarHead from "./NavbarHead.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    NavbarHead,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "primary", //vue|blue|orange|green|red|primary
    };
  },
  computed: {
    spanDebt() {
      const leftSum = this.currentUser.left_sum;
      const classes = 'bg-gradient-danger text-xs text-white ml-2 pl-1 pr-1';
      return leftSum > 0 ?
          `<span class='${classes}'>${leftSum}&nbsp; ₽</span>` :
          '';
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
</style>
