<template>
  <div class="container mt-md-4 pb-5">
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-secondary border-0 mb-3">
          <div class="card-body px-lg-5 py-lg-4">
            <div class="text-center">
              <h3>Оплата</h3>
            </div>

            <form role="form" @submit.prevent="handleSubmit()">
              <a href="#" target="_blank" v-if="showError">
                <b-alert show variant="danger">
                  Пожалуйста, введите все поля!
                </b-alert>
              </a>

              <a href="https://aneks.center/o-tsentre/rekvizity" target="_blank">
                <b-alert show variant="primary">
                  <span class="dotted">Наши реквизиты</span>.
                  Введите ниже сумму оплаты, ФИО и номер телефона, по которому
                  с вами сможет связаться оператор.
                </b-alert>
              </a>

              <base-input
                alternative
                class="input-group-alternative mb-3"
                placeholder="ФИО"
                addon-left-icon="ni ni-single-02"
                v-model="item.fio"
                name="Email"
              />
              <validation-error :errors="apiValidationErrors.fio" />

              <base-input
                  alternative
                  v-inputmask="'+7 (999) 999-99-99'"
                  placeholder="+7 (---) --- - -- - --"
                  class="input-group-alternative mb-3"
                  addon-left-icon="ni ni-tablet-button"
                  v-model="item.phone"
              />
              <validation-error :errors="apiValidationErrors.phone" />

              <base-input
                alternative
                class="input-group-alternative mb-3"
                placeholder="Сумма, руб."
                addon-left-icon="ni ni-money-coins"
                v-model="item.sum"
                name="Email"
              />
              <validation-error :errors="apiValidationErrors.sum" />

              <div class="text-center">
                <!-- <base-button type="submit" @click.prevent="handleSubmit" class="my-4">Sign in</base-button> -->
                <base-button
                    native-type="submit"
                    class="my-4 btn-login bg-purple border-0"
                >
                  Оплатить
                </base-button>
              </div>
            </form>
          </div>
        </div>

        <div class="row mt--3">
          <div class="col-6">
            <router-link to="/register" class="text-light text-lg">
              <small>
                Регистрация
              </small>
            </router-link>
          </div>
          <div class="col-6 text-right">
            <router-link to="/login" class="text-light text-lg">
              <small>
                Вход
              </small>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import paymentMixin from "../../mixins/payment-mixin";
export default {
  name: "Pay",
  mixins: [formMixin, paymentMixin],
  components: { ValidationError },
  data() {
    return {
      item: {
        fio: '',
        sum: '',
      },
      showError: false,
    };
  },
  methods: {
    async handleSubmit() {
      try {
        await this.payIndependently(this.item);
        // await this.$notify({
        //   type: "success",
        //   message: "Новый пароль был отправлен на указанный email",
        // });
      } catch (error) {
        await this.$notify({
          type: "danger",
          message: "Введите все поля!",
          time: 700,
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>