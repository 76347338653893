<template>
  <div class="row justify-content-center mt-md-6">
    <div class="col-md-8 col-sm-12">
      <div class="card bg-white shadow border-0">
        <div class="card-body px-lg-5">
          <h1>Это ваш аккаунт?</h1>
          <div class="mt-4 mb-1 lead">
            <img
                src="/img/no-avatar.png"
                class="rounded-circle bg-size-cover"
                width="50"
            />
            <span class="ml-2" v-if="$store.getters.oldUser.id">
              <b> {{ $store.getters.oldUser.fio }} </b> -
              {{ $store.getters.oldUser.email }}
            </span>
          </div>

            <div class="text-center">
              <b-btn
                  @click="sendPassword"
                  variant="primary"
                  class="my-4"
                  :disabled="!$store.getters.oldUser.id"
              >
                Да, отправить новый пароль
              </b-btn>
              <router-link to="/register">
                <b-btn variant="secondary" class="my-4">
                  Нет, вернуться
                </b-btn>
              </router-link>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserResource from "../../../resources/user";

export default {
  layout: "AuthLayout",
  methods: {
    async sendPassword() {
      await UserResource.resetPassword({
        email: this.$store.getters.oldUser.email
      });
      this.$notify({
        type: "success",
        message: "Новый пароль был отправлен на указанный email",
      });
      await this.$router.push('/login');
    }
  },
};
</script>
