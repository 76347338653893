import axios from "axios";

export default class Resource {

    static backendUrl = `${process.env.VUE_APP_API_BASE_URL}/api`;
    static url;

    static dataPagination;

    static indexPagination = 0;
    static indexData = 1;

    /**
     * Получает все записи
     * TODO Рефактор
     * @returns {Promise<void>}
     */
    static async fetch(
      params = {},
      paginationPage = undefined,
      pageSize = undefined
    ) {
        if (params == undefined) params = {};
        if (paginationPage !== undefined) {
            let paginator = {
                current_page: paginationPage
            };
            if (pageSize !== undefined)
                paginator['page_size'] = pageSize;

            const { data } = await axios.post(
                `${this.backendUrl}/${this.url}/index`,
                { params, paginator }
            );
            this.dataPagination = data[this.indexPagination];
            return data[this.indexData];
        } else {
            const { data } = await axios.post(
                `${this.backendUrl}/${this.url}/index`,
                { params }
            );
            return data;
        }
    }

    /**
     * Получает одну запись
     * @param id
     * @returns {Promise<void>}
     */
    static async get(id) {
        const { data } = await axios.post(
            `${this.backendUrl}/${this.url}/show/${id}`
        );
        return data;

    }

    /**
     * Сохраняет запись
     * @param params
     * @returns {Promise<void>}
     */
    static async save(params, config = {}) {
        if (config === undefined) config = {};
        const isFormData = params instanceof FormData;
        const isNew = params.id == undefined;
        if ((isNew && !isFormData) || (isFormData && !params.has('id'))) {
            const { data } = await axios.post(
                `${this.backendUrl}/${this.url}/store`,
                params,
                config
            );
            return data;
        } else {
            const { data } = await axios.post(
                `${this.backendUrl}/${this.url}/update`,
                params,
                config
            );
            return data;
        }
    }

    /**
     * Удаляет запись
     * @param id
     * @returns {Promise<void>}
     */
    static async delete(id) {
        const { data } = await axios.post(
            `${this.backendUrl}/${this.url}/destroy/${id}`
        );
        return data;
    }
}