import Resource from "../resource";
import axios from "axios";

export default class YookassaEntryResource extends Resource {
	static async pay(params) {
		const url = `${this.backendUrl}/${this.url}/pay`;
		const { data } = await axios.post(url, { params });
		// await this.setUuid(entryId, data.id);
		return data;
	}
}

YookassaEntryResource.url = 'yookassa-entry';