import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import profile from "./modules/profile";
import reset from "./modules/reset";
import dict from "./modules/dict";
import statistics from "./modules/statistics";
import notifications from "./modules/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    profile,
    dict,
    statistics,
    notifications,
    reset
  },
  state: {
    isAppLoading: true,
    isUserLoading: true,
    isStatsLoading: true,
  },
  getters: {
    isAppLoading: state => state.isAppLoading,
    isUserLoading: state => state.isUserLoading,
    isStatsLoading: state => state.isStatsLoading,
  },
  mutations: {
    SET_APP_LOADING: (state, payload) => {
      state.isAppLoading = payload;
    },
    SET_USER_LOADING: (state, payload) => {
      state.isAppLoading = payload;
    },
    SET_STATS_LOADING: (state, payload) => {
      state.isAppLoading = payload;
    },
  },
  actions: {
    async INITIALIZE ({commit, dispatch}, dictNames) {
      await dispatch('initDicts', dictNames);
      commit('SET_APP_LOADING', false);
      try {
        await dispatch('me');
      } catch (e) {
        // console.log(e.response)
      }
      commit('SET_USER_LOADING', false);
      await dispatch('initStatistics');
      commit('SET_STATS_LOADING', false);
      // await dispatch('notifications');
    },
  },
});
