<template>
  <card body-classes="px-0 pb-1" footer-classes="pb-2">
    <div class="card-header pt-0 border-0">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0">Платежи ЮКасса</h3>
        </div>
      </div>
    </div>

    <payment-list
        :loading="isLoading"
        :items="items"
        :items-pagination="itemsPagination"
        @on-update="fetchData"
    />

  </card>
</template>
<script>
import PaymentResource from "../../../../resources/payment";
import PaymentList from "./PaymentList";
import YookassaEntryResource from "../../../../resources/yookassa-entry";

export default {
  name: "Payment",
  components: {
    PaymentList
  },
  data() {
    return {
      items: [],
      itemsPagination: {},
    };
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData(page, filters) {
      this.isLoading = true;
      if (page === undefined) page = 1;
      if (filters === undefined) filters = {};
      this.items = await YookassaEntryResource.fetch(filters, page);
      this.itemsPagination = YookassaEntryResource.dataPagination;
      this.isLoading = false;
    },
  },
};
</script>
<style>
</style>
