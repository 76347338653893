<template>
  <a-dot-menu>
    <slot>
      <!--   Кастомные пункты   -->
    </slot>
    <b-dropdown-item
        @click="$emit('edit', item)"
    >
      <b-row>
        <b-col cols="1"><i class="fas fa-edit" /></b-col>
        <b-col align="left"><span>Редактировать</span></b-col>
      </b-row>
    </b-dropdown-item>
    <b-dropdown-item
        @click="$emit('remove', item)"
        link-class="text-danger"
        v-if="!disableRemove"
    >
      <b-row>
        <b-col cols="1"><i class="fas fa-trash" /></b-col>
        <b-col align="left"><span>Удалить</span></b-col>
      </b-row>
    </b-dropdown-item>
  </a-dot-menu>
</template>

<script>
export default {
  name: "AActions",
  props: {
    item: {
      type: Object
    },
    disableRemove: {
      type: Boolean,
      default: false,
    }
  }
}
</script>