<template>
  <base-dropdown
      menu-on-right
      class="nav-item"
      tag="li"
      title-tag="a"
      menu-classes="dropdown-menu dropdown-actions dropdown-menu-right py-0 overflow-hidden"
  >
    <a
        class="nav-link text-white"
        slot="title"
        href="#"
        data-toggle="dropdown"
        @click.prevent
    >
      <i class="ni ni-ungroup"></i>
    </a>

    <div class="dropdown-item bg-default dropdown-actions">
      <div class="d-flex ml--2">
        <a :href="siteUrl" target="_blank" class="text-center shortcut-item">
                <span class="shortcut-media avatar rounded-lg mb-2 bg-gradient-purple">
                  <i class="ni ni-atom" />
                </span> <br>
          АНЭКС
        </a>

        <a :href="journalUrl" target="_blank" class="text-center shortcut-item">
                <span class="shortcut-media avatar rounded-lg mb-2 bg-gradient-info">
                  <i class="ni ni-single-copy-04" />
                </span> <br>
          Экстернат.РФ
        </a>
<!--        <router-link tag="a" to="/user/entry" class="col-4 text-center shortcut-item">-->
<!--                <span class="shortcut-media avatar rounded-circle mb-2 bg-gradient-green">-->
<!--                  <i class="ni ni-send" />-->
<!--                </span> <br>-->
<!--          Заявки-->
<!--        </router-link>-->

<!--        <router-link tag="a" to="/user/service" class="col-4 text-center shortcut-item">-->
<!--                <span class="shortcut-media avatar rounded-circle mb-2 bg-gradient-purple">-->
<!--                  <i class="ni ni-calendar-grid-58" />-->
<!--                </span> <br>-->
<!--          Услуги-->
<!--        </router-link>-->

<!--        <router-link tag="a" to="/user/service-package" class="col-4 text-center shortcut-item">-->
<!--                <span class="shortcut-media avatar rounded-circle mb-2 bg-gradient-info">-->
<!--                  <i class="ni ni-app" />-->
<!--                </span> <br>-->
<!--          Пакеты-->
<!--        </router-link>-->

        <router-link tag="a" to="/user/profile" class="text-center shortcut-item">
                <span class="shortcut-media avatar rounded-lg mb-2 bg-gradient-pink">
                  <i class="ni ni-single-02" />
                </span> <br>
          Профиль
        </router-link>

      </div>
    </div>

  </base-dropdown>
</template>

<script>
export default {
  name: "ActionsUser",
  computed: {
    siteUrl() {
      return process.env.VUE_APP_JOOMLA_SITE_URL;
    },
    journalUrl() {
      return process.env.VUE_APP_JOOMLA_JOURNAL_URL;
    }
  },
}
</script>

<style scoped>

</style>