<template>
  <b-row class="gx-5">
    <template v-if="!isLoading">
      <service-package-card
          v-for="item in packages"
          :item="item"
          :key="item.id"
          @on-update="fetchData"
          @on-update-pinned="$emit('on-update')"
          @on-click="clickHandler"
          @on-edit="edit"
      />
    </template>
    <template v-else>
      <service-package-skeleton
          v-for="item in [0, 1, 2, 3, 4, 5]"
          :key="`skeleton-${item}`"
      />
    </template>

    <service-package-entry-modal :item="selected" />

  </b-row>
</template>

<script>
import ServicePackageResource from "../../../resources/service-package";
import ServicePackageCard from "./ServicePackageCard";
import ServicePackageSkeleton from "./ServicePackageSkeleton";
import ServicePackageEntryModal from "./ServicePackageEntryModal";

export default {
  name: "ServicePackages",
  components: {
    ServicePackageCard,
    ServicePackageSkeleton,
    ServicePackageEntryModal
  },
  data() {
    return {
      packages: [],
      selected: {},
    }
  },
  props: {
    reload: {
      type: Number
    }
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    reload: {
      handler() {
        this.fetchData();
      }
    }
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      this.packages = await ServicePackageResource.fetch();
      this.isLoading = false;
    },
    clickHandler(item) {
      if (!this.isManager)
        this.enroll(item);
      else
        this.edit(item);
    },
    enroll(item) {
      this.selected = item;
      this.$bvModal.show('servicePackageEntryModal');
    },
    edit(item) {
      this.$emit('on-edit', item);
    }
  }
}
</script>

<style>

</style>