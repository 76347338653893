<template>
<div>
  <base-header type="gradient-indigo" class="pb-6 pt-5 pt-md-8" />
  <span class="mask bg-gradient-indigo" />
  <div class="container-fluid mt--7 w-75 pr-0">
    <div class="row">
      <div class="col">
        <div class="card shadow">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Рассылка для всех пользователей</h3>
          </div>

            <mailing-card />

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MailingCard from "./Mailing/MailingCard";

export default {
  name: "Mailing",
  components: {
    MailingCard,
  }
}
</script>

<style scoped>

</style>