<template>
  <b-modal
      id="servicePackageModal"
      hide-footer
      size="xl"
      :header-class="`mb--3 mt--2 bg-${colorTitle} text-white`"
      body-class="bg-white"
      header-close-variant="white mt--4"
      :title="item.title ? item.title : 'Пакет услуг'"
      ok-title="Сохранить"
      cancel-title="Отмена"
  >

    <b-row>
      <b-col cols="6">
        <b-form-group label="Выберите услуги...">

          <b-row class="mb-4">
            <l-select :options="servicesOptions" v-model="lastSelectedService" class="col-10" />
            <b-btn variant="primary" class="col-1" @click="addService"> + </b-btn>
          </b-row>

          <template v-if="selectedServices.length > 0">
            <b-row v-for="service in selectedServices" :key="service.id">
              <b-card
                  :title="service.type.fdv"
              >
                <b-row>
                  <h2><b-badge class="ml-2" variant="info">
                    {{ service.price }} ₽
                  </b-badge></h2>
                  <h2><b-badge class="ml-2" variant="warning">
                    {{ service.points }} баллов
                  </b-badge></h2>
                  <b-row>
                    <b-col cols="9" class="ml-2">
                      {{ service.title }}
                    </b-col>
                    <b-col cols="2">
                      <b-btn variant="danger" @click="removeService(service)"> x </b-btn>
                    </b-col>
                  </b-row>
                </b-row>

              </b-card>
            </b-row>
          </template>
        </b-form-group>
      </b-col>

      <b-col cols="6">

        <b-form-group>
          <a-label :label="'Название'" required />
          <b-form-input placeholder="Название" v-model="item.title" />
          <validation-error :errors="apiValidationErrors.title" />
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group>
              <a-label :label="'Баллы'" />
              <b-form-input placeholder="Баллы" type="number" v-model="item.points" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <a-label :label="'Цена, руб.'" required />
              <b-form-input placeholder="Цена" type="number" v-model="item.price" />
              <validation-error :errors="apiValidationErrors.price" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group>
          <a-label :label="'Профиль'" />
          <l-select placeholder="Профиль" v-model="item.dict_profile_id" dict="dict_profiles" />
        </b-form-group>

        <b-form-group>
          <a-label :label="'Описание'" required />
          <b-form-textarea placeholder="Описание" v-model="item.description" />
          <validation-error :errors="apiValidationErrors.description" />
        </b-form-group>

        <b-color-picker @on-update-color="setColor" :value="item.color" />

        <div class="text-right mt--4">
          <b-btn variant="primary" @click="save">Сохранить</b-btn>
        </div>

      </b-col>

    </b-row>
  </b-modal>
</template>

<script>
import ServiceResource from "../../../resources/service";
import ServicePackageResource from "../../../resources/service-package";

export default {
  name: "ServicePackageModal",
  props: {
    selected: {
      type: Object,
    }
  },
  data() {
    return {
      services: [],
      item: {
        title: '',
        price: null,
        description: '',
        color: '',
      },
      lastSelectedService: null,
      selectedServices: [],
    }
  },
  watch: {
    selected: {
      handler() {
        this.item = this.selected;
        this.selectedServices = this.selected.services;
      }
    },
    deep: true
  },
  async mounted() {
    this.services = await ServiceResource.fetch();
  },
  computed: {
    servicesOptions() {
      return this.services.map(elm => ({
        ...elm,
        value: elm,
        label: elm.title
      }))
    },
    colorTitle() {
      return this.item.color ?? 'purple';
    },
  },
  methods: {
    addService() {
      this.selectedServices.push(this.lastSelectedService);
    },
    removeService(item) {
      const elmIndex = this.selectedServices.indexOf(item);
      const spliceLength = 1;
      this.selectedServices.splice(elmIndex, spliceLength);
    },
    setColor(color) {
      this.item.color = color;
    },
    async save() {
      await this.validateAndSave(ServicePackageResource);
      this.$emit('on-update');
      if (this.areValidationErrorsEmpty())
        await this.savePackageServices();
    },
    async savePackageServices() {
      await ServicePackageResource.storeServices(
          this.selectedServices,
          this.item.id
      );
      this.$emit('on-update');
    },
  }
}
</script>

<style scoped>
.active {
  transform: scale(1.2);
}
</style>