import Resource from "../resource";
import axios from "axios";

export default class TemplateResource extends Resource {
    static async save(params, config) {
        let configuration;
        if (config === undefined) {
            configuration = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
        } else {
            configuration = config;
        }
        await super.save(params, configuration);
    }

    /**
     * Устанавливает шаблон по умолчанию для указанного подтипа услуг
     * @param templateId Id шаблона
     * @param alias Алиас типа/подтипа услуги
     * @returns {Promise<any>}
     */
    static async setAsDefaultForSubtype(templateId, alias) {
        const { data } = await axios.post(
            `${this.backendUrl}/${this.url}/${templateId}/set-as-default/${alias}`
        );
        return data;
    }
}

TemplateResource.url = 'template';