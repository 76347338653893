<template>
  <b-modal
      centered
      id="searchModal"
      hide-header
      hide-footer
      size="xl"
      ref="searchModal"
  >
    <div>
      <b-form-input autofocus placeholder="Поиск" v-model.lazy="searchText" />

      <div class="card-body" v-if="results.length > 0">
        <h2 v-if="results.length < 6">Найдено записей: {{ results.length }}</h2>
        <h2 v-else>Последние {{ results.length }} записей:</h2>

        <div class="row icon-examples">
          <div
              class="col-lg-6"
              v-for="(service) in results"
              :key="service.id"
          >
            <button
                @click="handle(service)"
                type="button"
                class="btn-icon-clipboard"
                data-clipboard-text="air-baloon"
            >
              <b-row>

                <i :class="service.date_type_icon" class="d-none d-md-block ml-2" />

                <b-col :cols="service.points ? 6 : 8">
                  <h4 class="ml-3"><b>{{service.type.fdv}}</b></h4>
                  <span class="text-wrap mb-2 font-weight-600" v-html="highlight(service.title)">
                    {{ service.title }}
                  </span>
                  <div v-if="service.begda && service.endda">
                    <span class="text-center mt-1">
                      {{ service.interval_formatted }}
                    </span><br>
                  </div>
                  <!-- Раньше баллы и цена были здесь -->
                </b-col>
                <b-col
                    :cols="service.points ? 5 : 2"
                    :class="service.points ? '' : 'ml-4'"
                >
                  <b-badge variant="primary" v-if="service.points">
                    {{ service.points }} баллов
                  </b-badge>
                  <b-badge variant="warning">
                    {{ service.price }} ₽
                  </b-badge>
                </b-col>
              </b-row>
            </button>
          </div>
        </div>

        <service-entry :service="selectedService" />
        <service-forms-modal
            :item="selectedService"
            :type="selectedService.type"
            :subtype="selectedService.subtype"
        />

      </div>
      <div v-else class="p-4">
        Ничего не найдено :(
      </div>
    </div>
  </b-modal>
</template>

<script>
import ServiceResource from "../../resources/service";
import ServiceEntry from "../../app/user/Service/ServiceEntry";
import ServiceFormsModal from "../../app/manager/Service/ServiceFormsModal";

export default {
  name: "SearchModal",
  components: {
    ServiceEntry,
    ServiceFormsModal,
  },
  data() {
    return {
      searchText: null,
      results: [],
      selectedService: {
        sections: [],
      },
    }
  },
  props: {
    keywords: {
      type: String,
    }
  },
  watch: {
    keywords: {
      handler(value) {
        this.searchText = value;
      }
    },
    searchText: {
      async handler(value) {
        if (value.length > 0) {
          await this.search();
        } else {
          this.$bvModal.hide('searchModal');
          this.$emit('clear-keywords');
        }
      }
    },
  },
  methods: {
    async search() {
      this.results = await ServiceResource.search({
        keywords: this.searchText
      });
    },
    handle(service) {
      // this.$refs.searchModal.hide();
      this.selectedService = service;
      if (this.isManager) {
        this.$bvModal.show('serviceFormsModal');
      } else {
        this.$bvModal.show('serviceEntryModal');
      }
    },
    highlight(text) {
      const regexp = new RegExp(this.searchText, 'gi');
      const replaceWith = '<span class="search-match p-0 m-0 mb-1">$&</span>';
      return text.replace(regexp, replaceWith);
    }
  }
}
</script>

<style>
.search-match {
  background: var(--yellow) !important;
}
</style>
