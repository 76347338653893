<template>
  <b-modal
      hide-footer
      id="serviceFormsModal"
      size="xl"
      title="Услуга"
      @close="resetClear"
      @hide="resetClear"
  >
    <b-row>
      <b-col class="bg-secondary">
        <h6 class="heading-small text-muted mb-4">
          Профили
        </h6>

        <div class="form-group">
          <b-form-checkbox-group v-model="checkedProfiles" class="row pt-2" buttons>
            <b-form-checkbox
                class="col-5 p-1 mb-3 ml-3 checkbox-profile rounded-lg bg-white"
                v-for="profile in profiles"
                :value="profile.id"
                :key="profile.id"
            >
              {{ profile.fdv }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
        <hr class="my-4" />

        <!--    СЕКЦИИ    -->
        <section-card
            v-if="item"
            :item="item"
            @update-sections="updateSections"
            @update-last-section="updateLastSection"
        />

        <hr />
        <h6 class="heading-small text-muted mb-4 mt-3">
          Ведущие
        </h6>
        <div class="form-group">
          <l-select
              :options="leadings"
              v-model="selectedLeadings"
              placeholder-text="Выберите ведущих..."
              multiple
          />
        </div>

        <hr />

        <div class="bg-gradient-pink px-4 py-1 rounded">
          <h6 class="heading-small text-white mb-4 mt-3">
            Шаблоны
          </h6>
          <div class="form-group">
            <a-label :label="'Шаблон сертификата'" color="white" label-class="mb-1" />
            <l-select :options="templatesCertificate" v-model="item.template_certificate_id" />
            <validation-error :errors="apiValidationErrors.dict_type_id" />
            <a href="#" class="dotted text-xs border-primary ml-1" v-if="hasDefaultCertificate" @click="setDefaultCertificate">
              Установить шаблон по умолчанию
            </a>
          </div>
          <div class="form-group">
            <a-label :label="'Шаблон программы'" color="white" label-class="mb-1" />
            <l-select :options="templatesProgram" v-model="item.template_program_id" />
            <validation-error :errors="apiValidationErrors.dict_type_id" />
            <a href="#" class="dotted text-xs border-primary ml-1" v-if="hasDefaultProgram" @click="setDefaultProgram">
              Установить шаблон по умолчанию
            </a>
          </div>
          <div class="form-group">
            <a-label :label="'Шаблон рег. листа'" color="white" label-class="mb-1" />
            <l-select :options="templatesRegList" v-model="item.template_participation_id" />
            <validation-error :errors="apiValidationErrors.dict_type_id" />
            <a href="#" class="dotted text-xs border-primary ml-1" v-if="hasDefaultParticipation" @click="setDefaultRegistrationList">
              Установить шаблон по умолчанию
            </a>
          </div>
        </div>


      </b-col>
      <b-col cols="7" class="border-left pl-4">

        <h6 class="heading-small text-muted mb-4">
          Категория
        </h6>
        <div class="form-group">
          <a-label :label="'Тип услуги'" required />
          <l-select dict="dict_service_types" v-model="item.dict_type_id" />
          <validation-error :errors="apiValidationErrors.dict_type_id" />
        </div>
        <div class="form-group" v-if="dictSubtypes.length > 0">
          <a-label :label="'Подтип услуги'" required />
          <l-select :options="dictSubtypes" v-model="item.dict_subtype_id" />
        </div>
        <hr class="my-4" />

        <h6 class="heading-small text-muted mb-4">
          Основная информация
        </h6>
        <b-form-group>
          <a-label :label="'Название'" required />
          <b-form-input v-model="item.title" placeholder="Название" :maxlength="100" />
          <div class="small mt-2"> Внимание! Максимальная длина названия: 100 символов. </div>
          <validation-error :errors="apiValidationErrors.title" />
        </b-form-group>
        <b-row>
          <b-col cols="8">
            <b-form-group>
              <a-label :label="'Цена'" />
              <b-form-input v-model="item.price" placeholder="Цена, руб." />
            </b-form-group>
          </b-col>
          <b-col class="ml-3">
            <b-form-group label="Видимость услуги" label-class="form-control-label">
              <b-form-radio-group
                  id="btn-radios-2"
                  button-variant="outline-dark"
                  size="lg"
                  name="radio-btn-outline"
                  v-model="item.is_visible"
                  buttons
              >
                <b-form-radio class="pr-4" :value="1">
                  <b-icon-eye-fill />
                </b-form-radio>
                <b-form-radio class="pr-4" :value="0">
                  <b-icon-eye-slash />
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="my-4" />

        <h6 class="heading-small text-muted mb-4">
          Описание
        </h6>
        <b-form-group>
          <ckeditor
              :editor="ckEditor"
              v-model="item.description"
              @ready="onReady"
              :config="editorConfig"
          />
        </b-form-group>
        <hr class="my-4" />

        <h6 class="heading-small text-muted mb-4">
          Даты
        </h6>
        <b-form-group>
          <a-label :label="'Тип даты'" required />
          <validation-error :errors="apiValidationErrors.date_type" />
          <b-form-radio-group
              id="btn-radios-2"
              button-variant="outline-primary"
              size="lg"
              name="radio-btn-outline"
              v-model="item.date_type"
              buttons
          >
            <b-form-radio
                :value="dateType.value"
                v-for="dateType in dateTypes"
                :key="dateType.value"
            >
              <i :class="`${dateType.icon} mr-2`" />
              {{ dateType.label }}
            </b-form-radio>
          </b-form-radio-group>

          <b-row v-if="dateTypeCurrent" class="p-4">
            <b-col>
              <b-form-group label="Дата начала" label-class="form-control-label" class="mt-3">
                <v-date-picker
                    mode="dateTime"
                    v-model="item.begda"
                    :model-config="modelConfig"
                    color="purple"
                    :minute-increment="30"
                    :valid-hours="{ min: 8, max: 21 }"
                    is24hr
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Дата конца" label-class="form-control-label" class="mt-3">
                <v-date-picker
                    mode="dateTime"
                    v-model="item.endda"
                    :model-config="modelConfig"
                    color="purple"
                    :minute-increment="30"
                    :valid-hours="{ min: 8, max: 21 }"
                    is24hr
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form-group>
        <hr class="my-4" />

        <h6 class="heading-small text-muted mb-4">
          Участие
        </h6>
        <b-form-group>
          <a-label :label="'Тип участия'" required />
          <validation-error :errors="apiValidationErrors.participation_type" />
          <b-form-radio-group
              id="btn-radios-2"
              button-variant="outline-default"
              size="lg"
              name="radio-btn-outline"
              v-model="item.participation_type"
              buttons
          >
            <b-form-radio
                class="pr-4"
                :value="participationType.value"
                v-for="participationType in participationTypes"
                :key="participationType.value"
            >
              <component :is="participationType.icon" class="mr-2" />
              {{ participationType.label }}
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group
            label="Количество участников"
            label-class="form-control-label"
        >
          <b-alert variant="primary" show class="col-10 p-2 opacity-8">
            Количество участников в рег. листах и программах.
            Если равно нулю, то берётся значение по умолчанию (<b>27</b>).
          </b-alert>
          <b-form-input class="col-10" v-model="item.max_participants" placeholder="Введите значение" />
        </b-form-group>

      </b-col>
    </b-row>
    <hr class="my-4" />

    <joomla-card :templates="templatesPage" :item="item" :errors="apiValidationErrors" />
    <hr class="my-4" />

    <div class="text-right">
      <b-button v-if="!isLoading" variant="primary" @click="save" pill>
        Сохранить
      </b-button>
      <b-button v-else disabled variant="primary" pill>
        Сохранение...
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import ServiceResource from "../../../resources/service";
import SectionCard from "./ServiceFormsModal/SectionCard";
import JoomlaCard from "./ServiceFormsModal/JoomlaCard";
import TemplateResource from "../../../resources/template";
import LeadingResource from "../../../resources/leading";

export default {
  name: "ServiceFormsModal",
  components: {
    SectionCard,
    JoomlaCard
  },
  data() {
    return {
      templates: [],
      leadings: [],
      // TODO Вынести в mixin с константами!!
      templateTypes: {
        certificate: 1,
        program: 2,
        regList: 3,
        page: 4,
        notification: 5,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD HH:mm:ss',
      },
      dateTypes: [
        { value: 1, label: 'Определённые даты', icon: 'ni ni-calendar-grid-58' },
        { value: 2, label: 'Перманентный', icon: 'ni ni-tag' },
        { value: 3, label: 'Открытые даты', icon: 'ni ni-collection' },
      ],
      participationTypes: [
        { value: 2, label: 'Очное', icon: 'b-icon-eye-fill' },
        { value: 1, label: 'Очное и заочное', icon: 'b-icon-eye' },
        { value: 3, label: 'Заочное', icon: 'b-icon-display' },
      ],
      begdaFormatted: '',
      enddaFormatted: '',
      checkedProfiles: [],
      selectedLeadings: [],
      isCleared: false,
      sectionsBeforeSave: [],
      lastSection: '',
    }
  },
  props: {
    isNew: {
      type: Boolean,
    },
    item: {
      type: Object,
    },
    type: {
      type: Object,
    },
    subtype: {
      type: Object,
    },
  },
  watch: {
    item: {
      handler() {
        this.setDefaultTemplates();
        this.clearItem();
        this.resetRelations();
      },
    },
    'item.begda': {
      handler(val) {
        if (!this.item.id)
          this.item.endda = val;
      }
    },
    'item.image': {
      handler(value) {
        console.log('change image', value);
      }
    }
  },
  async mounted() {
    await this.fetchData();
  },
  computed: {
    hasDefaultTemplate() {
      return !!this.subtype.template_certificate_id ||
          !!this.type.template_certificate_id ||
          !!this.subtype.template_program_id ||
          !!this.subtype.template_participation_id ||
          !!this.subtype.joomla_article_template_id ||
          !!this.type.joomla_article_template_id;
    },
    hasDefaultCertificate() {
      return !!this.subtype.template_certificate_id ||
          !!this.type.template_certificate_id;
    },
    hasDefaultProgram() {
      return !!this.subtype.template_program_id;
    },
    hasDefaultParticipation() {
      return !!this.subtype.template_participation_id;
    },
    templatesCertificate() {
      return this.templates.filter(
          elm => elm.dict_type_id === this.templateTypes.certificate
      );
    },
    templatesProgram() {
      return this.templates.filter(
          elm => elm.dict_type_id === this.templateTypes.program
      );
    },
    templatesRegList() {
      return this.templates.filter(
          elm => elm.dict_type_id === this.templateTypes.regList
      );
    },
    templatesPage() {
      return this.templates.filter(
          elm => elm.dict_type_id === this.templateTypes.page
      );
    },
    profiles() {
      return this.$store.getters.getDict('dict_profiles');
    },
    title() {
      return this.isNew ? 'Создание' : 'Редактирование';
    },
    dictSubtypes() {
      const typeId = this.item.dict_type_id;
      let subtypes = this.$store.getters.getDict('dict_service_subtypes');
      subtypes = subtypes.filter(
          elm => elm.dict_service_type_id === typeId
      );
      subtypes = subtypes.map(elm => ({
        ...elm,
        label: elm.fdv,
        value: elm.id,
      }));
      return subtypes;
    },
    dateTypeCurrent() {
      const dateTypeCurrent = 1;
      return this.item.date_type === dateTypeCurrent;
    }
  },
  methods: {
    async fetchData() {
      await this.fetchTemplates();
      await this.fetchLeadings();
    },

    /**
     * Сбрасывает связанные сущности
     * в первоначальное значение
     */
    resetRelations() {
      const issetProfiles = this.item.profiles !== undefined;
      const issetLeadings = this.item.leadings !== undefined;
      const isNew = this.item.id === undefined;
      if ((issetProfiles || issetLeadings) && isNew)
        this.item.sections = this.sectionsBeforeSave;
      if (issetProfiles) {
        this.checkedProfiles = this.item.profiles.map(elm => elm.id);
      }
      if (issetLeadings) {
        this.selectedLeadings = this.item.leadings.map(elm => elm.id);
      }
    },

    /**
     * Устанавливает шаблоны по умолчанию,
     * если они не были выбраны для услуги
     */
    setDefaultTemplates() {
      if (!this.item.id) {
        this.setDefaultCertificate();
        this.setDefaultPage();
        this.setDefaultProgram();
        this.setDefaultRegistrationList();
      }
    },

    setDefaultCertificate() {
      if (!this.item.template_certificate_id) {
        const templateCertificate = this.templates.filter(
            elm => elm.id === this.subtype.template_certificate_id ||
                elm.id === this.type.template_certificate_id
        )[0];
        if (templateCertificate)
          this.item.template_certificate_id = templateCertificate.id;
      }
    },

    setDefaultPage() {
      if (!this.item.joomla_article_template_id) {
        const templatePage = this.templates.filter(
            elm => elm.id === this.subtype.template_page_id ||
                elm.id === this.type.template_page_id
        )[0];
        if (templatePage)
          this.item.joomla_article_template_id = templatePage.id;
      }
    },

    setDefaultProgram() {
      if (!this.item.template_program_id) {
        const templateProgram = this.templates.filter(
            elm => elm.dict_type_id === this.templateTypes.program
        )[0];
        this.item.template_program_id = templateProgram.id;
      }
    },

    setDefaultRegistrationList() {
      if (!this.item.template_participation_id) {
        const templateRegList = this.templates.filter(
            elm => elm.dict_type_id === this.templateTypes.regList
        )[0];
        this.item.template_participation_id = templateRegList.id;
      }
    },

    resetClear() {
      this.isCleared = false;
    },

    clearItem() {
      if (this.item.id === undefined && !this.isCleared) {
        this.checkedProfiles = [];
        this.item.sections = [];
        this.selectedLeadings = [];
        this.isCleared = true;
      }
    },

    /**
     * Фетч шаблонов
     * @returns {Promise<void>}
     */
    async fetchTemplates() {
      const templates = await TemplateResource.fetch();
      this.templates = templates.map(item => ({
        ...item,
        value: item.id,
        label: item.title,
      }))
    },

    /**
     * Фетч ведущих
     * @returns {Promise<void>}
     */
    async fetchLeadings() {
      const leadings = await LeadingResource.fetch();
      this.leadings = leadings.map((item) => ({
        ...item,
        value: item.id,
        label: item.fio
      }));
    },

    /**
     * Если отсутствтуют ошибки валидации, то
     * сохранение услуги в БД, затем остальных
     * сущностей (профилей, секций и ведущих).
     * @returns {Promise<void>}
     */
    async save() {
      this.isLoading = true;
      this.item.sections = this.item.sections.map((item) => ({
        id: item.id,
        title: item.title,
        service_id: item.service_id
      }));
      this.sectionsBeforeSave = [ ...this.item.sections ];
      this.addLastSectionIfNeeded();
      this.convertProfiles();
      this.convertLeadings();

      const image = this.item.image;
      try {
        await this.validateAndSave(
            ServiceResource,
            this.item,
            true
        );
        this.item.image = image;
        await this.afterSave();
        this.isCleared = false;
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },

    /**
     * Действия после сохранения услуги
     * @returns {Promise<void>}
     */
    async afterSave() {
      if (this.areValidationErrorsEmpty()) {
        this.$bvModal.hide('serviceFormsModal');
      }
      if (this.item.id) {
        if (this.item.image)
          await this.uploadImage();
        await this.saveToJoomla();
      } else {
        console.log('Отсутствует id!');
      }
      this.$emit('on-update');
    },

    /**
     * Загрузка изображения
     * @returns {Promise<void>}
     */
    async uploadImage() {
      if (this.item.image !== undefined && !this.item.image.id) {
        let formData = new FormData();
        formData.append('image', this.item.image);
        await ServiceResource.uploadImage(this.item.id, formData);
      }
    },

    /**
     * Сохранение услуги в Joomla
     * @returns {Promise<void>}
     */
    async saveToJoomla() {
      try {
        await ServiceResource.saveToJoomla(this.item.id);
      } catch (e) {
        const error = e.response.data.message;
        const message = `Ошибка сохранения в Joomla! ${error}...`;
        this.$notify({
          message: message,
          type: 'danger',
        });
      }
    },

    /**
     * Сохраняет последнюю введённую, но
     * не сохранённую секцию
     */
    addLastSectionIfNeeded() {
      if (this.lastSection.length > 0) {
        this.item.sections.push({
          title: this.lastSection,
        });
        this.lastSection = '';
      }
    },

    /**
     * Конвертация профилей из массива
     * айдишников в массив объектов
     */
    convertProfiles() {
      this.item.profiles = this.profiles.filter(elm =>
        this.checkedProfiles.indexOf(elm.id) !== -1
      );
    },

    /**
     * Конвертация ведущих из массива
     * айдишников в массив объектов
     */
    convertLeadings() {
      this.item.leadings = this.leadings.filter(elm =>
          this.selectedLeadings.indexOf(elm.id) !== -1
      );
    },

    onContextBegda(ctx) {
      this.begdaFormatted = ctx.selectedFormatted
    },

    onContextEndda(ctx) {
      this.enddaFormatted = ctx.selectedFormatted
    },

    updateSections(sections) {
      this.item.sections = sections;
    },

    /**
     * Обновляет последнюю секцию и, если она не пустая
     * при сохранении услуги, то добавляет и её к секциям
     * @param section
     */
    updateLastSection(section) {
      this.lastSection = section;
    },
  }
}
</script>

<style scoped>
.checkbox-profile.active {
  background: var(--purple) !important;
  color: var(--white) !important;
}
</style>
