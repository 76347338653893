import PublicationResource from "../resources/publication";

export default {
	methods: {
		async save() {
			let formData = new FormData();
			// TODO Сохранение файлов при редактировании
			if (this.item.additionalFiles !== undefined && !this.item.id)
				this.item.additionalFiles.forEach(file => {
					formData.append('additionalFiles[]', file);
				})
			if (this.item.file !== undefined)
				formData.append('file', this.item.file);
			Object.keys(this.item).forEach(key => {
				if (this.item[key] !== null)
					formData.append(key, this.item[key]);
			});
			await this.validateAndSave(PublicationResource, formData, false);
			if (this.areValidationErrorsEmpty()) {
				this.$emit('on-update');
				this.$emit('reset');
			}
			await this.$store.dispatch('me');
		},
	}
};
