<template>
  <b-nav-item-dropdown class="dots-actions" right>
    <template #button-content>
      <button class="btn btn-link text-dark mb-0">
        <i class="fa fa-ellipsis-v text-xs" aria-hidden="true" />
      </button>
    </template>
    <slot />
  </b-nav-item-dropdown>
</template>

<script>
export default {
  name: "ADotMenu",
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style>
.dots-actions .dropdown-toggle::after {
  content: none !important;
}
.dots-actions .dropdown-toggle {
  padding: 0px !important;
}

</style>