<template>
  <div>
    <base-header type="gradient-indigo" class="pb-6 pt-5 pt-md-8" />
    <span class="mask bg-gradient-indigo" />
    <div class="container-fluid mt--7">

      <b-row>
        <b-col sm="12" xl="9">
          <b-card class="shadow">
            <div class="card-body">
              <div class="row icon-examples">
                <div
                    class="mr-3"
                    v-for="(type, index) in dictTemplateTypes"
                    :key="type.id + index"
                >
                  <button
                      @click="selectType(type)"
                      :class="{'bg-default text-white': type.id === selectedType.id}"
                      type="button"
                      :title="type.fdv"
                      class="btn-icon-clipboard"
                      data-clipboard-text="air-baloon"
                  >
                    <div>
                      <i :class="iconType(type)" />
                      <span> {{ type.fdv }} </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>

            <div v-if="selectedType.id">
              <h2 class="ml-4">
                {{ selectedType.fdv }}
                <b-btn
                    pill
                    variant="default"
                    class="ml-4 mt-2 mb-3"
                    @click="addItem"
                >
                  + Добавить шаблон
                </b-btn>
              </h2>

              <div class="row icon-examples ml-1" v-if="!isLoading">
                <div
                    class="col-4"
                    v-for="(template, index) in templates" :key="template.title + index"
                >

                  <!--        ЗНАЧКИ ИСПОЛЬЗОВАНИЯ ПО УМОЛЧАНИЮ          -->
                  <b-badge
                      v-if="template.event"
                      class="float-right ml--4 position-relative text-white"
                      :class="`bg-${template.event.color}`"
                      v-b-tooltip.left="'Используется для события'"
                  >
                    <i :class="template.event.icon" />
                  </b-badge>
                  <b-badge
                      v-if="template.serviceTypeCertificateFor"
                      class="float-right ml--4 position-relative text-white"
                      :class="`bg-${template.serviceTypeCertificateFor.color}`"
                      v-b-tooltip.left="template.serviceTypeCertificateFor.fdv"
                  >
                    <i :class="template.serviceTypeCertificateFor.icon" />
                  </b-badge>
                  <b-badge
                      v-if="template.serviceTypePageFor"
                      class="float-right ml--4 position-relative text-white"
                      :class="`bg-${template.serviceTypePageFor.color}`"
                      v-b-tooltip.left="template.serviceTypePageFor.fdv"
                  >
                    <i :class="template.serviceTypePageFor.icon" />
                  </b-badge>

                  <!--        ШАБЛОН         -->
                  <button type="button"
                          @click="editItem(template)"
                          class="btn-icon-clipboard"
                          data-clipboard-text="air-baloon"
                  >
                    <a @click.stop="remove(template)" title="Удалить">
                      <b-icon-x font-scale="1.5" class="float-right" />
                    </a>
                    <div>
                      <i :class="selectedType.icon" />
                      <span class="text-wrap">{{ template.title }}</span>
                    </div>
                  </button>

                </div>
              </div>

              <template v-else>
                <service-loader />
              </template>

            </div>

          </b-card>
        </b-col>

        <b-col xl="3" cols="12">
          <b-row>
            <b-col xl="12" md="6" cols="12">
              <b-card
                  @click="openSettingsDefaultCertificate"
                  class="pl-0 text-center opacity-7 bg-transparent cursor-pointer"
              >
                <b-card-header
                    header-bg-variant="white"
                    header-text-variant="gradient"
                >
                  Сертификаты по умолчанию
                </b-card-header>
                <b-card-body class="bg-white pb-5">
                  <b-icon-gear font-scale="1.2" class="mr-2" />
                  <b-badge variant="primary">по типам услуг</b-badge>
                </b-card-body>
              </b-card>
            </b-col>

            <b-col xl="12" md="6" cols="12">
              <b-card
                  @click="openSettingsDefaultPage"
                  class="pl-0 text-center opacity-7 bg-transparent cursor-pointer"
              >
                <b-card-header
                    header-bg-variant="white"
                    header-text-variant="gradient"
                >
                  Шаблоны страниц по умолчанию
                </b-card-header>
                <b-card-body class="bg-white pb-5">
                  <b-icon-gear font-scale="1.2" class="mr-2" />
                  <b-badge variant="primary">по типам услуг</b-badge>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <template-modal :item="item" @on-update="fetchData" />
      <default-certificate-modal />
      <default-page-modal />

    </div>
  </div>
</template>

<script>
import TemplateModal from "./Template/TemplateModal";
import DefaultCertificateModal from "./Template/DefaultCertificateModal";
import DefaultPageModal from "./Template/DefaultPageModal";
import ServiceLoader from "../../user/Service/ServiceLoader";
import TemplateResource from "../../../resources/template";

export default {
  name: "Template",
  components: {
    DefaultCertificateModal,
    DefaultPageModal,
    TemplateModal,
    ServiceLoader
  },
  data() {
    return {
      selectedType: {},
      item: {},
      templates: [],
    }
  },
  computed: {
    dictTemplateTypes() {
      return this.$store.getters.getDict('dict_template_types');
    },
  },
  methods: {
    async selectType(type) {
      this.selectedType = type;
      await this.fetchData(type.id);
    },
    iconType(type) {
      const active = this.selectedType === type ? 'text-white' : '';
      return `${type.icon} text-default ${active}`;
    },
    editItem(item) {
      this.item = item;
      this.$bvModal.show('templateModal');
    },
    addItem() {
      this.item = {
        dict_type_id: this.selectedType.id,
      };
      this.$bvModal.show('templateModal');
    },
    openSettingsDefaultCertificate() {
      this.$bvModal.show('modalDefaultCertificate');
    },
    openSettingsDefaultPage() {
      this.$bvModal.show('modalDefaultPage');
    },
    async fetchData(typeId) {
      this.isLoading = true;
      this.$bvModal.hide('templateModal');
      this.templates = await TemplateResource.fetch({
        dict_type_id: typeId
      });
      this.isLoading = false;
    },
    async remove(item) {
      await this.deleteItem(TemplateResource, item.id);
      await this.fetchData(this.selectedType.id);
    },
  },
}
</script>

<style scoped>

</style>