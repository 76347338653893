import NotificationResource from "../resources/notification";

// TODO Всё делать через $store, а не через mixin??
export default {
    data() {
        return {
            oldNotifications: [],
            newNotifications: [],
            wasSoundPlayed: false,
        }
    },
    methods: {
        /**
         * Проверяет новые уведомления
         * @returns {Promise<void>}
         */
        async checkNotifications(showNotifications = false) {
            if (!this.$store.getters.user.id)
                await this.$store.dispatch('me');
            this.newNotifications = await NotificationResource.checkNew(
                this.$store.getters.user.id
            );
            // this.$store.commit('SET_NEW_NOTIFICATIONS', this.newNotifications);
            if (this.newNotifications.length > 0 && showNotifications) {
                this.newNotifications.forEach(elm => {
                    if (!this.oldNotifications.some(
                        item => item.text === elm.text
                    )) {
                        this.oldNotifications.push(elm);
                        this.showNotification(elm);
                        this.wasSoundPlayed = true;
                    }
                })
                this.wasSoundPlayed = false;
            }
        },
        /**
         * Выводит уведомление для пользователя
         * @param elm
         */
        showNotification(elm) {
            if (!this.wasSoundPlayed) {
                this.playSound();
            }
            this.$notify({
                type: "dark",
                message: elm.text,
                icon: elm.icon,
                timeout: 2000,
            });
        },
        /**
         * Проигрывает звук уведомлений
         */
        playSound() {
            const sound = '/sound/knock.mp3';
            const audio = new Audio(sound);
            audio.play();
        }
    }
};
