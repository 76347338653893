<template>
  <div>
    <base-header type="default" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <widgets-manager />
    </base-header>

    <!--Charts-->
    <div class="container-fluid mt--7">
      <div class="row">

        <statistics-finance />
        <statistics-entry />

      </div>

      <!-- End charts-->

      <!--Tables-->
<!--      <div class="row mt-5">-->
<!--        <div class="col-xl-8 mb-5 mb-xl-0">-->
<!--          <page-visits-table></page-visits-table>-->
<!--        </div>-->
<!--        <div class="col-xl-4">-->
<!--          <social-traffic-table></social-traffic-table>-->
<!--        </div>-->
<!--      </div>-->
      <!--End tables-->
    </div>
  </div>
</template>
<script>
import WidgetsManager from "../../../components/WidgetsManager";
import StatisticsFinance from "./Modules/StatisticsFinance";
import StatisticsEntry from "./Modules/StatisticsEntry";

// Tables
import SocialTrafficTable from "./SocialTrafficTable";
import PageVisitsTable from "./PageVisitsTable";

export default {
  components: {
    WidgetsManager,
    StatisticsFinance,
    StatisticsEntry,
    // PageVisitsTable,
    // SocialTrafficTable,
  },
};
</script>
<style>
</style>
