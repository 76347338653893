<template>
  <b-modal
      size="lg"
      title="Выпуск"
      title-class="text-lg p-2 text-center"
      body-class="mt--4 mb--5"
      id="itemFormsModal"
      centered
      cancel-title="Закрыть"
      ok-title="Сохранить"
      @ok="saveItem"
  >
    <a-label label="Тип публикации" />
    <l-select
        class="text-wrap mb-3"
        :options="publicationTypes"
        v-model="item.dict_service_subtype_id"
    />

    <a-label label="Название" />
    <b-form-group>
      <b-form-input v-model="item.title" placeholder="Название" />
    </b-form-group>
  </b-modal>
</template>

<script>

import PublicationReleaseResource from "../../../../resources/publication-release";

export default {
  name: "ItemFormsModal",
  data() {
    return {
      publicationTypes: [],
    }
  },
  props: {
    item: {
      type: Object,
    },
  },
  async mounted() {
    const types = this.$store.getters.getDict('dict_service_subtypes');
    this.publicationTypes = types.filter(elm => elm.is_publication);
    this.publicationTypes = this.publicationTypes.map(item => ({
      ...item,
      value: item.id,
      label: item.fdv,
    }))
  },
  methods: {
    async saveItem() {
      await this.validateAndSave(PublicationReleaseResource, this.item);
      this.$emit('on-update');
    },
  },
}
</script>

<style scoped>

</style>