<template>
  <footer class="footer px-3">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-12">
        <div class="copyright text-center">
          {{ year }} АНЭКС
          <br> <a href="https://aneks.center/o-tsentre/rekvizity" target="_blank" class="text-no-decoration dotted text-white border-white"> 
            Наши реквизиты 
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "ContentFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
