import Resource from "../resource";
import axios from "axios";

export default class PublicationReleaseResource extends Resource {
	/**
	 * Публикация выпуска
	 * @returns {Promise<void>}
	 */
	static async publish(id) {
		const url = `${this.backendUrl}/${this.url}/${id}/publish`;
		const { data } = await axios.post(url, {});
		return data;
	}
}

PublicationReleaseResource.url = 'publication-release';