import EntryResource from "../resources/entry";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
      /**
       * Вызов удаление из ресурса, вызов всплывающего уведомления
       * и заполнение массива ошибок
       * @param resourceModel Модель ресурса,
       * у которой будет использован метод destroy
       * для передачи на бэк
       * itemId id объекта, который будет удален
       * @returns {Promise<void>}
       */
      async deleteItem(resourceModel, itemId) {
          this.isLoading = true;
          const value = await this.$bvModal.msgBoxConfirm(
              'Вы уверены, что хотите удалить?', {
                  title: 'Удаление',
                  size: 'sm',
                  buttonSize: 'sm',
                  okVariant: 'danger',
                  okTitle: 'ДА',
                  cancelTitle: 'НЕТ',
                  hideHeaderClose: false,
                  centered: true,
              })
          if (value) {
            try {
              await resourceModel.delete(itemId);
              this.notifyAboutDelete();
            } catch (error) {
              this.showErrorMessage(error);
            }
          }
      },

      /**
       * Вывод ошибок
       * @param error
       */
      showErrorMessage(error) {
        if (error.response) {
          this.$notify({
            message: error.response.data.message,
            type: 'danger',
          });
        } else {
          this.$notify({
            message: 'Ошибка сервера',
            type: 'danger',
          });
        }
      },

      /**
       * Уведомляет пользователя об успешном удалении
       */
      notifyAboutDelete() {
          this.$notify({
              type: "success",
              message: "Успешно удалено",
          });
      },
  }
};
