<template>
  <b-card class="mt-3 shadow card-file" v-if="file">
    <b-row class="mt--2">
      <b-col cols="1">
        <img v-if="isImage" width="80" class="rounded" :src="file.url" />
        <b-icon-file-earmark v-else class="h1 mt-3" :class="`ml-${iconMarginLeft}`" />
      </b-col>
      <b-col :class="textClass">
        <div class="d-flex">
          <span class="mb-0">
            <a
                v-if="file.id"
                :href="`${file.url}?is_inline=true`"
                target="_blank"
                class="font-weight-300"
                :class="`text-${textSize}`"
            >
              {{ isShortText ? `${fileName.substr(0, 25)}...` : fileName}}
            </a>
            <div
                v-else
                class="font-weight-300 pointer-none"
                :class="`text-${textSize}`"
            >
              {{ isShortText ? `${fileName.substr(0, 25)}...` : fileName}}
            </div>
          </span>
          <div class="ml-auto" v-if="!hideDeleteBtn">
            <b-button
                title="Удалить файл"
                variant="link"
                class="p-0"
                @click="removeFile">
              <b-icon-x scale="1.5" />
            </b-button>
          </div>
        </div>
        <p class="small mb-0">
          {{ file.date_formatted ? file.date_formatted : now }}
          <b-badge :variant="fileExtVariant" class="ml-2">
            {{ fileExt }}
          </b-badge>
        </p>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import FileResource from "../resources/file";

export default {
  name: "AFile",
  data() {
    return {
      images: [
          'jpg',
          'jpeg',
          'png',
          'webp'
      ]
    }
  },
  props: {
    file: {},
    isShortText: {
      type: Boolean,
      default: false,
    },
    hideDeleteBtn: {
      type: Boolean,
      default: false,
    },
    textSize: {
      type: String,
      default: 'lg',
    },
    textClass: {
      type: String,
      default: '',
    },
    iconMarginLeft: {
      type: String,
      default: '3',
    },
  },
  computed: {
    now() {
      return this.$moment().format('YYYY-MM-DD HH:mm:ss');
    },
    fileName() {
      const split = this.file.name.split('.');
      return split[0];
    },
    fileExt() {
      const split = this.file.name.split('.');
      return split[split.length - 1];
    },
    fileExtVariant() {
      switch (this.fileExt) {
        case 'xlsx':
          return 'success';
        case 'pdf':
          return 'danger';
        case 'pptx':
          return 'warning';
        case 'png':
        case 'jpg':
          return 'info';
        default:
          return 'primary';
      }
    },
    isImage() {
      return this.images.includes(this.fileExt);
    },
  },
  methods: {
    async removeFile() {
      if (this.file.id) {
        await FileResource.delete(this.file.id);
        this.$emit('update-list');
      }
      this.$emit('on-remove', this.file);
    },
  },
}
</script>

<style scoped>
.pointer-none {
  pointer-events: none;
  cursor: default;
}
</style>