<template>
  <b-modal
      size="xl"
      id="publicationViewModal"
      centered
      ref="publicationViewModal"
      modal-class="xxl"
      hide-footer
  >
    <b-row class="ml-2 mr-2" v-if="!isFormLoading">
      <b-col cols="4" class="border-right pr-5 mr-4 position-sticky" v-if="isManager || issetManagerComment">
        <template v-if="isManager">
          <h2>Действия с публикацией
            <b-button v-b-tooltip="'Отклонить без уведомления'" variant="link" size="sm" class="px-1 text-danger" @click="rejectSilently">
              <b-icon-trash-fill />
            </b-button>
            <b-button v-b-tooltip="'Отклонить публикацию'" variant="danger" size="sm" class="px-2 ml-1" @click="reject">
              x
            </b-button>
          </h2>

          <div class="mb-3">
            <small>
              <b class="form-control-label"> Дата публикации: </b>
              {{ item.created_at_formatted }}
            </small>
          </div>

          <b-form-group>
            <a-label :label="'Авторы'" />
            <b-row>
              <b-col>
                <b-row>
                  <b-col cols="9">
                    <l-select v-model="anotherAuthorId" placeholder-text="ФИО" :options="users" @search-change="fetchUsers" />
                  </b-col>
                  <b-col>
                    <b-btn variant="primary" class="ml--4" @click="addAuthor" v-b-tooltip="'Добавить автора'">
                      <b-icon-person-plus-fill />
                    </b-btn>
                  </b-col>
                </b-row>
              </b-col>
              <b-col>
                <ul v-for="entry in item.entries" :key="'small' + entry.id" class="p-0 ml-4 m-0">
                  <li class="form-control-label p-0 m-0" :key="entry.id">
                    {{ entry.user.fio }}
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group class="mt-3">
            <a-label :label="'Комментарий'" />
            <b-form-textarea v-model="item.manager_comment" placeholder="Оставьте поле пустым, если комментариев нет" rows="8" />
            <div class="text-right mt-4">
              <b-button
                  variant="outline-dark"
                  size="sm" class="p-2"
                  pill
                  @click="checkPublication"
                  :disabled="isCheckDisabled"
              >
                Проверить
              </b-button>
              <b-button variant="outline-danger" size="sm" class="p-2" pill @click="declinePublication">
                Корректировка
              </b-button>
              <b-button variant="outline-info" size="sm" class="p-2" pill @click="toJournal">
                В журнал
              </b-button>
              <b-button variant="primary" size="sm" class="p-2" pill @click="acceptPublication" :disabled="isAcceptLoading">
                <template v-if="!isAcceptLoading"> Одобрить </template>
                <template v-else> Сохранение... </template>
              </b-button>
            </div>
          </b-form-group>

          <b-alert :show="isCheckDisabled" variant="warning">
            Проверка публикации с файлом возможна только при
            подстановке текста файла в отведённую форму. Проверка
            не будет доступна до сохранения!
          </b-alert>

          <div v-if="!isLoading && item.uniqueness && item.uniqueness != 'null'" class="mb-2">
            <hr />
            <b>
              Уникальность текста:
              <a-badge :variant="colorUniqueness" text-class="xs">
                {{ item.uniqueness }} %
              </a-badge>
            </b>

            <b-row v-for="match in item.matches" :key="match.url" class="mb-2 mt-3">
              <b-col cols="6"><a :href="match.url"> {{ match.url.substr(8, 20) }}... </a></b-col>
              <b-col> <b> {{ match.percent }}% </b> </b-col>
              <b-col> <a href="#" @click="highlightMatches(match.highlight)"> совпадения </a> </b-col>
            </b-row>

            <div class="mt-4 mb-4">
              <a href="#" class="font-weight-bolder" @click="highlightAllMatches"> все совпадения </a>
            </div>
            <hr />
          </div>
          <div v-if="isLoading" class="mb-2">
            <b-skeleton />
            <b-skeleton />
            <b-skeleton />
          </div>
        </template>

        <!--    КОММЕНТАРИЙ ОПЕРАТОРА    -->
        <template v-if="issetManagerComment">
          <b class="mr-2">Оператор</b>
          <small>{{ item.manager_comment }}</small>
        </template>
      </b-col>

      <!--   Редактирование публикации   -->
      <b-col class="publication" v-if="item.entry">
        <b-form-group>
          <a-label :label="'Тип услуги'" required />
          <l-select
              :disabled="!isManager"
              :options="services"
              v-model="item.entry.service_id"
              @input="updateEntry"
          />
        </b-form-group>

        <publication-forms
            :item="item"
            :service="item.entry.service"
            @on-update="$emit('on-update')"
        />
      </b-col>

    </b-row>

  </b-modal>
</template>

<script>
import PublicationResource from "../../../resources/publication";
import PublicationForms from "../../shared/Publication/PublicationForms";
import ServiceResource from "../../../resources/service";
import EntryResource from "../../../resources/entry";
import UserResource from "../../../resources/user";
import publicationMixin from "../../../mixins/publication-mixin";

export default {
  name: "PublicationViewModal",
  components: {
    PublicationForms,
  },
  mixins: [
      publicationMixin
  ],
  data() {
    return {
      isFormLoading: false,
      isAcceptLoading: false,
      item: {
        text: '',
        entry: {},
        entries: [],
      },
      anotherAuthorId: null,
      services: [],
      users: [],
      response: ''
    }
  },
  props: {
    publication: {
      type: Object,
    },
  },
  watch: {
    publication: {
      async handler() {
        await this.fetchData();
      },
      immediate: true
    },
  },
  computed: {
    colorUniqueness() {
      return this.item.uniqueness > 70 ? 'success' : 'danger'
    },
    issetManagerComment() {
      return this.item.manager_comment
          && this.item.manager_comment.length > 0
          && this.item.manager_comment != 'null';
    },
    /**
     * Проервка недоступна, если публикация
     * добавлена документом и текст не заполнен
     * (проверка уникальности текста документа
     * осуществляется через вставку оператором текста
     * в инпут)
     */
    isCheckDisabled() {
      const issetText = this.item.text !== null &&
          this.item.text !== '';
      return this.item.file !== null && !issetText;
    }
  },
  async mounted() {
    this.isFormLoading = true;
    this.services = await ServiceResource.fetch({
      isPublication: true
    });
    this.services = this.services.map((elm) => ({
      ...elm,
      value: elm.id,
      label: elm.title,
    }));
  },
  methods: {
    async fetchData() {
      this.isFormLoading = true;
      this.item = this.publication;
      if (this.item.entries)
        this.item.entry = this.item.entries[0];
      else
        this.item.entry = {};
      this.isFormLoading = false;
    },

    handleUpdate() {
      this.$emit('on-update')
    },

    /**
     * Поиск по пользователям внутри l-select
     * @param search
     * @returns {Promise<void>}
     */
    async fetchUsers(search) {
      if (search.length > 0) {
        this.users = await UserResource.fetch({
          'fio': search,
        }, 1, 5);
        this.users = this.users.map((elm) => ({
          ...elm,
          value: elm.id,
          label: elm.fio,
        }));
      }
    },

    modifyUrl(url) {
      let endpoint = url;
      endpoint = endpoint.replace('oembed', 'iframe');
      endpoint = endpoint.replace('url', 'src');
      endpoint = endpoint.replace('watch?v=', 'embed/');
      endpoint = endpoint.replace('oembed', 'iframe');
      return endpoint;
    },

    async checkPublication() {
      this.isLoading = true;
      try {
        this.item = await PublicationResource.checkUniqueness(this.item.id);
        this.isFormLoading = true;
        this.item.entry = this.item.entries[0];
        this.isFormLoading = false;
      } catch (e) {
        this.$notify({
          type: "danger",
          message: e.response.data.message,
        });
      }
      this.isFormLoading = false;
      this.isLoading = false;
    },

    async acceptPublication() {
      this.isAcceptLoading = true;
      try {
        await this.save();
        await PublicationResource.acceptPublication(this.item, this.item.id);
      } catch (e) {
        const error = e.response.data.message;
        // todo скрыто до фикса onContentPrepare() со стороны шаблона joomlart
        // const message = `Ошибка сохранения публикации в Joomla! ${error}...`;
        // this.$notify({
        //   message: message,
        //   type: 'danger',
        // });
      }
      this.isAcceptLoading = false;
      // todo после фикса шаблона joomlart перенести в try{}
      this.$bvModal.hide('publicationViewModal');
      this.$emit('on-update');
      this.$notify({
        type: 'success',
        message: 'Публикация одобрена!'
      })
    },

    notifyStatusChanged() {
      this.$notify({
        type: 'success',
        message: 'Статус публикации успешно изменён!'
      })
    },

    async declinePublication() {
      await PublicationResource.declinePublication(this.item, this.item.id);
      this.$bvModal.hide('publicationViewModal');
      this.$emit('on-update');
      this.notifyStatusChanged();
    },

    async toJournal() {
      await PublicationResource.sendToJournal(this.item, this.item.id);
      this.$bvModal.hide('publicationViewModal');
      this.$emit('on-update');
      this.notifyStatusChanged();
    },

    async reject() {
      if (this.item.manager_comment) {
        await PublicationResource.reject(this.item, this.item.id);
        this.$bvModal.hide('publicationViewModal');
        this.$emit('on-update');
        this.notifyStatusChanged();
      } else {
        this.$notify({
          type: 'danger',
          message: 'Укажите причину отклонения!'
        })
      }
    },

    async rejectSilently() {
      await PublicationResource.rejectSilently(this.item, this.item.id);
      this.$bvModal.hide('publicationViewModal');
      this.$emit('on-update');
      this.notifyStatusChanged();
    },

    /**
     * TODO Обновлять отображаемый список авторов
     * Добавление автора к публикации
     */
    async addAuthor() {
      this.isFormLoading = true;
      if (Number.isInteger(this.anotherAuthorId)) {
        this.item = await PublicationResource.addAuthor(
            this.item.id,
            this.anotherAuthorId
        );
        await this.fetchData();
        this.$emit('on-update');
        this.$notify({
          type: "success",
          message: "Автор успешно добавлен",
        });
      } else {
        this.$notify({
          type: "danger",
          message: "Выберите автора из выпадающего списка!",
        });
      }
      this.isFormLoading = false;
    },

    async updateEntry() {
      const service = this.services.filter(
          elm => elm.id === this.item.entry.service_id
      )[0];
      for (const entry of this.item.entries) {
        this.item.entry.price = service.price;
        await EntryResource.save(this.item.entry);
      }
      this.$emit('on-update');
      this.$notify({
        message: 'Тип успешно изменён!',
        type: 'success'
      })
    },

    replaceTags(str) {
      let result = str.replaceAll('<b class="bg-yellow">', '');
      result = result.replaceAll('</b>', '')
      return result;
    },

    /**
     * Подсвечивает совпадения
     * @param matches Массив совпадений
     */
    highlightMatches(matches) {
      console.log(this.item);
      this.item.text = this.replaceTags(this.item.text);
      let textAsArray = this.item.text.split(' ');
      matches.forEach(match => {
        match.forEach(index => {
          textAsArray[index] =
              `<b class="bg-yellow">${textAsArray[index]}</b>`;
        });
      })
      this.item.text = textAsArray.join(' ');
    },

    highlightAllMatches() {
      this.item.text = this.replaceTags(this.item.text);
      let textAsArray = this.item.text.split(' ');
      this.item.highlight.forEach(match => {
        match.forEach(index => {
          textAsArray[index] = `<b class="bg-yellow">${textAsArray[index]}</b>`;
        });
      })
      this.item.text = textAsArray.join(' ');
    }

  },
}
</script>

<style>
  iframe {
    width: 800px !important;
    height: 400px !important;
  }

  .publication {
    max-height: 80vh !important;
    overflow: auto;
  }
</style>