import Resource from "../resource";
import axios from "axios";

export default class ServiceResource extends Resource {
    static async search(params) {
        const url = `${this.backendUrl}/${this.url}/search`;
        const { data } = await axios.post(url, params);
        return data;
    }

    // TODO Исправить ссылку
    static async saveToJoomla(id) {
        const url = `${this.backendUrl}/${this.url}/save-to-joomla/${id}`;
        const { data } = await axios.post(url, {});
        return data;
    }

    static async uploadImage(id, params) {
        const url = `${this.backendUrl}/${this.url}/${id}/upload-image`;
        const { data } = await axios.post(url, params);
        return data;
    }
}

ServiceResource.url = 'service';