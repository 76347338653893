var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper bg-gradient-indigo",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{staticClass:"nav-user",attrs:{"background-color":_vm.sidebarBackground}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Главная',
          icon: 'ni ni-chart-bar-32 text-primary',
          path: '/user/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          html: ("Мои заявки " + _vm.spanDebt),
          name: "Мои заявки",
          icon: 'ni ni-send text-green',
          path: '/user/entry',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Услуги',
          icon: 'ni ni-archive-2 text-pink',
          path: '/user/service',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Пакеты услуг',
          icon: 'ni ni-app text-orange',
          path: '/user/service-package',
        }}}),_c('sidebar-item',{attrs:{"link":{
          html: "Мои публикации",
          name: "Мои публикации",
          icon: 'ni ni-single-copy-04 text-info',
          path: '/user/publication',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Профиль',
          icon: 'ni ni-single-02 text-yellow',
          path: '/user/profile',
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('navbar-head'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }