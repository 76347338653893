<template>
  <b-nav-item-dropdown class="dots-actions" right>
    <template #button-content>
      <slot name="button">
        <button class="btn btn-link text-dark mb-0">
          <b-icon-printer font-scale="1.5"/>
        </button>
      </slot>
    </template>
    <b-dropdown-item
        :disabled="!item.service.template_certificate_id"
        :href="`/entry/${item.id}/print/${types.certificate}`"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-printer-fill /></b-col>
        <b-col align="left"><span>Сертификат</span></b-col>
      </b-row>
    </b-dropdown-item>
    <b-dropdown-item
        v-if="isManager"
        :disabled="!item.service.template_certificate_id"
        :href="`/entry/${item.id}/print/${types.certificateDocx}`"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-printer-fill /></b-col>
        <b-col align="left"><span>Сертификат DOCX</span></b-col>
      </b-row>
    </b-dropdown-item>
    <b-dropdown-item
        :disabled="!item.service.template_program_id"
        :href="`/entry/${item.id}/print/${types.program}`"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-printer /></b-col>
        <b-col align="left"><span>Программа</span></b-col>
      </b-row>
    </b-dropdown-item>
    <b-dropdown-item
        :disabled="!item.service.template_participation_id"
        :href="`/entry/${item.id}/print/${types.registrationList}`"
        target="_blank"
    >
      <b-row>
        <b-col cols="1"><b-icon-printer /></b-col>
        <b-col align="left"><span>Рег. лист</span></b-col>
      </b-row>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import EntryResource from "../../../resources/entry";

export default {
  name: "EntryPrint",
  data() {
    return {
      types: {
        certificate: 'certificate',
        certificateDocx: 'certificate-docx',
        program: 'program',
        registrationList: 'registration-list',
      }
    }
  },
  props: {
    item: {
      type: Object
    }
  },
  methods: {
    async print(type) {
      const file = await EntryResource.print(this.item.id, type);
      window.open(file.url, '_blank');
    }
  },
}
</script>

<style>
.dots-actions .dropdown-toggle::after {
  content: none !important;
}
.dots-actions .dropdown-toggle {
  padding: 0px !important;
}

</style>
