var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper bg-default",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"id":"sidenav-admin"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
            name: 'Главная',
            icon: 'ni ni-chart-bar-32 text-primary',
            path: '/manager/dashboard',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: "Заявки",
            icon: 'ni ni-send text-yellow',
            path: '/manager/entry',
          }}}),_c('sidebar-item',{attrs:{"link":{
            html: ("Публикации " + _vm.spanPending),
            icon: 'ni ni-align-center text-red',
            path: '/manager/publication',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Услуги',
            icon: 'ni ni-archive-2 text-pink',
            path: '/manager/service',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Пакеты услуг',
            icon: 'ni ni-app text-purple',
            path: '/manager/service-package',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Пользователи',
            icon: 'ni ni-single-02 text-info',
            path: '/manager/users',
          }}}),_c('sidebar-item',{attrs:{"link":{
              name: 'Платежи',
              icon: 'ni ni-money-coins text-cyan',
            }}},[_c('sidebar-item',{attrs:{"link":{
                name: 'Платежи',
                path: '/manager/payments',
              }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'ЮКасса',
                path: '/manager/yookassa',
              }}})],1),_c('sidebar-item',{attrs:{"link":{
              name: 'Шаблоны',
              icon: 'ni ni-single-copy-04',
            }}},[_c('sidebar-item',{attrs:{"link":{
                name: 'Макросы',
                path: '/manager/macroses',
              }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'Шаблоны',
                path: '/manager/templates'
            }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'События',
                path: '/manager/events',
              }}})],1),_c('sidebar-item',{attrs:{"link":{
              name: 'Справочники',
              icon: 'ni ni-briefcase-24 text-orange',
            }}},[_c('sidebar-item',{attrs:{"link":{
                name: 'Ведущие',
                path: '/manager/leadings'
            }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'Типы услуг',
                path: '/manager/service-types'
            }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'Профили',
                path: '/manager/profiles',
              }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'Рубрики',
                path: '/manager/publication-categories',
              }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'Выпуски',
                path: '/manager/publication-releases',
              }}})],1),_c('sidebar-item',{attrs:{"link":{
              name: 'Разное',
              icon: 'ni ni-settings-gear-65 text-gray',
            }}},[_c('sidebar-item',{attrs:{"link":{
                name: 'Иконки',
                path: '/manager/icons',
              }}}),_c('sidebar-item',{attrs:{"link":{
                name: 'Отзывы',
                path: '/manager/reviews',
              }}})],1)],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),_c('content-footer',{staticClass:"bg-transparent"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }