<template>
  <b-modal
      hide-footer
      id="modalDefaultCertificate"
      size="xs"
      title="Установить шаблон по умолчанию для типа услуг"
  >

    <div class="mt--5 pt-3 pb-2">
      <div class="form-group">
        <label class="form-control-label">Тип услуги</label>
        <l-select :options="typesAll" v-model="alias" />
      </div>

      <div class="form-group">
        <label class="form-control-label">Шаблон сертификата</label>
        <l-select :options="templatesCertificate" v-model="certificateId" />
      </div>
    </div>

    <div class="text-right">
      <b-button variant="primary" pill @click="save">
        Установить по умолчанию
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import TemplateResource from "../../../../resources/template";

export default {
  name: "DefaultCertificateModal",
  data() {
    return {
      templatesCertificate: [],
      alias: null,
      certificateId: null,
      templateTypes: {
        certificate: 1,
        program: 2,
        regList: 3,
      },
    }
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      const templates = await TemplateResource.fetch({
        dict_type_id: this.templateTypes.certificate
      })
      this.templatesCertificate = templates.map((item) => ({
        ...item,
        value: item.id,
        label: item.title
      }))
    },
    async save() {
      await TemplateResource.setAsDefaultForSubtype(
          this.certificateId,
          this.alias
      );
      this.$notify({
        type: "success",
        message: "Шаблон успешно установлен по умолчанию!",
      });
    },
  },
}
</script>

<style scoped>

</style>