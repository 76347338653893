import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

export default {

  state: {
    notifications: {},
    newNotifications: {},
  },

  getters: {
    notifications: state => state.notifications,
    newNotifications: state => state.newNotifications
  },


  mutations: {
    SET_NOTIFICATIONS: (state, payload) => {
      state.notifications = payload;
    },

    SET_NEW_NOTIFICATIONS: (state, payload) => {
      state.newNotifications = payload;
    }
  },

  actions: {
    async notifications({commit}) {
      const response = await axios.post(`${url}/api/notification/index`);
      const newNotifications = response.data.filter(elm => !elm.checked)
      commit('SET_NEW_NOTIFICATIONS', newNotifications);
      return commit('SET_NOTIFICATIONS', response.data);
    },
  },

}