<template>
  <div>
    <div class="row icon-examples">

      <base-table
          class="table align-items-center table-flush mx-3"
          tbody-classes="list"
          :data="events"
          :thead-classes="'thead-light'"
      >
        <template slot="columns">
          <th></th>
          <th><span class="ml-3">Заголовок</span></th>
          <th><span>Письмо</span></th>
          <th class="text-center">Шаблон уведомления</th>
<!--          <th>Каждые ... часов</th>-->
          <th>Рассылка писем</th>
        </template>

        <template slot-scope="{ row }">
          <td class="col-1">
            <div
                :class="`bg-${row.color}`"
                class="icon icon-shape rounded-circle shadow mt-1 float-right mr-2"
            >
              <i class="text-white" :class="row.icon" />
            </div>
          </td>

          <td class="text-wrap col-3 font-weight-bold">
            {{ row.fdv }}
          </td>

          <td class="text-wrap col-1">
            <b-btn @click="showEmailModal(row)">
              <i class="ni ni-email-83" />
             </b-btn>
<!--            <b-textarea v-model="row.dv" @input="saveRow(row)"/>-->
<!--            {{ row.dv }}-->
          </td>

          <td class="text-center col-3">
            <l-select
                class="text-wrap"
                @input="saveRow(row)"
                :options="templatesNotification"
                v-model="row.template_id"
            />
          </td>

<!--          <td class="text-center col-1">-->
<!--            <l-select-->
<!--                v-if="row.per_hours"-->
<!--                @input="saveRow(row)"-->
<!--                :options="hours"-->
<!--                class="col-10"-->
<!--                v-model="row.per_hours"-->
<!--                placeholder-text="0"-->
<!--            />-->
<!--          </td>-->

          <td class="text-center col-1">
            <b-form-checkbox
                @input="saveRow(row)"
                :variant="row.is_mailing ? 'success' : 'danger'"
                v-model="row.is_mailing"
                size="sm"
                switch
            />
          </td>
        </template>
      </base-table>

      <event-email-modal :event="selectedEvent" />

    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import TemplateResource from "../../../../resources/template";
import DictResource from "../../../../resources/dict";
import EventEmailModal from "./EventEmailModal.vue";
Vue.use(VueClipboard)

export default {
  name: "EventList",
  components: {EventEmailModal},
  data() {
    return {
      templates: [],
      hours: [...Array(24).keys()],
      selectedEvent: {},
      welcomeEventId: 1,
    }
  },
  computed: {
    events() {
      return this.$store.getters.getDict('dict_events');
    },
    templatesNotification() {
      const typeNotificationId = 5;
      return this.templates.filter(
          elm => elm.dict_type_id === typeNotificationId
      );
    },
  },
  async mounted() {
    await this.fetchData();
    this.hours = this.hours.map(elm => ({
      label: elm + 1,
      value: elm + 1
    }));
  },
  methods: {
    async fetchData() {
      const templates = await TemplateResource.fetch();
      this.templates = templates.map(item => ({
        ...item,
        value: item.id,
        label: item.title,
      }))
    },
    /**
     * Сохраняет событие
     * @param row
     * @returns {Promise<void>}
     */
    async saveRow(row) {
      DictResource.setUrl('dict_events');
      await DictResource.save(row);
      this.$notify({
        type: 'success',
        message: 'Успешно сохранено!'
      });
      await this.fetchData();
    },
    /**
     * Открытие модального окна с данными
     * письма на емиал
     * @param row
     */
    showEmailModal(row) {
      this.selectedEvent = row;
      this.$bvModal.show('eventEmailModal');
    },
  }
}
</script>

<style scoped>

</style>