export default {
  data() {
    return {
      isLoading: false,
      apiValidationErrors: {}
    };
  },
  methods: {
      setApiValidation(serverErrors, refs = null) {
          const keys = Object.keys(serverErrors);
          const length = keys.length;
          const errors = {};

          for (let i = 0; i < length; i++) {
              const errorFieldName = keys[i];
              const errorDetail = serverErrors[errorFieldName];
              errors[errorFieldName] = errorDetail;
          }

          this.apiValidationErrors = errors;
          console.log('VALIDATION ERRORS', this.apiValidationErrors);
      },

      areValidationErrorsEmpty() {
          return Object.keys(this.apiValidationErrors).length === 0;
      },

      resetApiValidation() {
          this.apiValidationErrors = {};
      }
  }
};
