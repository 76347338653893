<template>
  <div>
    <h6 class="heading-small text-muted mb-4">
      Управление
    </h6>
    <div>
      <div class="pl-lg-4">
        <div class="form-group">
          <label class="form-control-label">Роль</label>
          <l-select :options="roles" v-model="item.role" />
        </div>
      </div>
      <!-- Description -->
      <b-form-group class="pl-lg-4">
        <label class="form-control-label">Примечание</label>
        <b-form-textarea
            placeholder="Введите примечание..."
            rows="4"
            v-model="item.note"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManagementCard",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      roles: [
        { label: 'Гость', value: 3 },
        { label: 'Пользователь', value: 2 },
        { label: 'Оператор', value: 1 }
      ],
    }
  }
}
</script>

<style scoped>

</style>