import EntryResource from "../resources/entry";
import YookassaEntryResource from "../resources/yookassa-entry";

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async pay(entry, isWithFilters) {
      if (isWithFilters === undefined) isWithFilters = false;
      const immediate = this.isManager || entry.price === 0;
      const params = { immediate: immediate };
      if (immediate) {
        const immediateConfirmation = await this.immediateConfirmation();
        if (!immediateConfirmation) return false;
      }
      const payment = await EntryResource.pay(entry.id, params);
      if (!immediate) {
        if (payment.confirmation) {
          window.location.href = payment.confirmation.confirmation_url;
        } else {
          this.$notify({
            message: 'Произошёл сбой',
            type: 'danger',
          });
        }
      }
      if (!isWithFilters)
        this.$emit('on-update', this.entriesPagination.current_page);
      else
        this.$emit('on-update', this.entriesPagination.current_page, this.filters);
      if (!immediate) {
        await this.$store.dispatch('me');
      }
    },

    /**
     * Независимый платёж, т.е. без передаваемых сущностей
     * @returns {Promise<boolean>}
     */
    async payIndependently(params) {
      const payment = await YookassaEntryResource.pay(params);
      if (payment.confirmation) {
        window.location.href = payment.confirmation.confirmation_url;
      } else {
        this.$notify({
          message: 'Произошёл сбой',
          type: 'danger',
        });
      }
    },

    async immediateConfirmation() {
      return this.$bvModal.msgBoxConfirm(
        'Вы уверены, что хотите отметить заявку оплаченной?', {
          size: 'sm',
          buttonSize: 'sm',
          okTitle: 'ДА',
          cancelTitle: 'НЕТ',
          hideHeaderClose: false,
          centered: true,
        });
    }
  }
};
