<template>
  <module
      :bg-header="bgHeader(item)"
      color-header="white"
      @on-click="$emit('on-click', item)"
      align="center"
      tag="article"
      class="mb-5 mt-2 bg-transparent border-0 service-package-card shadow-none pointer mx-0 px-3"
      :class="`col-12`"
  >
    <template v-slot:header
        v-if="!isDashboard"
    >
      <div
          :key="`profile-${item.id}`"
          v-if="item.profile"
          class="text-xs mt--2"
          :class="{'ml--4': isManager}"
      >
        {{ item.profile.fdv }}
      </div>
      <div
          v-else
          class="text-xs mt--2"
          :class="{'ml--4': isManager}"
      >
        Общий
      </div>
      <span class="float-left mt--3" v-if="isManager">
        <b-btn
            v-if="!item.is_pinned"
            variant="outline-white"
            @click.stop="pinItem(item)"
            class="p-2"
        >
          <b-icon-pin />
        </b-btn>
        <b-btn
            v-else
            variant="white"
            class="p-2"
            v-b-tooltip="`Закреплён для ${pinnedFor}`"
        >
          <b-icon-pin-fill />
        </b-btn>
      </span>
      <span :class="{'ml-3': isManager}">
        {{ item.title }}
      </span>
      <span class="float-right mt--3" v-if="isManager">
        <b-btn tag="a" variant="dark" class="p-2" @click.stop="edit(item)">
          <i class="fas fa-edit" />
        </b-btn>
        <b-btn tag="a" variant="danger" @click.stop="remove(item)" class="p-2">
          <i class="fas fa-trash" />
        </b-btn>
      </span>
    </template>

    <!--  Закреплённый пакет услуг для дашборда пользователя  -->
<!--    <b-card-header-->
<!--        v-else-->
<!--        :class="`bg-${bgHead}`"-->
<!--        header-text-variant="white"-->
<!--    >-->
<!--      <h4 class="p-0 m-0 text-white text-left">-->
<!--        <span class="font-weight-300">Рекомендуемый пакет услуг сегодня:</span> <br>-->
<!--        <b>{{ item.title }}</b>-->
<!--        <a-badge class="ml-2" variant="dark">-->
<!--          <s>{{ item.oldPrice }} ₽</s>-->
<!--        </a-badge>-->
<!--        <a-badge class="ml-2" variant="info">-->
<!--          {{ item.price }} ₽-->
<!--        </a-badge>-->
<!--        <a-badge class="ml-2" variant="warning">-->
<!--          {{ item.points }} баллов-->
<!--        </a-badge>-->
<!--      </h4>-->
<!--    </b-card-header>-->

      <template v-if="showDescription">
        <h1 class="mt--2">
          <s class="text-sm"><b>{{ item.oldPrice }} ₽</b></s>
          <b-badge class="ml-2" variant="info">
            {{ item.price }} ₽
          </b-badge>
        </h1>
        <h2><b-badge class="ml-2" variant="warning">
          {{ item.points }} баллов
        </b-badge></h2>

        <br />
        <b-card-text>
          {{ item.description }}
        </b-card-text>
      </template>

      <b-list-group flush>
        <b-list-group-item v-if="showDescription" />
        <b-list-group-item v-for="service in item.services" :key="service.id">
          <b-badge variant="light">
            <b-icon-check2-circle class="h5 mr-2 mb-0 mt-0" />
            <b class="text-wrap">{{ service.type.fdv }}</b>
          </b-badge><br>
          <small class="service-title" :class="{'d-block': showTitles}">{{ service.title }}</small>
        </b-list-group-item>
        <b-list-group-item v-if="showDescription" />
      </b-list-group>

  </module>
</template>

<script>
import Module from "../../user/Dashboard/Modules/Module";
import ServicePackageResource from "../../../resources/service-package";

export default {
  name: "ServicePackageCard",
  components: {
    Module
  },
  props: {
    item: {
      type: Object,
    },
    cols: {
      type: String,
      default() {
        return '4'
      }
    },
    showDescription: {
      type: Boolean,
      default() {
        return true;
      }
    },
    showTitles: {
      type: Boolean,
      default() {
        return false;
      }
    },
    isDashboard: {
      type: Boolean,
      default() {
        return false;
      }
    },
    bgHead: {
      type: String,
      default() {
        return 'gradient-purple';
      }
    },
  },
  computed: {
    pinnedFor() {
      if (this.item.profile) {
        return `профиля '${this.item.profile.fdv}'`;
      } else {
        return 'пользователей без профиля';
      }
    },
  },
  methods: {
    bgHeader(item) {
      return item.color ?? 'purple';
    },
    async remove(item) {
      await this.deleteItem(ServicePackageResource, item.id);
      this.$emit('on-update');
    },
    async edit(item) {
      this.$emit('on-edit', item);
    },
    async pinItem(item) {
      await ServicePackageResource.pin(item.id);
      this.$emit('on-update');
      this.$emit('on-update-pinned');
      this.$notify({
        message: 'Успешно закреплено!',
        type: 'success'
      })
    },
  },
}
</script>

<style scoped>

</style>