<template>
  <b-modal
      size="lg"
      :title="service.title"
      title-class="text-lg p-2 text-center"
      id="serviceEntryModal"
      centered
      cancel-title="Закрыть"
      ok-title="Подать заявку"
      @hide="clearRoute"
      @close="clearRoute"
      hide-footer
  >

    <!--  ЗАЯВКА  -->
    <template v-if="!model.id">
      <b-card v-html="service.description" class="card-description p-3" />

      <div v-if="service.sections.length > 0" class="mx-4 my-3 p-2">
        <h3>Выберите секцию: <span class="text-danger">*</span></h3>
        <b-form-radio-group v-model="model.section_id">
          <b-form-radio
              class="col-12 mt-3"
              v-for="section in service.sections"
              :key="section.id"
              :value="section.id"
              @input="defineSaveAvailability"
          >
            {{ section.title }}
          </b-form-radio>
        </b-form-radio-group>
      </div>

      <b-form-group class="ml-4 p-2" label="Тип оплаты">
        <b-form-radio-group
            id="btn-radios-1"
            v-model="model.pay_type"
            :options="userPaymentTypes"
            name="radios-btn-default"
            buttons
        />
      </b-form-group>

      <b-form-group class="ml-4 p-2 mb-0" label="Тип участия">
        <b-form-radio-group
            id="btn-radios-1"
            v-model="model.is_extramural"
            :options="participationTypes"
            name="radios-btn-default"
            buttons
        />
        <div class="text-right mt-3">
        </div>
      </b-form-group>

      <div class="float-right">
        <span v-if="isSaveDisabled" class="text-danger">
        Выберите секцию!
        </span><br>
        <b-btn
            v-if="!isSaving"
            @click="save"
            variant="primary"
            :disabled="isSaveDisabled"
        >
          Подать заявку
        </b-btn>
        <b-btn
            v-else
            variant="primary"
            disabled
        >
          Сохранение...
        </b-btn>
      </div>

    </template>

    <!--  ТЕЗИСЫ  -->
    <!--  TODO Вынести в отдельный компонент в shared  -->
    <template v-if="model.id && !isThesisSent">
      <b-form-group class="pl-lg-4 mr-4 ml-2 mr-2 mt--4">
        <a-label :label="'Тезисы:'" required />
        <b-form-textarea
            placeholder="Введите тезисы..."
            rows="20"
            v-model="thesis.text"
        />
        <div class="mt-3">
          Вы ввели: {{ thesis.text.length }} <br>
          Размер принимаемых тезисов: {{ minTextLength }}-{{ maxTextLength }} символов <br>
          <div class="text-danger" v-if="!thesis.text || thesis.text.length < minTextLength">
            Допишите ваши тезисы, сейчас они слишком короткие (минимум 1500 символов)
          </div>
          <div class="text-danger" v-if="thesis.text.length > maxTextLength">
            Слишком много символов! (максимум 10000 символов)
          </div>
        </div>
      </b-form-group>

      <div class="float-right">
        <b-btn variant="secondary" @click="skipThesis">
          Пропустить
        </b-btn>
        <b-btn variant="primary" @click="saveThesis" :disabled="!isThesisLengthValid">
          Сохранить
        </b-btn>
      </div>
    </template>

    <!--  ОПЛАТА  -->
    <template v-if="isThesisSent && this.model.id && this.model.price !== 0">
      <div class="mx-5 text-center mt--4">
        <span class="text-xl"> {{ model.price }} </span> ₽ <span class="ml-1"> к оплате </span>
        <b-btn @click="handlePay" block class="bg-gradient-blue mt-3 border-0 py-3 text-white font-weight-bolder">
          Оплатить сейчас
        </b-btn>
        <router-link to="/user/entry">
          <b-btn block class="py-3">
            Оплатить позже
          </b-btn>
        </router-link>
      </div>
    </template>

  </b-modal>
</template>

<script>
import EntryResource from "../../../resources/entry";
import EntryThesisResource from "../../../resources/entry-thesis";
import paymentMixin from "../../../mixins/payment-mixin";

export default {
  name: "ServiceEntry",
  mixins: [
    paymentMixin
  ],
  data() {
    return {
      model: {
        created_by: null,
        service_id: null,
        price: "",
        section_id: null,
        pay_type: 3,
      },
      thesis: {
        text: '',
        entry_id: null,
      },
      payTypeOnline: 3,
      // todo вынести в константы!
      minTextLength: 1500,
      maxTextLength: 10000,
      isThesisSent: false,
      isSaving: false,
      isSaveDisabled: false,
      dictParticipationTypes: {
        double: 1,
        intramural: 2,
        extramural: 3
      },
    }
  },
  props: {
    service: {
      type: Object,
    },
  },
  watch: {
    service: {
      handler() {
        this.model = {};
        this.isThesisSent = !this.service.is_thesis_needed;
        this.model.section_id = null;
        this.model.is_extramural = this.participationTypes[0].value;
        this.model.pay_type = this.payTypeOnline;
        this.defineSaveAvailability();
      },
    },
  },
  computed: {
    isThesisLengthValid() {
      const textLength = this.thesis.text.length;
      return textLength > this.minTextLength && textLength < this.maxTextLength;
    },
    participationTypes() {
      const intramural = { text: 'Очное', value: 0 };
      const extramural = { text: 'Заочное', value: 1 };
      if (this.service.participation_type === this.dictParticipationTypes.extramural)
        return [extramural];
      if (this.service.participation_type === this.dictParticipationTypes.intramural)
        return [intramural];
      return [intramural, extramural];
    }
  },
  methods: {
    async save() {
      this.isSaving = true;
      this.model.created_by = this.$store.getters.user.id;
      this.model.service_id = this.service.id;
      this.model.price = this.service.price;

      this.model = await EntryResource.save(this.model);
      this.$notify({
        type: 'success',
        message: 'Заявка успешно отправлена!'
      })
      this.isSaving = false;
      await this.$store.dispatch('me');
    },
    clearRoute() {
      this.$router.push('/user/service');
    },
    /**
     * Определяет, можно ли нажать на кнопку "отправить заявку"
     * @returns {boolean}
     */
    defineSaveAvailability() {
      const issetSections = this.service.sections !== undefined &&
          this.service.sections.length > 0;
      const isSectionChosen = !!this.model.section_id;
      this.isSaveDisabled = (issetSections && !isSectionChosen);
    },
    skipThesis() {
      this.isThesisSent = true;
      this.handleFreeIfNeeded();
    },
    async saveThesis() {
      this.isThesisSent = true;
      this.thesis.entry_id = this.model.id;
      await this.validateAndSave(EntryThesisResource, this.thesis, false);
      this.handleFreeIfNeeded();
    },
    handleFreeIfNeeded() {
      if (this.model.price === 0) {
        this.$bvModal.hide('serviceEntryModal')
        this.$emit('on-update');
      }
    },
    async handlePay() {
      await this.pay(this.model);
    }
  },
}
</script>

<style>
.card-description p {
  margin-bottom: 0px !important;
}
</style>