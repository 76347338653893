import Vue from "vue";
import router from "@/router";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const backendUrl = process.env.VUE_APP_API_BASE_URL;
// TODO Добавить префикс /api через .env

export default {
  state: {
    isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
    authErrorCode: null,
    oldUser: {},
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    authErrorCode(state) {
      return state.authErrorCode;
    },
    oldUser(state) {
      return state.oldUser;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
    SET_AUTH_ERROR_CODE(state, payload) {
      state.authErrorCode = payload;
    },
    SET_OLD_USER(state, payload) {
      state.oldUser = payload;
    },
  },

  actions: {
    /**
     * TODO Рефакторинг
     * Аутентификация пользователя
     * @param context
     * @param payload
     */
    async login({commit, dispatch}, payload) {
      await axios.get(`${backendUrl}/api/v1/sanctum/csrf-cookie`);
      const credentials = payload.user;
      try {
        const r = await axios.post(`${backendUrl}/login`, credentials);
        dispatch("me");
        const token = r.config.headers['X-XSRF-TOKEN'];
        localStorage.setItem('x-xsrf-token', token);
        commit("isAuthenticated", {
          isAuthenticated: true
        });
        if (localStorage.getItem('to'))
          router.push(localStorage.getItem('to'))
        else
          router.push({path: '/user/dashboard'});
        commit('SET_AUTH_ERROR_CODE', null)
      } catch (error) {
        dispatch("removeToken");
        dispatch("showError", error);
      }
    },

    /**
     * Записывает токен в текущего
     * пользователя в localStorage
     * @param response
     */
    storeToken(response) {
      const token = response.config.headers['X-XSRF-TOKEN'];
      localStorage.setItem('x-xsrf-token', token)
    },

    removeToken() {
      localStorage.removeItem('x-xsrf-token')
    },

    /**
     * TODO Рефакторинг
     * Регистрация
     * @param context
     * @param payload
     * @returns {Promise<void>}
     */
    async register({commit, dispatch}, payload) {
      const response = await axios.get(`${backendUrl}/api/v1/sanctum/csrf-cookie`);
      const credentials = payload.user.data.attributes;
      const token = response.config.headers['X-XSRF-TOKEN'];
      localStorage.setItem('x-xsrf-token', token);
      try {
        await axios.post(`${backendUrl}/register`, credentials);
        await this.dispatch("me")
        commit("isAuthenticated", {
          isAuthenticated: true
        });
        Vue.prototype.$notify({
          type: "success",
          message: "Вы успешно зарегистрированы в системе!",
        });
        if (localStorage.getItem('to'))
          router.push(localStorage.getItem('to'))
        else
          router.push({path: '/user/dashboard'});
      } catch (error) {
        const errorMsg = error.response.data.message;
        const isNonUnique = errorMsg.includes('Duplicate') || errorMsg.includes('существует');
        if (isNonUnique) {
          const { data } = await axios.post(`${backendUrl}/api/old-user`, credentials);
          commit('SET_OLD_USER', data.data);
          return router.push({path: '/password/email'});
        }
        dispatch("showError", error);
      }
    },

    logout(context, payload) {
      axios.post(`${backendUrl}/logout`).then(response => {
        localStorage.removeItem('x-xsrf-token')
        context.commit("isAuthenticated", {
          isAuthenticated: false
        });
        router.push({path: "/login"});
      });
    },

    /**
     * Вывод ошибок
     * @param commit
     * @param dispatch
     * @param error
     */
    showError({commit, dispatch}, error) {
      commit('SET_AUTH_ERROR_CODE', error.response.status)
      console.log(error.response.data.errors.email)
      if (error.response.status === 422 && error.response.data.errors.email) {
        Vue.prototype.$notify({
          message: 'Введите корректный email',
          type: 'danger',
        });
      } else if (error.response.status === 422) {
        Vue.prototype.$notify({
          message: 'Заполните все обязательные поля!',
          type: 'danger',
        });
      } else {
        Vue.prototype.$notify({
          message: error.response.data.message,
          type: 'danger',
        });
      }
    }
  }
};
