<template>
<div>
  <h2 class="text-center mb-4">
    <span class="ml--6">
      <joomla-logo />
    </span>
  </h2>

  <b-form-group>
    <a-label :label="'Шаблон страницы'" required />
    <l-select v-model="item.joomla_article_template_id" :options="templates" />
    <validation-error :errors="errors.joomla_article_template_id" />
  </b-form-group>

  <b-form-group>
    <a-label :label="'Изображение'" />
    <b-form-file
        v-model="item.image"
        placeholder="Выберите изображение или перетащите его курсором..."
        drop-placeholder="Перетащите файл сюда..."
    />
    <a-file :file="item.image" @on-remove="removeFile" hide-delete-btn />
  </b-form-group>

  <b-form-group v-if="!isLoading">
    <a-label :label="'Анонс на Joomla'" />
    <b-form-textarea v-model="item.joomla_intro_html" />

    <a-label :label="'Дополнительный контент на странице Joomla'" class="mt-4"/>
    <ckeditor
        :editor="ckEditor"
        v-model="item.joomla_additional_html"
        @ready="onReady"
        :config="editorConfig"
    />
  </b-form-group>
</div>
</template>

<script>
export default {
  name: "JoomlaCard",
  props: {
    item: {
      type: Object,
    },
    templates: {
      type: Array,
    },
    errors: {},
  },
  computed: {
    url() {
      const baseUrl = process.env.VUE_APP_JOOMLA_SITE_URL;
      if (this.item.id) {
        return `${baseUrl}/index.php/article-${this.item.id}`;
      }
      return '';
    }
  },
  methods: {
    removeFile() {
      this.item.image = null;
    }
  }
}
</script>

<style>
.title-joomla {
  border: 10px solid black !important
}

.ck-content {
  min-height: 200px;
}
</style>