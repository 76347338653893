<template>
<div>
  <base-header type="gradient-indigo" class="pb-6 pt-5 pt-md-8" />
  <span class="mask bg-gradient-indigo" />
  <div class="container-fluid mt--7 col-lg-5">
    <div class="row">
      <div class="col">
        <div class="card shadow">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">Макросы</h3>
          </div>

            <macros-list description-next-line btn-macros-class="p-2"/>

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import MacrosList from "./Macros/MacrosList";

export default {
  name: "Macros",
  components: {
    MacrosList,
  }
}
</script>

<style scoped>

</style>