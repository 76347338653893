<template>
  <module
      @on-click="enroll"
      subtitle="Рекомендуемый пакет услуг сегодня"
      :bg-header="bgHeader"
      color-header="white"
      class="pointer"
  >
    <template v-slot:header>
      {{ item.title }}
      <a-badge class="ml-2" variant="dark">
        <s>{{ item.oldPrice }} ₽</s>
      </a-badge>
      <a-badge class="ml-2" variant="info">
        {{ item.price }} ₽
      </a-badge>
      <a-badge class="ml-2" variant="warning">
        {{ item.points }} баллов
      </a-badge>
    </template>

    <b-list-group flush class="mt--3">
      <b-list-group-item v-for="service in item.services" :key="service.id">
        <b-badge variant="light">
          <b-icon-check2-circle class="h5 mr-2 mb-0 mt-0" />
          <b class="text-wrap">{{ service.type.fdv }}</b>
        </b-badge><br>
        <small class="service-title" :class="{'d-block': showTitles}">{{ service.title }}</small>
      </b-list-group-item>
    </b-list-group>

    <service-package-entry-modal :item="item" />

  </module>
</template>

<script>
import Module from "./Module";
import ServicePackageEntryModal from "../../../shared/ServicePackages/ServicePackageEntryModal";

export default {
  name: "ServicePackageCard",
  components: {
    Module,
    ServicePackageEntryModal
  },
  props: {
    item: {
      type: Object,
    },
    showTitles: {
      type: Boolean,
      default() {
        return false;
      }
    },
  },
  computed: {
    bgHeader() {
      return this.item.color ?? 'purple';
    },
  },
  methods: {
    /**
     * Запись на пакет услуг
     */
    enroll() {
      this.$bvModal.show('servicePackageEntryModal');
    }
  },
}
</script>

<style scoped>

</style>