<template>
  <div class="card">
    <div class="card-header">
      <h1>Изменить пароль</h1>
    </div>
    <div class="card-body">
      <form ref="password_form">
        <base-input
          v-model="password"
          type="password"
          name="new_password"
          autocomplete="on"
          class="input-group-alternative"
          addon-left-icon="ni ni-lock-circle-open"
          placeholder="Новый пароль"
        />
        <validation-error :errors="apiValidationErrors.password" />

        <base-input
          v-model="password_confirmation"
          type="password"
          name="confirm_password"
          autocomplete="on"
          class="input-group-alternative"
          addon-left-icon="ni ni-lock-circle-open"
          placeholder="Подтверждение пароля"
        />
<!--        <validation-error :errors="apiValidationErrors.password_confirmation" />-->
        <div class="my-4">
          <b-btn
              @click="handleChangePassword"
              size="sm"
              variant="primary"
              native-type="submit"
          >
            Сохранить пароль
          </b-btn>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import UserResource from "../../../../resources/user";
import BaseInput from "@/components/BaseForms/BaseInput.vue";

export default {
  name: "UserPasswordCard",

  components: {
    BaseInput,
  },

  computed: {
    user() {
      return this.$store.getters.user;
    }
  },

  data() {
    return {
      password: null,
      password_confirmation: null,
    };
  },

  methods: {
    async handleChangePassword() {
      // this.resetApiValidation();
      if (this.comparePasswords()) {
        await UserResource.changePassword({
          password: this.password
        }, this.user.id);
        this.$notify({
          type: "success",
          message: "Пароль успешно обновлён!",
        });
      } else {
        this.$notify({
          type: "danger",
          message: "Пароли не совпадают!",
        });
      }
    },
    comparePasswords() {
      return this.password === this.password_confirmation;
    }
  },
};
</script>
