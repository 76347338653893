<template>
  <div class="user-dashboard">
    <base-header type="gradient-indigo" class="pb-6 pb-8 pt-5 pt-md-8">
      <widgets />
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">

        <!--    TODO Придумать, как выводить инструкцию и рекламу пакета услуг    -->
        <instructions class="module" />

        <notification class="module" />

        <template v-if="!issetEntriesUnpaid">
          <service-package-card
              :item="pinnedServicePackage"
              v-if="!isLoading && pinnedServicePackage"
              class="module"
              show-titles
          />
          <service-package-skeleton class="module" v-else-if="isLoading" />
          <user class="module" v-else />
        </template>

        <payment v-if="issetEntriesUnpaid" class="module" />

      </div>
    </div>
  </div>
</template>
<script>
import Widgets from "@/components/Widgets";
import Notification from "./Modules/Notification";
import Payment from "./Modules/Payment";

import ServicePackageResource from "../../../resources/service-package";
import ServicePackageCard from "./Modules/ServicePackageCard";
import ServicePackageSkeleton from "./Modules/ServicePackageSkeleton";
import User from "./Modules/User";
import Instructions from "./Modules/Instructions";

export default {
  components: {
    Widgets,
    Notification,
    Payment,
    ServicePackageCard,
    ServicePackageSkeleton,
    User,
    Instructions
  },
  data() {
    return {
      pinnedServicePackage: {},
      notifications: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch('me');
    await this.fetchData();
  },
  computed: {
    issetEntriesUnpaid() {
      return this.$store.getters.user.entries_unpaid_num > 0;
    }
  },
  methods: {
    /**
     * Выводит пакет услуг по профилю пользователя
     * @returns {Promise<void>}
     */
    async fetchData() {
      const params = this.getPackageSearchParamsByUser();
      const pinnedServicePackages = await ServicePackageResource.fetch(params);
      this.pinnedServicePackage = pinnedServicePackages[0];
      this.isLoading = false;
    },

    /**
     * Параметры для поиска по пакетам услуг в зависимости от наличия
     * / отсутствия у пользователя профилей
     * @returns {{is_pinned: boolean}|{dict_profile_id, is_pinned: boolean}}
     */
    getPackageSearchParamsByUser() {
      const currentUserProfile = this.$store.getters.user.profiles[0];
      if (this.$store.getters.user.profiles.length > 0) {
        return {
          is_pinned: true,
          dict_profile_id: currentUserProfile.id
        }
      } else {
        return {
          is_pinned: true,
          dict_profile_id: null,
        }
      }
    }
  },
};
</script>
<style>

</style>
