<template>
  <div>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-11 mt-3">
          <b-form-group >
            <a-label :label="'Отправить сообщение'" />
            <ckeditor :editor="ckEditor" v-model="item.message" @ready="onReady" :config="editorConfig"/>
          </b-form-group>
          <div class="col text-center mb-3">
          <b-btn
              variant="primary"
              @click="sendAll(item)"
          >
            Отправить
          </b-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailResource from "../../../../resources/mail";

export default {
  name: "MailingCard",
  data() {
    return {
      item: {
        message: "",
      },
    };
  },
  methods: {
    async sendAll(item) {
      await MailResource.sendAll(item);
      this.$notify({
        type: 'success',
        message: 'Письмо успешно отправлено всем пользователем!'
      })
      await this.fetchData();
    },
  },
}
</script>

<style scoped>

</style>