<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light"
    id="sidenav-main"
  >
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="text-xl font-weight-bolder navbar-brand text-white" :to="mainUrl">
<!--        <img :src="logo" class="navbar-brand-img" alt="..." />-->
        АНЭКС
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">

          <!--    УВЕДОМЛЕНИЯ      -->
          <base-dropdown class="nav-item" position="right">
            <a
              slot="title"
              class="nav-link nav-link-icon"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="ni ni-bell-55"></i>
            </a>

            <notifications-dropdown />
          </base-dropdown>

          <!--     МЕНЮ ПОЛЬЗОВАТЕЛЯ       -->
          <base-dropdown class="nav-item" position="right">
            <a href="#" slot="title" class="nav-link" @click.prevent>
              <div class="media align-items-center">
                <span
                  class="avatar avatar-sm rounded-circle avatar-image"
                  :style="{
                    'background-image': `url('${avatarUrl}')`,
                  }"
                >
                </span>
              </div>
            </a>

            <!--     МЕНЮ ПОЛЬЗОВАТЕЛЯ: ПУНКТЫ       -->
            <template v-if="!isManagerPanel && isManager">
              <router-link to="/manager/dashboard" class="dropdown-item">
                <i class="ni ni-app"></i>
                <span>Оператор</span>
              </router-link>
              <div class="dropdown-divider"></div>
            </template>

            <template v-if="isManagerPanel && isManager">
              <router-link to="/user/dashboard" class="dropdown-item">
                <i class="ni ni-app"></i>
                <span>Пользователь</span>
              </router-link>
              <div class="dropdown-divider"></div>
            </template>

            <router-link to="/user/notifications" class="dropdown-item">
              <i class="ni ni-bell-55"></i>
              <span>
              Уведомления
              <a-badge
                  v-if="newNotifications.length > 0"
                  variant="gradient-danger"
                  pill
              >
                {{ newNotifications.length }}
              </a-badge>
            </span>
            </router-link>
            <router-link to="/user/settings" class="dropdown-item">
              <i class="ni ni-settings"></i>
              <span>Настройки</span>
            </router-link>
            <!--          <router-link to="/user/profile" class="dropdown-item">-->
            <!--            <i class="ni ni-single-02"></i>-->
            <!--            <span>Профиль</span>-->
            <!--          </router-link>-->
            <a href="" @click.prevent="logout()" to="" class="dropdown-item text-danger">
              <i class="ni ni-user-run"></i>
              <span>Выход</span>
            </a>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div
        v-show="$sidebar.showSidebar"
        class="navbar-collapse collapse show"
        id="sidenav-collapse-main"
      >
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link class="text-xl font-weight-bolder navbar-brand" :to="mainUrl">
                <!--        <img :src="logo" class="navbar-brand-img" alt="..." />-->
                АНЭКС
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button
                @click.native="closeSidebar"
              ></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>

<!--          <div class="mt-3 d-flex">-->
<!--            <b-col cols="6" class="mb-3">-->
<!--              <router-link to="/user/service">-->
<!--                <b-btn variant="primary" class="btn btn-square-md">-->
<!--                  <h3><i class="ni ni-archive-2" /></h3>-->
<!--                  АНЭКС-->
<!--                </b-btn>-->
<!--              </router-link>-->
<!--            </b-col>-->
<!--            <b-col cols="6" class="mb-3 float-left p-0">-->
<!--              <router-link to="/user/service">-->
<!--                <b-btn variant="primary" class="btn btn-square-md">-->
<!--                  <h3><i class="ni ni-archive-2" /></h3>-->
<!--                  Контакты-->
<!--                </b-btn>-->
<!--              </router-link>-->
<!--            </b-col>-->
<!--          </div>-->
        </ul>

        <template v-if="!isManagerPanel">

<!--          <div class="mt-3 d-none d-md-flex">-->
<!--            <b-col cols="6" class="mb-3 mr-3 ml&#45;&#45;4">-->
<!--              <a :href="siteUrl" target="_blank">-->
<!--                <b-btn class="btn btn-square-md bg-light-purple">-->
<!--                  <i class="ni ni-archive-2" />-->
<!--                  АНЭКС-->
<!--                </b-btn>-->
<!--              </a>-->
<!--            </b-col>-->
<!--            <b-col cols="6" class="mb-3 p-0">-->
<!--              <a :href="`${siteUrl}/contact-us`" target="_blank">-->
<!--                <b-btn class="btn btn-square-md bg-light-purple px-3">-->
<!--                  <b-icon-telephone-fill scale=".9" />-->
<!--                  Позвонить-->
<!--                </b-btn>-->
<!--              </a>-->
<!--            </b-col>-->
<!--          </div>-->

          <hr class="my-2 border-light opacity-5" />
<!--          <ul class="navbar-nav position-fixed bottom-3">-->
          <ul class="navbar-nav">
            <a :href="siteUrl" target="_blank" aria-current="page" class="nav-link active router-link-active">
              <i class="ni ni-briefcase-24 text-white" />
              <span class="nav-link-text dotted border-white">
              Сайт АНЭКС
            </span>
            </a>
            <a :href="`${siteUrl}/contact-us`" target="_blank" aria-current="page" class="nav-link active router-link-active">
              <i class="ni ni-badge text-white" />
              <span class="nav-link-text">
              Контакты
            </span>
            </a>
            <span aria-current="page" class="nav-link active router-link-active">
              <i class="ni ni-mobile-button text-white" />
              <span class="nav-link-text">
              8-921-956-67-42
            </span>
            </span>
            <a href="mailto:info@aneks.center" aria-current="page" class="nav-link active router-link-active">
              <i class="ni ni-email-83 text-white" />
              <span class="nav-link-text">
              info@aneks.center
            </span>
            </a>
          </ul>
        </template>

      </div>
    </div>
  </nav>
</template>
<script>
import NavbarToggleButton from "@/components/NavbarToggleButton";
import NotificationsDropdown from "../../layout/NotificationsDropdown";

export default {
  name: "sidebar",
  components: {
    NotificationsDropdown,
    NavbarToggleButton,
  },
  data() {
    return {
      avatar: null,
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      keywords: '',
    };
  },
  props: {
    logo: {
      type: String,
      default: "/img/brand/green.png",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    avatarUrl() {
      const avatarUrl = this.user.avatar_url;
      return this.user.avatar_id ?
          avatarUrl :
          '/img/no-avatar.png';
    },
    isManagerPanel() {
      return this.$route.path.includes('manager');
    },
    isManager() {
      const roleManager = 1;
      return this.$store.getters.user.role === roleManager;
    },

    siteUrl() {
      return process.env.VUE_APP_JOOMLA_SITE_URL;
    },

    mainUrl() {
      const roleManager = 1;
      const isManager = this.$store.getters.user.role === roleManager;
      const isManagerPanel = this.$route.path.includes('manager');
      if (!isManager) {
        return '/user/dashboard';
      } else {
        return isManagerPanel ? '/user/dashboard' : '/manager/dashboard';
      }
    }
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    async logout() {
      try {
        await this.$store.dispatch("logout");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "Oops, something went wrong!",
        });
      }
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>

<style scoped>
.navbar-brand {
  /*font-size: 30px !important;*/
}

.bg-light-purple {
  background: #8774E1;
  //box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
  //            rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
  //            rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: white;
  border: none;
}
</style>
