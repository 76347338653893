<template>
  <div>
    <h6 class="heading-small text-muted mb-4">
      Адрес
    </h6>
    <div class="pl-lg-4">
      <div class="row">
        <div class="col-md-12">
          <base-input
              alternative=""
              label="Домашний адрес"
              placeholder="Звенигородская ул., 28 лит А"
              v-model="item.address"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label class="form-control-label">Регион</label>
            <l-select dict="dict_regions" v-model="item.dict_region_id" />
          </div>
        </div>
        <div class="col-lg-4">
          <base-input
              alternative=""
              label="Страна"
              placeholder="Страна"
              v-model="item.country"
          />
        </div>
        <div class="col-lg-4">
          <base-input
              alternative=""
              label="Почтовый индекс"
              placeholder="Почтовый индекс"
              v-model="item.postcode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddressCard",
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    regions() {
      return this.$store.getters.getDict('dict_regions');
    },
  },
}
</script>

<style scoped>

</style>