<template>
  <div class="row justify-content-center">
    <div>
      <div class="card bg-white shadow border-0 mt--5 mt-md-0">
        <div class="card-body px-lg-5 py-lg-5">
<!--          <div class="text-center text-muted mb-4">-->
<!--            <small>Введите данные:</small>-->
<!--          </div>-->

          <b-row>
            <b-col md="4" sm="12" class="border-right bg-secondary">
              <div class="justify-content-center">
                <h1>Регистрация</h1>
                <p class="text-lead">
                  Введите данные в формы, чтобы зарегистрироваться.
                  Если возникли затруднения при регистрации, просим обращаться в нашу редакцию,
                  по указанным номерам:
                  8 (812) 956-67-42 <br>
                  8 (921) 856-03-61
                </p>
              </div>
              <hr class="d-block d-md-none my-3" />
            </b-col>
            <b-col class="pl-md-5">
              <form role="form" @submit.prevent="handleSubmit()">

                <b-row>
                  <b-col md="4" sm="12">
                    <a-label label="Фамилия" required label-class="mb-1" />
                    <base-input
                        alternative
                        class="input-group-alternative mb-3"
                        placeholder="Фамилия"
                        addon-left-icon="ni ni-hat-3"
                        v-model="last_name"
                    />
                    <validation-error :errors="apiValidationErrors.last_name" />
                  </b-col>
                  <b-col md="4" sm="12">
                    <a-label label="Имя" required label-class="mb-1" />
                    <base-input
                        alternative
                        class="input-group-alternative mb-3"
                        placeholder="Имя"
                        addon-left-icon="ni ni-hat-3"
                        v-model="first_name"
                    />
                    <validation-error :errors="apiValidationErrors.first_name" />
                  </b-col>
                  <b-col md="4" sm="12">
                    <a-label label="Отчество" required label-class="mb-1" />
                    <base-input
                        alternative
                        class="input-group-alternative mb-3"
                        placeholder="Отчество"
                        addon-left-icon="ni ni-hat-3"
                        v-model="middle_name"
                    />
                    <validation-error :errors="apiValidationErrors.middle_name" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6" sm="12">
                    <a-label label="Телефон" required label-class="mb-1" />
                    <base-input
                        alternative
                        v-inputmask="'+7 (999) 999-99-99'"
                        placeholder="+7 (---) --- - -- - --"
                        class="input-group-alternative mb-3"
                        addon-left-icon="ni ni-tablet-button"
                        v-model="phone"
                    >
                    </base-input>
                    <validation-error :errors="apiValidationErrors.phone" />
                  </b-col>
                  <b-col md="6" sm="12">
                    <a-label label="Email" required label-class="mb-1" />
                    <base-input
                        alternative
                        class="input-group-alternative mb-3"
                        placeholder="Email"
                        addon-left-icon="ni ni-email-83"
                        v-model="email"
                    >
                    </base-input>
                    <validation-error :errors="apiValidationErrors.email" />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6" sm="12">
                    <a-label label="Пароль" required label-class="mb-1" />
                    <base-input
                        alternative
                        class="input-group-alternative m-0 p-0"
                        placeholder="Пароль"
                        :type="!isShowPassword ? 'password' : ''"
                        addon-left-icon="ni ni-lock-circle-open"
                        addon-right-icon="ni ni-glasses-2 text-dark pointer"
                        tooltip-right-icon="Показать пароль"
                        @click-right="showPassword"
                        v-model="password"
                    />
<!--                    <safety-check :scors="scors" class="mb-2" />-->
                    <div class="text-muted font-italic mt-0 pt-0">
                      <small>
                        Минимальная длина:
                        <span
                            :class="isPasswordValid ? 'text-success' : 'text-danger'"
                            class="font-weight-700"
                        >
                          4 символа
                        </span>
                      </small>
                    </div>
                    <password
                        v-model="password"
                        :strength-meter-only="true"
                        @score="showScore"
                        :showStrengthMeter="false"
                    />
                    <validation-error :errors="apiValidationErrors.password" />
                  </b-col>
                  <b-col md="6" sm="12">
                    <a-label label="Повтор пароля" required label-class="mb-1" />
                    <base-input
                        alternative
                        class="input-group-alternative mb-0"
                        placeholder="Подтвердите пароль"
                        type="password"
                        addon-left-icon="ni ni-lock-circle-open"
                        v-model="password_confirmation"
                    />
                    <div
                        v-if="password_confirmation && password_confirmation.length > 0"
                        :class="isPasswordConfirmed ? 'text-success' : 'text-danger'"
                        class="font-italic mt-0 pt-0"
                    >
                      <small class="font-weight-700">
                        Пароли <template v-if="!isPasswordConfirmed">не</template> совпадают
                      </small>
                    </div>
                    <validation-error
                        :errors="apiValidationErrors.password_confirmation"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col md="6" sm="12">
                    <a-label label="Организация" required label-class="mb-1" />
                    <base-input
                        alternative
                        class="input-group-alternative mb-3"
                        placeholder="Школа №213"
                        addon-left-icon="ni ni-hat-3"
                        v-model="organization"
                    />
                    <validation-error :errors="apiValidationErrors.organization" />
                  </b-col>
                  <b-col md="6" sm="12">
                    <a-label label="Должность" required label-class="mb-1" />
                    <base-input
                        alternative
                        class="input-group-alternative mb-3"
                        placeholder="Методист"
                        addon-left-icon="ni ni-hat-3"
                        v-model="job_title"
                    />
                    <validation-error :errors="apiValidationErrors.job_title" />
                  </b-col>
                </b-row>

                Отправляя настоящую форму, Вы даете
                <a href="https://aneks.center/index.php/976-soglasie-pd">
                  согласие на обработку персональных данных.
                </a>
                Администрация сайта гарантирует конфиденциальность личных данных.

                <div class="row my-0">
                  <div class="col-12">
                    <div class="text-center">

                      <small class="text-danger" v-if="disabled">
                        Заполните все поля в соответствии с требованиями
                      </small><br>

                      <base-button
                          v-if="!isLoading"
                          native-type="submit"
                          class="btn-login mb-0 bg-purple border-0"
                          :disabled="disabled"
                      >
                        Регистрация
                      </base-button>

                      <base-button v-else disabled class="btn-login mt-4 mb-0 bg-purple border-0">
                        Пожалуйста, подождите...
                      </base-button>
                    </div>
                  </div>
                </div>
              </form>
            </b-col>
          </b-row>

        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <router-link to="/password/reset" class="text-light text-lg">
            <small>Забыли пароль?</small>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light text-lg">
            <small>Вход</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";
import SafetyCheck from "./Password/SafetyCheck";
import Template from "../manager/Template/Template.vue";

export default {
  name: "register",
  components: {
    ValidationError,
    Password,
    // SafetyCheck
  },
  mixins: [formMixin],
  data() {
    return {
      last_name: null,
      first_name: null,
      middle_name: null,
      phone: null,
      email: null,
      organization: null,
      job_title: null,
      password: '',
      password_confirmation: null,
      scors: "",
      isShowPassword: false,
      minPasswordLength: 4,
    };
  },

  computed: {
    isPasswordValid() {
      return this.password.length >= this.minPasswordLength;
    },
    isPasswordConfirmed() {
      return this.password === this.password_confirmation;
    },
    disabled() {
       return !this.isPasswordValid || !this.isPasswordConfirmed;
    },
  },

  methods: {
    showScore(score) {
      this.scors = score;
    },
    showPassword() {
      this.isShowPassword = !this.isShowPassword;
    },
    async handleSubmit() {
      const user = {
        data: {
          type: "token",
          attributes: {
            last_name: this.last_name,
            first_name: this.first_name,
            middle_name: this.middle_name,
            phone: this.phone,
            email: this.email,
            organization: this.organization,
            job_title: this.job_title,
            password: this.password,
            password_confirmation: this.password_confirmation,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      this.isLoading = true;
      await this.$store.dispatch("register", {user, requestOptions});
      this.isLoading = false;
    },
  },
};
</script>
<style></style>
