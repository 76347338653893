export default {
    data() {
        return {
            pageFirst: 1,
            payTypes: [
                { text: 'Через банк', value: 1 },
                { text: 'В офисе карточкой', value: 2 },
                { text: 'Онлайн', value: 3 },
                { text: 'Другое', value: 4 }
            ],
            paymentTypeIds: {
                bank: 1,
                office: 2,
                online: 3,
                other: 4,
            }
        };
    },
    computed: {
        /**
         * Все типы и подтипы услуг для l-select.
         * В качестве value передаётся alias!
         * @returns {(*&{label: *, value: *})[]}
         */
        typesAll() {
            const subtypes = this.$store.getters.getDict('dict_service_subtypes');
            const types = this.$store.getters.getDict('dict_service_types');
            let typesWoSubtypes = types.filter(item => !item.has_subtypes);
            const typesAll = [...subtypes, ...typesWoSubtypes];
            return typesAll.map((item) => ({
                ...item,
                value: item.value ?? item.alias,
                label: item.label ?? this.getSubtypeLabelWithType(item),
            }))
        },
        /**
         * Все типы и подтипы услуг для l-select вместе с пакетами услуг.
         * В качестве value передаётся alias!
         * @returns {(*&{label: *, value: *})[]}
         */
        typesExtended() {
            this.typesAll.unshift({
                value: 'service-packages',
                label: 'Пакеты услуг',
            });
            return this.typesAll;
        },
        paymentTypes() {
            return this.payTypes.map((item) => ({
                ...item,
                label: item.text
            }))
        },
        userPaymentTypes() {
            return this.paymentTypes.filter(
              item => item.value !== this.paymentTypeIds.other
            );
        },
    },
    methods: {
        /**
         * Лейбл подтипа вида 'Тип услуги - подтип'
         */
        getSubtypeLabelWithType(subtype) {
            if (subtype.dict_service_type_id) {
                const type = this.$store.getters.getDictItemById(
                  'dict_service_types',
                  subtype.dict_service_type_id
                );
                return `${type.fdv} - ${subtype.fdv}`;
            }
            return subtype.fdv;
        },
        async fetchDataWithFilters() {
            await this.fetchData(this.pageFirst);
        },
        async changePageWithFilters() {
            await this.changePage(this.pageFirst);
        },
        async clearFilters() {
            Object.keys(this.filters).forEach(key => {
                this.filters[key] = null;
            });
            await this.changePageWithFilters();
        },
    }
};
