<template>
  <b-modal
      size="lg"
      title="Редактирование профиля"
      title-class="text-lg p-2 text-center"
      body-class="mt--4"
      id="profileFormsModal"
      centered
      cancel-title="Закрыть"
      ok-title="Сохранить"
      @ok="saveItem"
  >
    <b-form-group label="Название">
      <b-form-input v-model="item.fdv" placeholder="Название" />
    </b-form-group>

    <a-label label="Тэг в Joomla (aneks.center)" />
    <l-select
        class="text-wrap"
        :disabled="joomlaTags.length < 1"
        :options="joomlaTags"
        v-model="item.joomla_tag_id"
    />

  </b-modal>
</template>

<script>
import JoomlaResource from "../../../../resources/joomla";
import DictResource from "../../../../resources/dict";

export default {
  name: "ProfileFormsModal",
  data() {
    return {
      joomlaTags: [],
    }
  },
  props: {
    item: {
      type: Object,
    },
  },
  async mounted() {
    const joomlaTags = await JoomlaResource.fetchTags();
    this.joomlaTags = joomlaTags.map(item => ({
      ...item,
      label: `${item.id}. ${item.title} (${item.alias})`,
      value: parseInt(item.id),
    }));
  },
  methods: {
    async saveItem() {
      DictResource.setUrl('dict_profiles');
      await this.validateAndSave(DictResource);
    },
  },
}
</script>

<style scoped>

</style>