import Resource from "../resource";
import axios from "axios";

export default class NotificationResource extends Resource {
    /**
     * Отмечает уведомление прочитанным
     * @param notificationId
     * @returns {Promise<any>}
     */
    static async read(notificationId) {
        const url = `${this.backendUrl}/${this.url}/${notificationId}/read`;
        const { data } = await axios.post(url, {});
        return data;
    }

    /**
     * Отмечает все новые уведомления прочитанным
     * @returns {Promise<any>}
     */
    static async readAll() {
        const url = `${this.backendUrl}/${this.url}/read-all`;
        const { data } = await axios.post(url, { all: true });
        return data;
    }

    /**
     * Получает новые уведомления
     * @param userId
     * @returns {Promise<any>}
     */
    static async checkNew(userId) {
        return await this.fetch({
            checked: false,
            user_id: userId
        });
    }
}

NotificationResource.url = 'notification';