<template>
  <b-modal
      id="reviewModal"
      size="md"
      title="Оставить отзыв"
      ok-title="Сохранить"
      cancel-title="Отмена"
      @ok="save"
      :ok-disabled="!item.text"
  >
    <p>Отзыв будет опубликован на сайте сразу после проверки оператором</p>
    <b-textarea autofocus placeholder="Отзыв" rows="5" v-model="item.text" />
  </b-modal>
</template>

<script>
import ReviewResource from "../../resources/review";

export default {
  name: "ReviewModal",
  data() {
    return {
      item: {
        text: '',
        user_id: null,
      },
    }
  },
  methods: {
    async save() {
      this.item.user_id = this.$store.getters.user.id;
      await ReviewResource.save(this.item);
      this.$notify({
        type: 'success',
        message: 'Заявка успешно отправлена!'
      })
      this.item.text = '';
    }
  },
}
</script>

<style scoped>

</style>