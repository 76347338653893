import Resource from "../resource";
import axios from "axios";

export default class JoomlaResource extends Resource {
    /**
     * Системы Joomla, используемые приложением
     * @type {{site: string, journal: string}}
     */
    static jSystems = {
        site: 'joomla_site',
        journal: 'joomla_journal'
    }

    /**
     * Фетч категорий под переданную систему в аргументах
     * систему
     * @param joomlaSystem
     * @returns {Promise<any>}
     */
    static async fetchCategories(joomlaSystem) {
        if (joomlaSystem === undefined) joomlaSystem = this.jSystems.site;
        const url = `${this.backendUrl}/${this.url}/categories`;
        const params = {
            joomla_system: joomlaSystem
        }
        const { data } = await axios.post(url, { params });
        return data;
    }

    /**
     * Фетч тегов
     * систему
     * @returns {Promise<any>}
     */
    static async fetchTags() {
        const url = `${this.backendUrl}/${this.url}/tags`;
        const { data } = await axios.post(url, {});
        return data;
    }

    /**
     * Фетч рубрик публикаций под переданную систему в аргументах
     * систему
     * @param joomlaSystem
     * @returns {Promise<any>}
     */
    static async fetchPublicationCategories(joomlaSystem) {
        const url = `${this.backendUrl}/${this.url}/publication-categories`;
        const { data } = await axios.post(url, {});
        return data;
    }
}

JoomlaResource.url = 'joomla';