<template>
  <div class="text-muted font-italic mt-0 pt-0">
    <small>
      Надёжность пароля
      <template v-if="scors === 0">
        <span class="text-danger font-weight-700"> очень слабый </span>
      </template>

      <template v-if="scors === 1">
        <span class="text-danger font-weight-700"> слабый </span>
      </template>

      <template v-if="scors === 2">
        <span class="text-warning font-weight-700"> средний </span>
      </template>

      <template v-if="scors === 3">
        <span class="text-success font-weight-700"> надёжный </span>
      </template>

      <template v-if="scors === 4">
        <span class="text-success font-weight-700">
          очень надёжный
        </span>
      </template>
    </small>
  </div>
</template>

<script>
export default {
  name: "SafetyCheck",
  props: {
    scors: {}
  }
}
</script>

<style scoped>

</style>