<template>
  <div>
    <div class="table-responsive px-lg-5">
      <base-table
          :loading="loading"
          class="table align-items-center table-flush"
          tbody-classes="list"
          :data="items"
          :thead-classes="'thead-light'"
      >
        <template slot="columns">
<!--          <th class="text-center">№</th>-->
          <th class="text-center col-1">Заявка</th>
          <th class="text-center">Клиент</th>
          <th class="text-center">Услуга</th>
          <th class="text-center col-2">Тип оплаты</th>
          <th class="text-center col-1">Сумма</th>
          <th class="text-center">Дата</th>
        </template>

        <template slot="filters">
<!--          <th></th>-->
          <th class="text-center">
            <b-input v-model="filters.entry_number" placeholder="№ заявки" @change="changePageWithFilters" />
          </th>
          <th class="text-center">
            <b-input v-model="filters.fio" placeholder="ФИО" @input="changePageWithFilters" />
          </th>
          <th class="text-center">
            <l-select
                :options="typesAll"
                placeholder-text="..."
                v-model="filters.serviceType"
                @input="changePageWithFilters"
                class="overflow-hidden mb-2"
                add-default-option
            />
            <b-input v-model="filters.serviceTitle" placeholder="Заголовок" @input="changePageWithFilters" />
          </th>
          <th class="text-center">
            <l-select
                :options="payTypes"
                placeholder-text="..."
                v-model="filters.payType"
                @input="changePageWithFilters"
                class="overflow-hidden mb-2"
                add-default-option
            />
            <b-input v-model="filters.uuid" placeholder="UUID" @change="changePageWithFilters" />
          </th>
          <th>
            <b-input v-model="filters.sum" placeholder="Сумма" @change="changePageWithFilters" />
          </th>
          <th></th>
        </template>

        <template slot-scope="{ row }" v-if="row.entry && row.entry.service">
<!--          <td class="text-center">-->
<!--            {{ row.id }}-->
<!--          </td>-->
          <td class="text-center">
            {{ row.entry.number }}
          </td>
          <td class="col-2 text-wrap">
            {{ row.entry.user.fio }}
          </td>
          <td class="col-3 text-wrap">
            <template v-if="row.entries.length > 1">
              <i>Пакет услуг</i>
            </template>
            <template v-else>
              {{ row.entry.service.title }}
            </template>
          </td>
          <td class="text-wrap text-center">
            {{ row.entry.payTypeLabel }} <br>
            <a :href="`https://yookassa.ru/my/payments?search=${row.uuid}`" target="_blank" v-if="row.uuid">
              {{ row.uuid }}
            </a>
          </td>
          <td class="text-center">
            <b>{{ row.sum }} ₽</b>
          </td>
          <td class="text-center text-wrap">
            {{ row.created_at_formatted }}
          </td>
        </template>
      </base-table>
    </div>

    <div
        v-if="itemsPagination.total_pages > 1"
        class="card-footer d-flex justify-content-end"
    >
      <base-pagination
          :page-count="itemsPagination.total_pages"
          :value="itemsPagination.current_page"
          @step="changePage"
      />

    </div>
  </div>
</template>

<script>
export default {
  name: "PaymentList",
  data() {
    return {
      filters: {
        entry_number: null,
        fio: null,
        serviceType: null,
        serviceTitle: null,
        sum: null,
        payType: null,
      },
      payTypes: [
        { value: 1, label: 'Банк' },
        { value: 2, label: 'Офис' },
        { value: 3, label: 'Онлайн' },
        { value: 4, label: 'Другое' },
      ],
    };
  },
  props: {
    items: {},
    itemsPagination: {},
    loading: {},
  },
  methods: {
    changePage(page) {
      if (page === undefined) page = 1;
      this.$emit('on-update', page, this.filters);
    }
  }
}
</script>

<style scoped>

</style>