<template>
  <module
      :subtitle="$store.getters.user.fio"
      title="С чего начать?"
  >
      <router-link to="/user/profile" v-if="!$store.getters.user.profiles">
        <b-alert show variant="danger">
          Заполните информацию о себе в профиле!
        </b-alert>
      </router-link>

      <b-row>

        <b-col cols="6" xl="4" class="mb-3">
          <router-link to="/user/service">
            <b-btn variant="primary" class="btn btn-square-md">
              <h3><i class="ni ni-archive-2" /></h3>
              1. Выберите услугу
            </b-btn>
          </router-link>
        </b-col>

        <b-col cols="6" xl="4" class="mb-3">
          <router-link to="/user/service-package">
            <b-btn variant="primary" class="bg-gradient-purple border-0 btn-square-md text-white">
              <h3><i class="ni ni-archive-2" /></h3>
              ... или пакет услуг
            </b-btn>
          </router-link>
        </b-col>

        <b-col cols="6" xl="4" class="mb-3">
          <router-link to="/user/entry">
            <b-btn variant="info" class="btn btn-square-md">
              <h3><i class="ni ni-send" /></h3>
              2. Оплатите заявку
            </b-btn>
          </router-link>
        </b-col>

        <b-col cols="6" xl="4" class="mb-3">
          <router-link to="/user/entry">
            <b-btn variant="default" class="btn btn-square-md">
              <h3><i class="ni ni-hat-3" /></h3>
              3. Посетите услугу
            </b-btn>
          </router-link>
        </b-col>

        <b-col cols="6" xl="4" class="mb-3">
          <router-link to="/user/entry">
            <b-btn variant="warning" class="btn btn-square-md">
              <h3><i class="ni ni-tag" /></h3>
              4. Добавьте тезисы
            </b-btn>
          </router-link>
        </b-col>

        <b-col cols="6" xl="4" class="mb-3">
          <router-link to="/user/entry">
            <b-btn variant="success" class="btn btn-square-md">
              <h3><i class="ni ni-paper-diploma" /></h3>
              5. Получите сертификат
            </b-btn>
          </router-link>
        </b-col>

      </b-row>
  </module>
</template>
<script>
import Module from "./Module";

export default {
  name: "Instructions",
  components: {
    Module
  },
}
</script>

<style scoped>
i {
  color: white
}

.btn-square-md {
  min-width: 100% !important;
  min-height: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  text-align: center;
  padding: .8rem;
  font-size: 12px;
}
</style>