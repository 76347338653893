import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  state: {
    statistics: {},
  },

  getters: {
    getStatisticsByName: (state) => (modelName) => state.statistics[modelName] || [],
    statistics: state => state.statistics
  },

  mutations: {
    SET_STATISTICS: (state, items) => {
      state.statistics = items;
    },
  },

  actions: {
    initStatistics({commit, dispatch}) {
      dispatch('statistics').then(response => {
        commit('SET_STATISTICS', response);
      });
    },

    // TODO Рефакторинг
    async statistics({commit, dispatch}) {
      const entry = await dispatch('entry');
      const payment = await dispatch('payment');
      const user = await dispatch('user');
      const publication = await dispatch('publication');
      const publicationPending = await dispatch('publicationPending');
      return {
        entry: entry,
        payment: payment,
        user: user,
        publication: publication,
        publicationPending: publicationPending
      }
    },

    async entry({commit, dispatch}) {
      const { data } = await axios.get(`${url}/api/entry/statistics`);
      return data;
    },

    async payment({commit, dispatch}) {
      const { data } = await axios.get(`${url}/api/payment/statistics`);
      return data;
    },

    async user({commit, dispatch}) {
      const { data } = await axios.get(`${url}/api/user/statistics`);
      return data;
    },

    async publication({commit, dispatch}) {
      const { data } = await axios.get(`${url}/api/publication/statistics`);
      return data;
    },

    async publicationPending({commit, dispatch}) {
      const { data } = await axios.get(`${url}/api/publication/count-pending`);
      return data;
    },
  },
}