<template>
  <div>
    <div class="bg-white rounded shadow-sm px-4 restaurant-detailed-ratings-and-reviews">
      <div>
        <hr class="mb-4" />
        <a-label label-class="mb-3" label="Автор" />
        <l-select :options="users" v-model="review.user_id" @search-change="fetchUsers" />
        <a-label label-class="my-3" label="Текст" />
        <b-textarea autofocus placeholder="Отзыв" rows="5" v-model="review.text" />
        <b-btn pill class="px-3 py-1 mt-3 float-right" variant="primary" @click="save(review)">
          Опубликовать
        </b-btn>
        <br>
        <hr class="mt-5" />
      </div>
      <template v-for="item in items">
        <div class="reviews-members py-4" :key="`review-${item.id}`">
          <div class="media">
            <img :src="avatarUrl(item.user)" class="mr-3 rounded-pill">
            <div class="media-body">
              <div class="reviews-members-header">
                <h4 class="mb-1">
                  {{ item.user.fio }}
                </h4>
                <p class="text-gray">{{ item.created_at_formatted }}</p>
              </div>
              <div class="reviews-members-body">
                <b-textarea v-if="isEditing(item)" v-model="item.text" rows="6  " class="mb-3"/>
                <p v-else> {{ item.text }} </p>
              </div>
              <div class="reviews-members-footer" v-if="item.status === statusPending">
                <template v-if="!isEditing(item)">
                  <b-btn pill variant="outline-success" class="px-3 py-1" @click="accept(item)">
                    <b-icon-check font-scale="1.5" />
                  </b-btn>
                  <b-btn pill variant="outline-danger" class="px-3 py-1" @click="decline(item)">
                    <b-icon-x font-scale="1.5" />
                  </b-btn>
                  <b-btn pill variant="outline-primary" class="px-3 py-1 float-right" @click="edit(item)">
                    <b-icon-pencil-square font-scale="1.5" />
                  </b-btn>
                </template>
                <template v-else>
                  <b-btn pill variant="success" class="px-3 py-1" @click="save(item)" v-b-tooltip="'Сохранить'">
                    <b-icon-check font-scale="1.5" />
                  </b-btn>
                  <b-btn pill variant="danger" class="px-3 py-1" @click="stopEditing()" v-b-tooltip="'Отменить'">
                    <b-icon-x font-scale="1.5" />
                  </b-btn>
                </template>
              </div>
            </div>
          </div>
        </div>
        <hr :key="`hr-review-${item.id}`">
      </template>
    </div>
  </div>
</template>

<script>
import ReviewResource from "../../../../resources/review";
import UserResource from "../../../../resources/user";

export default {
  name: "ReviewList",
  data() {
    return {
      statusPending: 0,
      editingId: null,
      users: [],
      review: {
        text: '',
        user_id: null,
      },
    }
  },
  props: {
    items: {},
    itemsPagination: {},
    loading: {},
  },
  methods: {
    /**
     * Поиск по пользователям внутри l-select
     * @param search
     * @returns {Promise<void>}
     */
    async fetchUsers(search) {
      if (search.length > 0) {
        this.users = await UserResource.fetch({
          'fio': search,
        }, 1, 5);
        this.users = this.users.map((elm) => ({
          ...elm,
          value: elm.id,
          label: elm.fio,
        }));
      }
    },
    async accept(item) {
      await ReviewResource.accept(item.id);
      this.$emit('on-update');
      this.$notify({
        type: "success",
        message: "Успешно опубликовано!",
      });
    },
    async decline(item) {
      await ReviewResource.delete(item.id);
      this.$emit('on-update');
      this.$notify({
        type: "success",
        message: "Успешно удалено!",
      });
    },
    changePage(page) {
      if (page === undefined) page = 1;
      this.$emit('on-update', page);
    },
    avatarUrl(user) {
      const avatarUrl = user.avatar_url;
      return user.avatar_url ?
          avatarUrl :
          '/img/no-avatar.png';
    },
    isEditing(item) {
      return item.id === this.editingId;
    },
    edit(item) {
      this.editingId = item.id;
    },
    async save(item) {
      await this.validateAndSave(ReviewResource, item, false);
      this.stopEditing();
    },
    stopEditing() {
      this.editingId = null;
      this.$emit('on-update');
    },
  }
}
</script>

<style scoped>
.restaurant-detailed-ratings-and-reviews hr {
  margin: 0 -24px;
}
.graph-star-rating-header .star-rating {
  font-size: 17px;
}
.reviews-members .media .mr-3 {
  width: 56px;
  height: 56px;
  object-fit: cover;
}

</style>