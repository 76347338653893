<template>
  <base-dropdown
      menu-on-right
      class="nav-item"
      tag="li"
      title-tag="a"
      menu-classes="dropdown-menu dropdown-menu-right py-0 overflow-hidden dropdown-notifications"
  >
    <a
        class="nav-link text-white"
        href="#"
        slot="title"
    >
      <i class="ni ni-bell-55" :class="{'unread': newNotifications.length > 0}" />
    </a>

    <notifications-dropdown />
  </base-dropdown>
</template>

<script>
import NotificationsDropdown from "../NotificationsDropdown";

export default {
  name: "Notifications",
  components: {
    NotificationsDropdown,
  },
  async created() {
    // TODO Уведомления
    if (!this.isManager) {
      // await this.checkNotifications(true);
      // const interval = setInterval(this.checkNotifications, 10000, true);
      // this.$on('hook:beforeDestroy', () => clearInterval(interval));
    }
  },
}
</script>

<style>
.unread {
  animation: blink 2s linear infinite;
}

.nav-dropdown-notification p {
  font-size: 0.8rem !important;
}

@keyframes blink {
  50% { opacity: 0; }
  100% { opacity: 1 }
}

.dropdown-item:hover {
  background: none !important;
  color: var(--dark) !important;
}
</style>