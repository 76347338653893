<template>
  <module
      title="Добро пожаловать!"
      :subtitle="currentUser.role_label"
  >
    <template v-slot:buttons>
      <div @click="emitUpload" class="mr-3">
        <a href="#">
          <img :src="avatar" class="rounded-circle avatar-dashboard" />
        </a>
      </div>
      <b-form-file class="d-none" v-model="file" ref="avatarUpload" id="avatarUpload" />
    </template>

    <div class="text-center">
      <div class="mb-3">
        <b-badge
            pill
            class="m-1 text-white"
            :class="`bg-${profile.color}`"
            v-for="profile in currentUser.profiles"
            :key="profile.id"
            variant="info"
            size="xl"
        >
          {{ profile.fdv }}
        </b-badge>
      </div>

      <router-link
          v-if="!currentUser.profiles || currentUser.profiles.length < 1"
          to="/user/profile"
      >
        <b-alert show variant="danger">
          Заполните профили!
        </b-alert>
      </router-link>

    </div>
  </module>
</template>
<script>
import UserResource from "../../../../resources/user";
import Module from "./Module";

export default {
  name: "User",
  components: {
    Module
  },
  data() {
    return {
      model: {
        email: "",
        fio: "",
        address: "",
        region: "",
        country: "",
        postcode: "",
        phone: "",
        organization: "",
        learned_from_us: "",
        password: "",
        note: "",
        dict_region_id: null,
        fdv: null,
        profiles: [],
      },
      file: null,
    };
  },
  props: {
    isFromProfile: {
      type: Boolean,
      default() {
        return true;
      }
    },
    avatarClass: {},
    background: {},
    textColor: {},
    user: {
      type: Object
    }
  },
  watch: {
    file: {
      async handler() {
        await this.uploadFile();
      }
    }
  },
  computed: {
    currentUser() {
      if (this.isFromProfile) {
        return this.$store.getters.user;
      } else {
        return this.user;
      }
    },
    avatar() {
      const avatarUrl = this.currentUser.avatar_url;
      return this.currentUser.avatar_id ?
          avatarUrl :
          '/img/no-avatar.png';
    },
  },
  mounted() {
    this.model = this.currentUser;
  },
  methods: {
    async uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file);
      await UserResource.uploadAvatar(formData, this.$store.getters.user.id);
      await this.$store.dispatch('me');
      this.notifyImgUploaded();
      window.reload();
    },
    emitUpload() {
      this.$refs.avatarUpload.$refs.input.click();
    },
    notifyImgUploaded() {
      this.$notify({
        message: 'Изображение профиля успешно изменено!',
        type: 'success'
      })
    },
  },
}
</script>

<style scoped>
.avatar-dashboard {
  width: 42px !important;
  height: 42px !important;
}
</style>