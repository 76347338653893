<template>
  <module
      title="Уведомления"
      :subtitle="`Новых уведомлений: ${newNotifications.length}`"
      class="module-notification"
  >
    <!--  ОЧИСТКА УВЕДОМЛЕНИЙ  -->
    <template v-slot:buttons v-if="newNotifications.length > 0">
      <b-btn class="mr-4" variant="primary" size="sm" @click="readAll">
        Очистить
      </b-btn>
    </template>

    <!--  ЗАГРУЗКА  -->
    <ul class="list-group list-group-flush list" v-if="isLoading">
      <li
          v-for="item in [0, 1]"
          class="hide-markers px-0 mb-3 ml--1 pointer notification"
          :key="`skeleton-${item}`"
      >
        <div class="row align-items-center">
          <div class="col-auto">
            <div
                class="icon icon-shape text-white rounded-circle text-lg p-3 bg-light opacity-5"
            >
              <b-skeleton />
            </div>
          </div>
          <div
              class="col mr-4 text-xs p-3 rounded notification-text"
          >
            <b-skeleton width="90%" class="my-2" />
            <b-skeleton width="70%" class="my-2" />
            <b-skeleton width="80%" class="my-2" />
<!--            <b-skeleton width="90%" />-->
<!--            <b-skeleton width="80%" />-->
          </div>
        </div>
      </li>
    </ul>

    <!--  УВЕДОМЛЕНИЯ  -->
    <ul class="list-group list-group-flush list" v-else-if="newNotifications.length > 0 && !isLoading">
      <li
          v-for="notification in newNotifications"
          class="hide-markers px-0 mb-3 ml--1 pointer notification"
          :key="notification.id"
          @click="readNotification(notification)"
      >
        <div class="row align-items-center">
          <div class="col-auto">
            <div
                :class="`bg-${notification.color}`"
                class="icon icon-shape text-white rounded-circle text-lg p-3"
            >
              <i :class="notification.icon" />
            </div>
          </div>
          <div
              class="col mr-4 text-xs p-3 rounded notification-text"
              :style="`background-color: rgb(${textColorByBackground(notification.color)} / .5)`"
          >
            {{ notification.created_at_formatted }}
            <span class="text-xs" v-html="notification.text" />
          </div>
        </div>
      </li>
    </ul>
    <div class="text-center" v-else>
      Нет новых уведомлений
    </div>
  </module>
</template>

<script>
import Module from "./Module";
import NotificationResource from "../../../../resources/notification";

export default {
  name: "Notification",
  components: {
    Module
  },
  // TODO Уведомления
  async mounted() {
    this.isLoading = true;
    await this.checkNotifications();
    this.isLoading = false;
  },
  methods: {
    async readNotification(item) {
      if (!item.checked)
        await NotificationResource.read(item.id);
      await this.$router.push(item.url);
    },
    textColorByBackground(backgroundColor) {
      switch (backgroundColor) {
        case 'gradient-danger':
        case 'danger':
          return '255 162 162';

        case 'gradient-warning':
        case 'warning':
          return '255 225 162';

        case 'gradient-cyan':
        case 'gradient-success':
        case 'cyan':
        case 'success':
          return '134 255 225';

        case 'gradient-purple':
        case 'purple':
          return '195 162 255';

        default:
          return '162 209 255';
      }
    },
    async readAll() {
      await NotificationResource.readAll();
      this.newNotifications = [];
    }
  }
}
</script>

<style>
.module-notification p {
  font-size: small !important;
  color: var(--dark);
  font-weight: 500;
  margin-right: 1rem;
}

.module-notification .icon {
  width: 0;
  height: 0;
}

.module-notification .ni {
  font-size: small !important;
}

.module-notification .notification-header {
  padding: 1.0rem !important;
}

.module-notification .notification-header-margin {
  margin-bottom: 0rem !important;
}

.hide-markers {
  list-style-type: none !important;
}

.notification p {
  margin-bottom: 0px !important;
}
</style>