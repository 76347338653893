<template>
  <span>
    <span class="text-success">Jo</span>
    <span class="text-warning">o</span>
    <span class="text-primary">m</span>
    <span class="text-danger">la</span>
  </span>
</template>

<script>
export default {
  name: "JoomlaLogo"
}
</script>

<style scoped>

</style>