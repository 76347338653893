<template>
  <div>
    <div class="table-responsive">
      <base-table
          :loading="loading"
          class="table align-items-center table-flush "
          tbody-classes="list"
          :data="items"
          :thead-classes="'thead-light'"
      >
        <template slot="columns">
          <th class="text-center col-1">№ Заявки</th>
          <th class="text-center">Клиент</th>
          <th class="text-center col-2" v-if="!hideService">Услуга</th>
          <th class="text-center">Заголовок</th>
          <th class="text-center col-1">Статус</th>
          <th class="text-center col-1">Оплачено</th>
          <th class="text-center">Дата создания</th>
          <th class="text-center"></th>
          <th class="text-center"></th>
        </template>

        <template slot="filters">
          <th>
            <b-input v-model="filters.entryNumber" placeholder="№ заявки" @change="changePageWithFilters" />
          </th>
          <th>
            <b-input v-model="filters.fio" placeholder="ФИО" @change="changePageWithFilters" />
          </th>
          <th v-if="!hideService">
            <l-select
                :options="services"
                placeholder-text="..."
                v-model="filters.serviceId"
                @input="changePageWithFilters"
                class="overflow-hidden"
                add-default-option
            />
          </th>
          <th>
            <b-input v-model="filters.title" placeholder="Заголовок" @change="changePageWithFilters" />
          </th>
          <th>
            <l-select
                :options="statuses"
                placeholder-text="..."
                v-model="filters.status"
                @input="changePageWithFilters"
                class="overflow-hidden"
                add-default-option
            />
          </th>
          <th>
            <l-select
                :options="entryStatuses"
                placeholder-text="..."
                v-model="filters.isPaid"
                @input="changePageWithFilters"
                class="overflow-hidden"
                add-default-option
            />
          </th>
          <th></th>
          <th></th>
          <th></th>
        </template>

        <template slot-scope="{ row }" v-if="row.entries.length">
          <td class="text-center">
            <template v-for="entry in row.entries">
              {{ entry.number }} <br :key="entry.id">
            </template>
          </td>
          <td class="col-2 text-wrap">
            <div v-for="entry in row.entries" :key="'fio' + entry.id">
              <b-icon-person-fill /> {{ entry.user.fio }}
            </div>
          </td>
          <td class="col-1 text-wrap" v-if="!hideService">
            <template v-if="row.service">
              {{ row.service.title }}
            </template>
          </td>
          <td class="text-wrap" v-if="!hideService">
            <b> {{ row.title }} </b> <br>
            <a :href="row.url" target="_blank" v-if="row.url">
              <b-icon-link45deg />
              {{ shortUrl(row) }}
            </a>
          </td>
          <td>
            <h4><b-badge :variant="row.statusColor">
              {{ row.statusLabel }}
            </b-badge></h4>
          </td>
          <td class="text-center">
            <div v-for="entry in row.entries" class="d-flex" :key="entry.id">
              <h4 :class="`text-${entry.statusColor}`">
                {{ entry.statusIcon }}
              </h4>
              <span class="mt-1 ml-1">{{ entry.price }} ₽</span>
            </div>
          </td>
          <td class="text-wrap text-center col-1">
            {{ row.created_at_formatted }}
          </td>
          <td>
            <b-btn variant="outline-primary" @click="viewItem(row)" v-b-tooltip="'Просмотреть'">
              <b-icon-eye-fill />
            </b-btn>
<!--            <b-btn tag="a" variant="outline-danger" @click="remove(row)" v-b-tooltip="'Отклонить'">-->
<!--              <b-icon-x scale="1.2"/>-->
<!--            </b-btn>-->
          </td>
          <td>
            <b-checkbox
                @input="$emit('collect', row)"
                :checked="selectedItems.includes(row.id)"
            />
          </td>
        </template>
      </base-table>
    </div>

    <publication-view-modal
        :publication="selected"
        @on-update="changePage"
    />

    <div
        v-if="itemsPagination.total_pages > 1"
        class="card-footer d-flex justify-content-end"
    >
      <base-pagination
          size=""
          :page-count="itemsPagination.total_pages"
          :value="itemsPagination.current_page"
          @step="changePage"
      />

    </div>
  </div>
</template>

<script>
import ServiceResource from "../../../resources/service";
import PublicationResource from "../../../resources/publication";
import PublicationViewModal from "./PublicationViewModal";

export default {
  name: "PublicationList",
  components: {
    PublicationViewModal
  },
  data() {
    return {
      selected: {
        text: ''
      },
      filters: {
        title: null,
        entryNumber: null,
        fio: null,
        serviceId: null,
        status: null,
        entryStatus: null,
        isPaid: null,
      },
      services: [],
      entryStatuses: [
        { value: true, label: 'Да' },
        { value: false, label: 'Нет' },
      ],
      statuses: [
        { value: 1, label: 'Ожидает проверки' },
        { value: 2, label: 'Одобрена' },
        { value: 3, label: 'Требует доработок' },
        { value: 5, label: 'Взято в журнал' },
        { value: 4, label: 'Опубликована' },
        { value: 0, label: 'Отклонена' },
      ],
    }
  },
  props: {
    items: {
      type: Array,
    },
    selectedItems: {
      type: Array,
    },
    itemsPagination: {
      type: Object,
    },
    hideService: {
      type: Boolean,
      default() {
        return false;
      }
    },
    loading: {
      type: Boolean,
    },
  },
  async mounted() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.services = await ServiceResource.fetch({
        isPublication: true
      });
      this.services = this.services.map((elm) => ({
        ...elm,
        value: elm.id,
        label: elm.title,
      }));
    },
    async remove(item) {
      await this.deleteItem(PublicationResource, item.id);
      this.$emit('on-update', this.itemsPagination.current_page);
    },
    viewItem(item) {
      this.selected = item;
      this.$bvModal.show('publicationViewModal' );
    },
    changePage(page) {
      if (page === undefined) page = 1;
      this.$emit('on-update', page, this.filters);
    },
    shortUrl(row) {
      if (!row.url) return null;
      const length = 40;
      const url = row.url.substring(0, length);
      return (row.url.length > length) ? `${url}...` : url;
    }
  },
}
</script>

<style scoped>

</style>