import Resource from "../resource";
import axios from "axios";

export default class ServicePackageResource extends Resource {
    static storeServices(params, packageId) {
        const url = `${this.backendUrl}/${this.url}/store-services/${packageId}`;
        const { data } = axios.post(url, params);
        return data;
    }

    static async pin(packageId) {
        const url = `${this.backendUrl}/${this.url}/${packageId}/pin`;
        const { data } = await axios.post(url, {});
        return data;
    }

    static async enroll(packageId, params) {
        const url = `${this.backendUrl}/${this.url}/${packageId}/enroll`;
        const { data } = await axios.post(url, params);
        return data;
    }
}

ServicePackageResource.url = 'service-package';