<template>
  <b-modal hide-footer id="userEditModal" size="xl" :title="title">
    <user-info
        :isNew="isNew"
        :user="user"
        :is-from-profile="false"
        @on-update="$emit('on-update')"
    />
  </b-modal>
</template>

<script>
import UserInfo from "../../../shared/User/UserInfo";
export default {
  name: "UserEditModal",
  components: {
    UserInfo
    // UserInfo
  },
  props: {
    isNew: {
      type: Boolean,
    },
    user: {
      type: Object,
    }
  },
  computed: {
    title() {
      if (this.isNew) {
        return 'Создание пользователя';
      }
      else {
        return "Редактирование";
      }
    }
  }
  }
</script>

<style scoped>

</style>