<template>
  <div class="card-body">
    <div class="row icon-examples">
      <div
          class="col-lg-6"
          v-for="(item) in [0, 1]"
          :key="`skeleton-${item}`"
      >
        <button
            type="button"
            class="btn-icon-clipboard"
            data-clipboard-text="air-baloon"
        >
          <b-row>
            <b-skeleton
                type="avatar"
                width="30px"
                height="30px"
                class="ml-2"
            />
            <b-col cols="6">
              <b-skeleton width="75%" />
              <b-skeleton width="85%" />
              <b-skeleton width="80%" />
              <b-skeleton width="70%" />
            </b-col>
            <b-col class="ml-4">
              <b-row>
                <b-skeleton width="30%" height="30px" class="mr-3" />
                <b-skeleton width="30%" height="30px" />
              </b-row>
            </b-col>
          </b-row>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceLoader"
}
</script>

<style scoped>

</style>