<template>
  <module
      bg-header="purple"
      color-header="white"
  >
    <template v-slot:header>
      <b-skeleton width="80%" class="mb-1" />
      <b-skeleton width="90%" />
    </template>

    <b-skeleton width="90%" class="mt-3"/>
    <b-skeleton class="mt-3"/>
    <b-skeleton width="70%" class="mt-3"/>
    <b-list-group flush>
      <b-list-group-item/>
      <b-list-group-item>
        <b-skeleton class="center" width="50%"/>
      </b-list-group-item>
      <b-list-group-item>
        <b-skeleton class="center" width="80%"/>
      </b-list-group-item>
      <b-list-group-item>
        <b-skeleton class="center" width="70%"/>
      </b-list-group-item>
    </b-list-group>

  </module>
</template>

<script>
import Module from "./Module";

export default {
  name: "ServicePackageSkeleton",
  components: {
    Module,
  },
}
</script>

<style scoped>

</style>