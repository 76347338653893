import axios from "axios";

const url = process.env.VUE_APP_API_BASE_URL;

export default {
  state: {
    dicts: {},
  },

  getters: {
    getDict: (state) => (dictName) => state.dicts[dictName] || [],
    getDictItemById: (state) => (dictName, itemId) =>
        state.dicts[dictName].filter(elm => itemId === elm.id)[0] || [],
    getDictItemsByIds: (state) => (dictName, arrayIds) =>
        state.dicts[dictName].filter( el => arrayIds.indexOf( el.id ) > -1 ) || [],
    dicts: state => state.dicts
  },

  mutations: {
    SET_DICTS: (state, payload) => {
      state.dicts = {...payload};
    },
  },

  actions: {
    initDicts({commit, dispatch}, dictNames) {
      dispatch('dicts', dictNames).then(response => {
        commit('SET_DICTS', response);
      });
    },

    async dicts({commit}, dictNames) {
      let data = {};
      for (const dictName of dictNames) {
        let dictData = await axios.get(`${url}/api/dictionaries/${dictName}/index`);
        data[dictName] = dictData.data;
        data[dictName].sort(byField('id'));
      }

      function byField(field) {
        return (a, b) => a[field] > b[field] ? 1 : -1;
      }

      return data;
    },
  },
}