<template>
  <div class="col-xl-4" @click="$emit('on-click')">
    <card :header-classes="`bg-${bgHeader}`" class="border-0">
      <div slot="header" class="row align-items-center">
        <div class="col">
          <h6 class="text-uppercase text-muted ls-1 mb-1" :class="`text-${colorHeader}`">
            {{ subtitle }}
          </h6>
          <h5 class="h3 mb-0" :class="`text-${colorHeader}`">
            <slot name="header">
              {{ title }}
            </slot>
          </h5>
        </div>
        <slot name="buttons" />
      </div>

      <slot>

      </slot>

      <div slot="footer" v-if="!hideFooter">
        <slot name="footer">
        </slot>
      </div>


    </card>
  </div>
</template>

<script>
export default {
  name: "Module",
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    bgHeader: {
      type: String
    },
    colorHeader: {
      type: String
    },
    hideFooter: {
      type: Boolean,
      default: true,
    },
  }
}
</script>

<style scoped>

</style>