<template>
  <b-modal
      centered
      hide-footer
      id="templateModal"
      size="xl"
      modal-class="xxl"
      title="Шаблон"
  >
    <b-row>
      <b-col cols="4">
        <a-label class="text-center" :label="'Доступные макросы'" />
        <macros-list
            :categories="availableCategories"
            description-next-line
            btn-macros-class="p-2"
        />
      </b-col>
      <b-col>
        <b-form-group>
          <a-label :label="'Тип'" required />
          <l-select v-model="item.dict_type_id" placeholder="Тип" dict="dict_template_types" />
          <validation-error :errors="apiValidationErrors.dict_type_id" />
        </b-form-group>

        <b-form-group>
          <a-label :label="'Название'" required />
          <b-form-input v-model="item.title" placeholder="Название" />
          <validation-error :errors="apiValidationErrors.title" />
        </b-form-group>

        <b-form-group>
          <a-label :label="'Контент'" required />
          <template v-if="isFileAllowed">
            <b-form-file
                v-model="item.file"
                placeholder="Выберите файл шаблона или перетащите его курсором..."
                drop-placeholder="Перетащите файл сюда..."
            />

            <a-file :file="item.file" hide-delete-btn />

          </template>
          <template v-else>

            <template v-if="isRawHtmlAllowed">
              <b-row>
                <b-col cols="2" class="position-sticky">
                  <b-form-group>
                    <b-tabs size="lg" v-model="isHtml" pills>
                      <b-tab
                          title-link-class="mt-2"
                          class="float-right"
                          :value="type.value"
                          :title="type.label"
                          v-for="type in contentTypes"
                          :key="type.value"
                      />
                    </b-tabs>
                  </b-form-group>
                </b-col>
                <b-col>
                  <template v-if="isHtml">
                    <div v-html="item.content" />
                  </template>
                  <template v-else>
                    <b-textarea v-model="item.content" rows="20" />
                  </template>
                  <validation-error :errors="apiValidationErrors.content" />
                </b-col>
              </b-row>
            </template>
            <template v-else>
              <ckeditor :editor="ckEditor" @ready="onReady" v-model="item.content" :config="editorConfig" />
            </template>

          </template>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="my-4" />

    <div class="text-right">
      <b-button variant="primary" pill @click="save">
        Сохранить
      </b-button>
    </div>

  </b-modal>
</template>

<script>
import TemplateResource from "../../../../resources/template";
import MacrosList from "../Macros/MacrosList";

export default {
  name: "TemplateModal",
  components: {
    MacrosList,
  },
  data() {
    return {
      availableCategories: [],
      templateTypes: {
        certificate: 1,
        program: 2,
        regList: 3,
        page: 4,
        notification: 5,
      },
      isHtml: true,
      contentTypes: [
        { value: true, label: 'HTML' },
        { value: false, label: 'Просмотр' },
      ],
    }
  },
  props: {
    item: {
      type: Object,
      default: () => { return {} }
    },
  },
  computed: {
    isFileAllowed() {
      return this.typesWithFile.indexOf(this.item.dict_type_id) !== -1;
    },
    isRawHtmlAllowed() {
      return this.typesWithRawHtml.indexOf(this.item.dict_type_id) !== -1;
    },
    typesWithFile() {
      return [
          this.templateTypes.certificate,
      ];
    },
    typesWithRawHtml() {
      return [
        this.templateTypes.program,
        this.templateTypes.regList,
      ];
    },
  },
  watch: {
    'item.dict_type_id': {
      handler(itemId) {
        const type = this.$store.getters.getDictItemById(
            'dict_template_types',
            itemId
        )
        this.availableCategories = type.available_categories;
      }
    }
  },
  methods: {
    save() {
      let formData = new FormData();
      if (this.item.file !== undefined)
        formData.append('file', this.item.file);
      Object.keys(this.item).forEach(key => {
        formData.append(key, this.item[key]);
      });
      this.validateAndSave(TemplateResource, formData);
      this.$emit('on-update', this.item.dict_type_id);
    },
  },
}
</script>

<style scoped>

</style>