<template>
  <div>
    <h6 class="heading-small text-muted mb-4">
      Основная информация
    </h6>
    <div class="pl-lg-4">
      <b-row>
        <b-form-group class="col-4">
          <a-label label="Фамилия" required />
          <b-form-input :disabled="!isManager" placeholder="Фамилия" v-model="item.last_name" />
          <validation-error :errors="apiValidationErrors.last_name" />
        </b-form-group>
        <b-form-group class="col-4">
          <a-label label="Имя" required />
          <b-form-input :disabled="!isManager" placeholder="Имя" v-model="item.first_name" />
          <validation-error :errors="apiValidationErrors.first_name" />
        </b-form-group>
        <b-form-group class="col-4">
          <a-label label="Отчество" required />
          <b-form-input :disabled="!isManager" placeholder="Отчество" v-model="item.middle_name" />
          <validation-error :errors="apiValidationErrors.middle_name" />
        </b-form-group>

        <template v-if="item.role !== roleGuest">
          <b-form-group class="col-lg-6">
            <a-label label="Email" required />
            <b-form-input placeholder="jesse@example.com" v-model="item.email" />
            <validation-error :errors="apiValidationErrors.email" />
          </b-form-group>

          <b-form-group class="col-lg-6">
            <a-label label="Телефон" required />
            <b-form-input
                v-inputmask="'+7 (999) 999-99-99'"
                placeholder="+7 (---) --- - -- - --"
                v-model="item.phone"
            />
            <validation-error :errors="apiValidationErrors.phone" />
          </b-form-group>
        </template>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseInfoCard",
  data() {
    return {
      roleGuest: 3
    };
  },
  props: {
    item: {
      type: Object,
    },
  },
}
</script>

<style scoped>

</style>