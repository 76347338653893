<template>
<div>
  <base-header type="gradient-indigo" class="pb-6 pt-5 pt-md-8" />
  <span class="mask bg-gradient-indigo" />
  <div class="container-fluid mt--7 col-10">
    <div class="row">
      <div class="col">
        <div class="card shadow">
          <div class="card-header bg-transparent">
            <h3 class="mb-0">События</h3>
          </div>

            <event-list />

        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import EventList from "./Event/EventList";

export default {
  name: "Event",
  components: {
    EventList,
  }
}
</script>

<style scoped>

</style>