import Resource from "../resource";
import axios from "axios";

export default class MailResource extends Resource {


    static sendAll(params) {
        const url = `${this.backendUrl}/${this.url}/send-all`;
        const { data } = axios.post(url, params);
        return data;
    }
}

MailResource.url = 'mail';