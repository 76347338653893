import Resource from "../resource";
import axios from "axios";

export default class EntryResource extends Resource {
    static async pay(entryId, params) {
        const url = `${this.backendUrl}/${this.url}/${entryId}/pay`;
        const { data } = await axios.post(url, { params });
        if (!params.immediate)
            await this.setUuid(entryId, data.id);
        return data;
    }

    /**
     * Устанавливает Uuid платежу
     * TODO Это должно происходить не через api,
     *   а только на бэкенде
     * @param entryId
     * @param uuid
     * @returns {Promise<any>}
     */
    static async setUuid(entryId, uuid) {
        const url = `${this.backendUrl}/${this.url}/${entryId}/set-payment-uuid`;
        const { data } = await axios.post(url, {
            uuid: uuid
        });
        return data;
    }

    /**
     * Печать документа
     * @returns {Promise<void>}
     */
    static async print(id, type) {
        const url = `${this.backendUrl}/${this.url}/${id}/print/${type}`;
        const { data } = await axios.get(url, {});
        return data;
    }
}

EntryResource.url = 'entry';