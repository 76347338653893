<template>
  <div>
    <b-card-body>
      <l-select v-if="categories.length > 0" :options="categoriesOptions" v-model="category" />
      <l-select v-else dict="dict_macros_categories" v-model="category" />
    </b-card-body>

    <!--  TODO Все под один вид  -->
    <b-form-checkbox
        v-model="isForDocument"
        class="rounded-lg ml-4 mb-3"
    >
      Для документа DOCX
    </b-form-checkbox>

    <div class="row icon-examples" id="macros-list">
      <div
          class="mr-2 col-12"
          v-for="(macros, index) in macroses" :key="macros.fdv + index"
      >
        <button
            type="button"
            :title="macros.fdv"
            @click="copyToClipboard(macros.fdv)"
            class="btn-icon-clipboard"
            :class="btnMacrosClass"
            data-clipboard-text="air-baloon"
        >
          <div>
            <span><b>{{ convertTitleToVar(macros.fdv) }}</b></span>
            <span v-if="!hideDescription && !descriptionNextLine">{{ macros.description }}</span>
          </div>
          <span v-if="!hideDescription && descriptionNextLine" class="text-wrap">{{ macros.description }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

export default {
  name: "MacrosList",
  data() {
    return {
      category: 2,
      isForDocument: false,
    }
  },
  props: {
    hideDescription: {
      type: Boolean,
      default () {
        return false
      },
    },
    categories: {
      type: Array,
      default () {
        return []
      },
    },
    descriptionNextLine: {
      type: Boolean,
      default () {
        return false
      },
    },
    btnMacrosClass: {
      type: String,
    },
  },
  computed: {
    macroses() {
      const all = this.$store.getters.getDict('dict_macroses');
      return all.filter(elm => elm.dict_category_id === this.category);
    },
    categoriesOptions() {
      const categories = this.$store.getters.getDictItemsByIds(
          'dict_macros_categories',
          this.categories
      )
      return categories.map((item) => ({
        ...item,
        label: item.fdv,
        value: item.id
      }))
    }
  },
  watch: {
    categories: {
      handler(val) {
        //
      },
    },
  },
  methods: {
    /**
     * Копирование макроса в буфер обмена по нажатию
     * @param item
     */
    copyToClipboard(word) {
      const el = document.createElement('textarea');
      el.value = this.convertTitleToVar(word);
      document.getElementById("macros-list").appendChild(el);
      el.select();
      document.execCommand('Copy');
      document.getElementById('macros-list').removeChild(el);
      this.onCopy();
    },
    /**
     * Изменяет слово в макрос вида ${МАКРОС}
     * @param title
     * @returns {string}
     */
    convertTitleToVar(title) {
      const variable = `{${title}}`;
      return this.isForDocument ? `$${variable}` : variable;
    },
    onCopy() {
      this.$notify({
        type: 'success',
        title: 'Скопировано в буфер обмена'
      })
    },
  }
}
</script>

<style scoped>

</style>