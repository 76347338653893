import axios from "axios";
import router from "../../router";

const url = process.env.VUE_APP_API_BASE_URL;

export default {

  state: {
    user: {},
  },

  getters: {
    user: state => state.user
  },

  mutations: {
    SET_USER: (state, payload) => {
      state.user = payload;
    }
  },

  actions: {
    /**
     * TODO Рефакторинг
     * Пробует получить данные текущего пользователя в системе
     * если пользователь находится не на страницах авторизации/регистрации.
     * Если получает пустоту, то разлогинивает пользователя.
     * @param commit
     * @returns {Promise<*>}
     */
    async me({commit}) {
      const currentUrl = window.location.href;
      const authenticationPages = ['login', 'register', 'password', 'pay'];
      const isAuthenticationPage = authenticationPages.some(
          elm => currentUrl.includes(elm)
      );

      if (!isAuthenticationPage) {
        try {
          const { data } = await axios.get(`${url}/api/get`);
          if (!data.id) {
            this.dispatch('logout');
          }
          return commit('SET_USER', data);
        } catch (e) {
          this.dispatch('logout');
        }
      }
    },

    update() {
      console.log('todo');
    },
  },

}